import { Container, Row, Col } from 'react-bootstrap';

function RivalryGames() {
  return (
    <div className="App-content">
      <h3>Big Ten Rivals</h3>

      <p>
        Here is a breakdown by school of traditional Big Ten rivalries, including all-time meetings (though the 2023 season), first year 
        played, and any associated trophies or game designations. A few less traditional or shorter-lived games are also included because
        they relevant for the purpose of the discussion. *Indicates an annual protected rivals under new, expanded schdeduling plan.</p>

      <Container fluid>
        <Row>
          <Col className={'fixed-one-third'}>
            <div>
              <h4>USC Trojans</h4>
              <ul className={"unformattedList"}>
                <li>Non-conference: Cal 109 since 1915</li>
                <li>Non-conference: <a href="https://en.wikipedia.org/wiki/Stanford%E2%80%93USC_football_rivalry" target={"_blank"} rel="noreferrer">Stanford 101 since 1905</a></li>
                <li>Non-conference: <a href="https://en.wikipedia.org/wiki/Notre_Dame%E2%80%93USC_football_rivalry" target={"_blank"} rel="noreferrer">Notre Dame 94 since 1926 (Jeweled Shillelagh)</a></li>
                <li>*<a href="https://en.wikipedia.org/wiki/UCLA%E2%80%93USC_rivalry" target={"_blank"} rel="noreferrer">UCLA 91 since 1929 (Victory Bell)</a></li>
                <li>Washington 87 since 1923</li>
                <li>Oregon 63 since 1915</li>
              </ul>
              <br/>
            </div>

            <div>
              <h4>UCLA Bruins</h4>
              <ul className={"unformattedList"}>
                <li>Non-conference: Stanford 95 since 1925</li>
                <li>Non-conference: <a href="https://en.wikipedia.org/wiki/California%E2%80%93UCLA_football_rivalry" target={"_blank"} rel="noreferrer">Cal 94 since 1933</a></li>
                <li>*<a href="https://en.wikipedia.org/wiki/UCLA%E2%80%93USC_rivalry" target={"_blank"} rel="noreferrer">USC 91 since 1929 (Victory Bell)</a></li>
                <li>Washington 76 since 1932</li>
                <li>Oregon 71 since 1928</li>
              </ul>
              <br/>
            </div>

            <div>
              <h4>Oregon Ducks</h4>
              <ul className={"unformattedList"}>
                <li>Non-conference: <a href="https://en.wikipedia.org/wiki/Oregon%E2%80%93Oregon_State_football_rivalry" target={"_blank"} rel="noreferrer">Oregon State 127 since 1894 (Civil War/Platypus Trophy)</a></li>
                <li>*<a href="https://en.wikipedia.org/wiki/Oregon%E2%80%93Washington_football_rivalry" target={"_blank"} rel="noreferrer">Washington 116 since 1900</a></li>
                <li>Non-conference: Washington State 103 since 1901</li>
                <li>Non-conference: Cal 87 since 1899</li>
                <li>Non-conference: Stanford 87 since 1900</li>
                <li>UCLA 71 since 1928</li>
                <li>USC 63 since 1915</li>
              </ul>
              <br/>
            </div>

            <div>
              <h4>Washington Huskies</h4>
              <ul className={"unformattedList"}>
                <li>*<a href="https://en.wikipedia.org/wiki/Oregon%E2%80%93Washington_football_rivalry" target={"_blank"} rel="noreferrer">Oregon 116 since 1900</a></li>
                <li>Non-conference: <a href="https://en.wikipedia.org/wiki/Apple_Cup" target={"_blank"} rel="noreferrer">Washington State 115 since 1900 (Apple Cup)</a></li>
                <li>Non-conference: Oregon State 108 since 1897</li>
                <li>Non-conference: Cal 104 since 1904</li>
                <li>Non-conference: Stanford 94 since 1893</li>
                <li>USC 87 since 1923</li>
                <li>UCLA 76 since 1932</li>
              </ul>
              <br/>
            </div>

            <div>
              <h4>Nebraska Cornhuskers</h4>
              <ul className={"unformattedList"}>
                <li>Non-conference: <a href="https://en.wikipedia.org/wiki/Missouri%E2%80%93Nebraska_football_rivalry" target={"_blank"} rel="noreferrer">Missouri 104 since 1892 (Victory Bell)</a></li>
                <li>Non-conference: <a href="https://en.wikipedia.org/wiki/Nebraska%E2%80%93Oklahoma_football_rivalry" target={"_blank"} rel="noreferrer">Oklahoma 88 since 1912</a></li>
                <li>Non-conference: <a href="https://en.wikipedia.org/wiki/Colorado%E2%80%93Nebraska_football_rivalry" target={"_blank"} rel="noreferrer">Colorado 71 since 1898 (Bison Head Trophy)</a></li>
                <li><a href="https://en.wikipedia.org/wiki/Minnesota%E2%80%93Nebraska_football_rivalry" target={"_blank"} rel="noreferrer">Minnesota 64 since 1900 ($5 Bits of Broken Chair Trophy)</a></li>
                <li>*<a href="https://en.wikipedia.org/wiki/Iowa%E2%80%93Nebraska_football_rivalry" target={"_blank"} rel="noreferrer">Iowa 54 since 1891 (Heroes Trophy)</a></li>
                <li><a href="https://en.wikipedia.org/wiki/Nebraska%E2%80%93Wisconsin_football_rivalry" target={"_blank"} rel="noreferrer">Wisconsin 17 since 1901 (Freedom Trophy)</a></li>
              </ul>
              <br/>
            </div>
          </Col>

          <Col className={'fixed-one-third'}>
            <div>
              <h4>Iowa Hawkeyes</h4>
              <ul className={"unformattedList"}>
                <li>*<a href="https://en.wikipedia.org/wiki/Iowa%E2%80%93Minnesota_football_rivalry" target={"_blank"} rel="noreferrer">Minnesota 117 since 1891 (Floyd of Rosedale)</a></li>
                <li>*<a href="https://en.wikipedia.org/wiki/Iowa%E2%80%93Wisconsin_football_rivalry" target={"_blank"} rel="noreferrer">Wisconsin 97 since 1894 (Heartland Trophy)</a></li>
                <li>Purdue 94 since 1910</li>
                <li>Non-conference: <a href="https://en.wikipedia.org/wiki/Iowa%E2%80%93Iowa_State_football_rivalry" target={"_blank"} rel="noreferrer">Iowa State 70 since 1894 (Cy-Hawk Trophy)</a></li>
                <li>*<a href="https://en.wikipedia.org/wiki/Iowa%E2%80%93Nebraska_football_rivalry" target={"_blank"} rel="noreferrer">Nebraska 54 since 1891 (Heroes Trophy)</a></li>
              </ul>
              <br/>
            </div>

            <div>
              <h4>Minnesota Golden Gophers</h4>
              <ul className={"unformattedList"}>
                <li>*<a href="https://en.wikipedia.org/wiki/Minnesota%E2%80%93Wisconsin_football_rivalry" target={"_blank"} rel="noreferrer">Wisconsin 133 since 1890 (Paul Bunyan's Axe)</a></li>
                <li>*<a href="https://en.wikipedia.org/wiki/Iowa%E2%80%93Minnesota_football_rivalry" target={"_blank"} rel="noreferrer">Iowa 117 since 1891 (Floyd of Rosedale)</a></li>
                <li><a href="https://en.wikipedia.org/wiki/Little_Brown_Jug_(college_football_trophy)" target={"_blank"} rel="noreferrer">Michigan 105 since 1892 (Little Brown Jug)</a></li>
                <li>Northwestern 98 since 1892</li>
                <li><a href="https://en.wikipedia.org/wiki/Minnesota%E2%80%93Nebraska_football_rivalry" target={"_blank"} rel="noreferrer">Nebraska 64 since 1900 ($5 Bits of Broken Chair Trophy)</a></li>
                <li><a href="https://en.wikipedia.org/wiki/Minnesota%E2%80%93Penn_State_football_rivalry" target={"_blank"} rel="noreferrer">Penn State 16 since 1993 (Governor's Victory Bell)</a></li>
              </ul>
              <br/>
            </div>

            <div>
              <h4>Wisconsin Badgers</h4>
              <ul className={"unformattedList"}>
                <li>*<a href="https://en.wikipedia.org/wiki/Minnesota%E2%80%93Wisconsin_football_rivalry" target={"_blank"} rel="noreferrer">Minnesota 133 since 1890 (Paul Bunyan's Axe)</a></li>
                <li>Northwestern 105 since 1890</li>
                <li>*<a href="https://en.wikipedia.org/wiki/Iowa%E2%80%93Wisconsin_football_rivalry" target={"_blank"} rel="noreferrer">Iowa 97 since 1894 (Heartland Trophy)</a></li>
                <li>Illinois 91 since 1895</li>
                <li>Purdue 90 since 1892</li>
                <li>Ohio State 86 since 1913</li>
                <li><a href="https://en.wikipedia.org/wiki/Nebraska%E2%80%93Wisconsin_football_rivalry" target={"_blank"} rel="noreferrer">Nebraska 17 since 1901 (Freedom Trophy)</a></li>
              </ul>
              <br/>
            </div>

            <div>
              <h4>Northwestern Wildcats</h4>
              <ul className={"unformattedList"}>
                <li>*<a href="https://en.wikipedia.org/wiki/Illinois%E2%80%93Northwestern_football_rivalry" target={"_blank"} rel="noreferrer">Illinois 117 since 1892 (Land of Lincoln Trophy)</a></li>
                <li>Wisconsin 105 since 1890</li>
                <li>Minnesota 98 since 1892</li>
                <li>Purdue 88 since 1895</li>
                <li><a href="https://en.wikipedia.org/wiki/George_Jewett_Trophy" target={"_blank"} rel="noreferrer">Michigan 76 since 1892 (George Jewett Trophy)</a></li>
                <li>Non-conference: <a href="https://en.wikipedia.org/wiki/Northwestern%E2%80%93Notre_Dame_football_rivalry" target={"_blank"} rel="noreferrer">Notre Dame 49 since 1889</a></li>
              </ul>
              <br/>
            </div>

            <div>
              <h4>Illinois Fighting Illini</h4>
              <ul className={"unformattedList"}>
                <li>*<a href="https://en.wikipedia.org/wiki/Illinois%E2%80%93Northwestern_football_rivalry" target={"_blank"} rel="noreferrer">Northwestern 117 since 1892 (Land of Lincoln Trophy)</a></li>
                <li><a href="https://en.wikipedia.org/wiki/Illibuck_Trophy" target={"_blank"} rel="noreferrer">Ohio State 102 since 1902 (Illibuck)</a></li>
                <li>*<a href="https://en.wikipedia.org/wiki/Illinois%E2%80%93Purdue_football_rivalry" target={"_blank"} rel="noreferrer">Purdue 99 since 1890 (Purdue Cannon)</a></li>
                <li>Michigan 97 since 1898</li>
                <li>Wisconsin 91 since 1895</li>
                <li>Non-conference: <a href="https://en.wikipedia.org/wiki/Illinois%E2%80%93Missouri_football_rivalry" target={"_blank"} rel="noreferrer">Missouri 24 since 1896</a></li>
              </ul>
              <br/>
            </div>

            <div>
              <h4>Purdue Boilermakers</h4>
              <ul className={"unformattedList"}>
                <li>*<a href="https://en.wikipedia.org/wiki/Old_Oaken_Bucket" target={"_blank"} rel="noreferrer">Indiana 125 since 1891 (Old Oaken Bucket)</a></li>
                <li>*<a href="https://en.wikipedia.org/wiki/Illinois%E2%80%93Purdue_football_rivalry" target={"_blank"} rel="noreferrer">Illinois 99 since 1890 (Purdue Cannon)</a></li>
                <li>Iowa 94 since 1910</li>
                <li>Wisconsin 90 since 1892</li>
                <li>Non-conference: <a href="https://en.wikipedia.org/wiki/Notre_Dame%E2%80%93Purdue_football_rivalry" target={"_blank"} rel="noreferrer">Notre Dame 85 since 1896 (Shillelagh)</a></li>
                <li>Northwestern 88 since 1895</li>
              </ul>
              <br/>
            </div>
          </Col>

          <Col className={'fixed-one-third'}>
            <div>
              <h4>Indiana Hoosiers</h4>
              <ul className={"unformattedList"}>
                <li>*<a href="https://en.wikipedia.org/wiki/Old_Oaken_Bucket" target={"_blank"} rel="noreferrer">Purdue 125 since 1891 (Old Oaken Bucket)</a></li>
                <li>Ohio State 96 since 1901</li>
                <li><a href="https://en.wikipedia.org/wiki/Indiana%E2%80%93Michigan_State_football_rivalry" target={"_blank"} rel="noreferrer">Michigan State 70 since 1922 (Old Brass Spittoon)</a></li>
              </ul>
              <br/>
            </div>

            <div>
              <h4>Michigan State Spartans</h4>
              <ul className={"unformattedList"}>
                <li>*<a href="https://en.wikipedia.org/wiki/Michigan%E2%80%93Michigan_State_football_rivalry" target={"_blank"} rel="noreferrer">Michigan 116 since 1898 (Paul Bunyan Trophy)</a></li>
                <li><a href="https://en.wikipedia.org/wiki/Indiana%E2%80%93Michigan_State_football_rivalry" target={"_blank"} rel="noreferrer">Indiana 70 since 1922 (Old Brass Spittoon)</a></li>
                <li>Non-conference: <a href="https://en.wikipedia.org/wiki/Michigan_State%E2%80%93Notre_Dame_football_rivalry" target={"_blank"} rel="noreferrer">Notre Dame 77 since 1897 (Megaphone Trophy)</a></li>
                <li><a href="https://en.wikipedia.org/wiki/Michigan_State%E2%80%93Penn_State_football_rivalry" target={"_blank"} rel="noreferrer">Penn State 38 since 1914 (Land Grant Trophy)</a></li>
              </ul>
              <br/>
            </div>

            <div>
              <h4>Michigan Wolverines</h4>
              <ul className={"unformattedList"}>
                <li>*<a href="https://en.wikipedia.org/wiki/Michigan%E2%80%93Ohio_State_football_rivalry" target={"_blank"} rel="noreferrer">Ohio State 118 since 1897 (The Game)</a></li>
                <li>*<a href="https://en.wikipedia.org/wiki/Michigan%E2%80%93Michigan_State_football_rivalry" target={"_blank"} rel="noreferrer">Michigan State 116 since 1898 (Paul Bunyan Trophy)</a></li>
                <li><a href="https://en.wikipedia.org/wiki/Little_Brown_Jug_(college_football_trophy)" target={"_blank"} rel="noreferrer">Minnesota 105 since 1892 (Little Brown Jug)</a></li>
                <li>Illinois 97 since 1898</li>
                <li><a href="https://en.wikipedia.org/wiki/George_Jewett_Trophy" target={"_blank"} rel="noreferrer">Northwestern 76 since 1892 (George Jewett Trophy)</a></li>
                <li>Non-conference: <a href="https://en.wikipedia.org/wiki/Michigan%E2%80%93Notre_Dame_football_rivalry" target={"_blank"} rel="noreferrer">Notre Dame 43 since 1887</a></li>
              </ul>
              <br/>
            </div>

            <div>
              <h4>Ohio State Buckeyes</h4>
              <ul className={"unformattedList"}>
                <li>*<a href="https://en.wikipedia.org/wiki/Michigan%E2%80%93Ohio_State_football_rivalry" target={"_blank"} rel="noreferrer">Michigan 118 since 1897 (The Game)</a></li>
                <li><a href="https://en.wikipedia.org/wiki/Illibuck_Trophy" target={"_blank"} rel="noreferrer">Illinois 102 since 1902 (Illibuck)</a></li>
                <li>Indiana 96 since 1901</li>
                <li>Wisconsin 86 since 1913</li>
                <li><a href="https://en.wikipedia.org/wiki/Ohio_State%E2%80%93Penn_State_football_rivalry" target={"_blank"} rel="noreferrer">Penn State 38 since 1912</a></li>
              </ul>
              <br/>
            </div>

            <div>
              <h4>Penn State Nittany Lions</h4>
              <ul className={"unformattedList"}>
                <li>Non-conference: <a href="https://en.wikipedia.org/wiki/Penn_State%E2%80%93Pittsburgh_football_rivalry" target={"_blank"} rel="noreferrer">Pittsburgh 100 since 1893</a></li>
                <li><a href="https://en.wikipedia.org/wiki/Maryland%E2%80%93Penn_State_football_rivalry" target={"_blank"} rel="noreferrer">Maryland 47 since 1917</a></li>
                <li>Non-conference: <a href="https://en.wikipedia.org/wiki/Penn_State%E2%80%93Temple_football_rivalry" target={"_blank"} rel="noreferrer">Temple 45 since 1931</a></li>
                <li><a href="https://en.wikipedia.org/wiki/Ohio_State%E2%80%93Penn_State_football_rivalry" target={"_blank"} rel="noreferrer">Ohio State 38 since 1912</a></li>
                <li><a href="https://en.wikipedia.org/wiki/Michigan_State%E2%80%93Penn_State_football_rivalry" target={"_blank"} rel="noreferrer">Michigan State 38 since 1914 (Land Grant Trophy)</a></li>
                <li>Rutgers 34 since 1918</li>
                <li><a href="https://en.wikipedia.org/wiki/Minnesota%E2%80%93Penn_State_football_rivalry" target={"_blank"} rel="noreferrer">Minnesota 16 since 1993 (Governor's Victory Bell)</a></li>
              </ul>
              <br/>
            </div>

            <div>
              <h4>Maryland Terrapins</h4>
              <ul className={"unformattedList"}>
                <li>Non-conference: <a href="https://en.wikipedia.org/wiki/Maryland%E2%80%93Virginia_football_rivalry" target={"_blank"} rel="noreferrer">Virginia 79 since 1919</a></li>
                <li>Non-conference: <a href="https://en.wikipedia.org/wiki/Maryland%E2%80%93West_Virginia_football_rivalry" target={"_blank"} rel="noreferrer">West Virginia 53 since 1919</a></li>
                <li><a href="https://en.wikipedia.org/wiki/Maryland%E2%80%93Penn_State_football_rivalry" target={"_blank"} rel="noreferrer">Penn State 47 since 1917</a></li>
                <li>Non-conference: <a href="https://en.wikipedia.org/wiki/Crab_Bowl_Classic" target={"_blank"} rel="noreferrer">Navy 21 since 1905 (Crab Bowl Classic)</a></li>
                <li>*Rutgers 19 since 1920</li>
              </ul>
              <br/>
            </div>

            <div>
              <h4>Rutgers Scarlet Knights</h4>
              <ul className={"unformattedList"}>
                <li>Penn State 34 since 1918</li>
                <li>*Maryland 19 since 1920</li>
              </ul>
              <br/>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default RivalryGames;