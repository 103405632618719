function AllTimeJerseyNumbers() {
      
  return (
    <div className="App-content">
      <h3>All-Time Jersey Numbers</h3>
      <p>We plan to migrate everything from our All-Time Jersey Number project to the new site. But in the meantime, you can 
      still access the historical data at <a href="https://docs.google.com/spreadsheets/d/1oWRkeVDkKiEL_9LlwkWv0HVN-r2ekiBK3WhHTyjlits/edit#gid=183395958" target="new">All-Time Jersey Number Project</a>.</p>
    </div>
  )
}

export default AllTimeJerseyNumbers;