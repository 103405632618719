import Table from 'react-bootstrap/Table';
import SeasonSelect from './SeasonSelect';

function Season2023() {
  return (
    <div className="App-content">
      <h3>2023 Schedule</h3>

      <SeasonSelect />

      <Table responsive bordered hover size="sm">
      <thead>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th className={"centeredCol"}>Pacific</th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th className={"centeredCol"} colSpan={2}>USC</th>
            <th className={"centeredCol"} colSpan={2}>Opponent</th>
            <th className={"centeredCol"} colSpan={2}>Opp Current</th>
          </tr>
          <tr>
            <th width="50" className={"centeredCol"}>Date</th>
            <th width="125" className={"centeredCol"}>Opponent</th>
            <th width="100" className={"centeredCol"}>Conference</th>
            <th width="75" className={"centeredCol"}>Time</th>
            <th width="100" className={"centeredCol"}>TV</th>
            <th width="100" className={"centeredCol"}>Location</th>
            <th width="50" className={"centeredCol"}>Line</th>
            <th width="75" className={"centeredCol"}>Result</th>
            <th width="50" className={"centeredCol"}>Record</th>
            <th width="50" className={"centeredCol"}>Rank</th>
            <th width="50" className={"centeredCol"}>Record</th>
            <th width="50" className={"centeredCol"}>Rank</th>
            <th width="50" className={"centeredCol"}>Record</th>
            <th width="50" className={"centeredCol"}>Rank</th>
          </tr>
        </thead>
        <tbody>
          <tr className="monthRow">
            <td colSpan={14}>
              August
            </td>
          </tr>
          <tr>
            <td align="center">26</td>
            <td>San José State</td>
            <td>Mountain West</td>
            <td align="center">5:00 PM</td>
            <td align="center">P12N</td>
            <td>L.A. Coliseum</td>
            <td align="center" className={"loss"}>-31</td>
            <td align="center" className={"win"}>56-28</td>
            <td align="center">0-0</td>
            <td align="center">6</td>
            <td align="center">0-0</td>
            <td align="center">-</td>
            <td align="center">6-5</td>
            <td align="center">-</td>
          </tr>
          <tr className="monthRow">
            <td colSpan={14}>
              September
            </td>
          </tr>
          <tr>
            <td align="center">2</td>
            <td>Nevada</td>
            <td>Mountain West</td>
            <td align="center">3:30 PM</td>
            <td align="center">P12N</td>
            <td>L.A. Coliseum</td>
            <td align="center" className={"win"}>-38</td>
            <td align="center" className={"win"}>66-14</td>
            <td align="center">1-0</td>
            <td align="center">6</td>
            <td align="center">0-0</td>
            <td align="center">-</td>
            <td align="center">2-9</td>
            <td align="center">-</td>
          </tr>
          <tr>
            <td align="center">9</td>
            <td>Stanford</td>
            <td>Pac-12</td>
            <td align="center">7:30 PM</td>
            <td align="center">FOX</td>
            <td>L.A. Coliseum</td>
            <td align="center" className={"win"}>-29</td>
            <td align="center" className={"win"}>56-10</td>
            <td align="center">2-0</td>
            <td align="center">6</td>
            <td align="center">1-0</td>
            <td align="center">-</td>
            <td align="center">3-8</td>
            <td align="center">-</td>
          </tr>
          <tr className="bye">
            <td align="center">16</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td align="center">3-0</td>
            <td align="center">5</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td align="center">23</td>
            <td>Arizona State</td>
            <td>Pac-12</td>
            <td align="center">7:30 PM</td>
            <td align="center">FOX</td>
            <td>Tempe, AZ</td>
            <td align="center" className={"loss"}>-34.5</td>
            <td align="center" className={"win"}>42-28</td>
            <td align="center">3-0</td>
            <td align="center">5</td>
            <td align="center">1-2</td>
            <td align="center">-</td>
            <td align="center">3-8</td>
            <td align="center">-</td>
          </tr>
          <tr>
            <td align="center">30</td>
            <td>Colorado</td>
            <td>Pac-12</td>
            <td align="center">9:00 AM</td>
            <td align="center">FOX</td>
            <td>Boulder, CO</td>
            <td align="center" className={"loss"}>-24.5</td>
            <td align="center" className={"win"}>48-41</td>
            <td align="center">4-0</td>
            <td align="center">8</td>
            <td align="center">3-1</td>
            <td align="center">-</td>
            <td align="center">4-7</td>
            <td align="center">-</td>
          </tr>
          <tr className="monthRow">
            <td colSpan={14}>
              October
            </td>
          </tr>
          <tr>
            <td align="center">7</td>
            <td>Arizona</td>
            <td>Pac-12</td>
            <td align="center">7:30 PM</td>
            <td align="center">ESPN</td>
            <td>L.A. Coliseum</td>
            <td align="center" className={"loss"}>-21.5</td>
            <td align="center" className={"win"}>43-41 3OT</td>
            <td align="center">5-0</td>
            <td align="center">9</td>
            <td align="center">3-2</td>
            <td align="center">-</td>
            <td align="center">8-3</td>
            <td align="center">15</td>
          </tr>
          <tr>
            <td align="center">14</td>
            <td>Notre Dame</td>
            <td>Independent</td>
            <td align="center">4:30 PM</td>
            <td align="center">NBC</td>
            <td>South Bend, IN</td>
            <td align="center" className={"loss"}>+3</td>
            <td align="center" className={"loss"}>20-48</td>
            <td align="center">6-0</td>
            <td align="center">10</td>
            <td align="center">4-2</td>
            <td align="center">21</td>
            <td align="center">8-3</td>
            <td align="center">18</td>
          </tr>
          <tr>
            <td align="center">21</td>
            <td>Utah</td>
            <td>Pac-12</td>
            <td align="center">5:00 PM</td>
            <td align="center">FOX</td>
            <td>L.A. Coliseum</td>
            <td align="center" className={"loss"}>-7</td>
            <td align="center" className={"loss"}>32-34</td>
            <td align="center">6-1</td>
            <td align="center">18</td>
            <td align="center">5-1</td>
            <td align="center">14</td>
            <td align="center">7-4</td>
            <td align="center">18*</td>
          </tr>
          <tr>
            <td align="center">28</td>
            <td>Cal</td>
            <td>Pac-12</td>
            <td align="center">1:00 PM</td>
            <td align="center">P12N</td>
            <td>Berkeley, CA</td>
            <td align="center" className={"loss"}>-10</td>
            <td align="center" className={"win"}>50-49</td>
            <td align="center">6-2</td>
            <td align="center">24</td>
            <td align="center">3-4</td>
            <td align="center">-</td>
            <td align="center">5-6</td>
            <td align="center">-</td>
          </tr>
          <tr className="monthRow">
            <td colSpan={14}>
              November
            </td>
          </tr>
          <tr>
            <td align="center">4</td>
            <td>Washington (HC)</td>
            <td>Pac-12</td>
            <td align="center">4:30 PM</td>
            <td align="center">ABC</td>
            <td>L.A. Coliseum</td>
            <td align="center" className={"loss"}>+3</td>
            <td align="center" className={"loss"}>42-52</td>
            <td align="center">7-2</td>
            <td align="center">20</td>
            <td align="center">8-0</td>
            <td align="center">5</td>
            <td align="center">11-0</td>
            <td align="center">4</td>
          </tr>
          <tr>
            <td align="center">11</td>
            <td>Oregon</td>
            <td>Pac-12</td>
            <td align="center">7:30 PM</td>
            <td align="center">FOX</td>
            <td>Eugene, OR</td>
            <td align="center" className={"win"}>+15</td>
            <td align="center" className={"loss"}>27-36</td>
            <td align="center">7-3</td>
            <td align="center">20</td>
            <td align="center">8-1</td>
            <td align="center">6</td>
            <td align="center">10-1</td>
            <td align="center">6</td>
          </tr>
          <tr>
            <td align="center">18</td>
            <td>UCLA</td>
            <td>Pac-12</td>
            <td align="center">12:30 PM</td>
            <td align="center">ABC</td>
            <td>L.A. Coliseum</td>
            <td align="center" className={"loss"}>-5</td>
            <td align="center" className={"loss"}>20-38</td>
            <td align="center">7-4</td>
            <td align="center">-</td>
            <td align="center">6-4</td>
            <td align="center">-</td>
            <td align="center">7-4</td>
            <td align="center">-</td>
          </tr>
          <tr className="bye">
            <td align="center">25</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr className="monthRow">
            <td colSpan={14}>
              December
            </td>
          </tr>
          <tr>
            <td align="center">27</td>
            <td>Holiday Bowl: Louisville</td>
            <td>ACC</td>
            <td align="center">5:00 PM</td>
            <td align="center">FOX</td>
            <td>San Diego, CA</td>
            <td align="center" className={"win"}>+4.5</td>
            <td align="center" className={"win"}>42-28</td>
            <td align="center">7-5</td>
            <td align="center">-</td>
            <td align="center">10-3</td>
            <td align="center">15</td>
            <td align="center">10-4</td>
            <td align="center"></td>
          </tr>
        </tbody>          
      </Table>

    </div>
  )
}

export default Season2023;