function Spring2024PreviewDffense() {
  return (
    <div className="App-content">
      <div className="post">
        <h3>Spring Preview: Defense</h3>
        <p>Coming soon!</p>
        {/* <p className="byline">TributeToTroy - February 4, 2024</p>

        <h4>2023 Review</h4>

        

        <h4>2024 Outlook</h4>

        <h5>Defensive Coaching Staff</h5> */}
        


      </div>
    </div>
  )
}

export default Spring2024PreviewDffense;