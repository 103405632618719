function Spring2024PreviewOffense() {
  return (
    <div className="App-content">
      <div className="post">
        <h3>Spring Preview: Offense</h3>
        <p>Coming soon!</p>
        {/* <p className="byline">TributeToTroy - February 4, 2024</p>

        <h4>2023 Review</h4>

        <p>When a Blueblood loses five games, no aspect of the program will—or should—escape scrutiny. However, Lincoln Riley’s record 
        for developing elite quarterbacks and producing high-scoring offenses remains undisputed.</p>

        <p>Even in a “down” year, his offense was third in the nation in scoring (41.8 points per game) and fifth in passing (333.0 yards 
        per game; as well as a #3 with a passing rating of 170.88) — all while his top backs rushed for 7.1 (MarShwan Lloyd) and 5.6 (Austin Jones) yards 
        per carry and were rarely tackled for a loss (fifth nationally at 2.23 per game, after adjusting for sacks). Only one other team 
        had more than 90 plays from scrimmage of over 20 yards, and an overall 7.4 yards per play was third in the nation.</p>
        
        <p>That is not to say that all was peaches and cream. Allowing 2.54 sacks per game (#101 nationally) and 2 fumbles per game (#130 
        with 0.85 lost per game) are horrendous numbers. And our line was flagged on a whopping 4% of all offensive plays. Those are all 
        drive-killing stats. The fact that, despite returning starters Dedich and Monheim from a veteran-laden 2023 squad, all five starting 
        linemen were either transfers, playing at a new spot on the line, or both, really showed as players continued to be shifted around 
        and just never really seemed to completely gel.</p>
        
        <p>In response, it seemed that Riley tried to ask his quarterback to try to pull more rabbits out of his helmet than he had even 
        during his Heisman winning campaign—often having the reverse effect of putting his suspect defense right back on the field.</p>

        <p><iframe width="600" height="451" className="center" src="https://www.youtube.com/embed/LP4SfCy_hR8" title="The Caleb Williams Mixtape - USC Football (4K)" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></p>
        
        <p>Finally, when the Air Raid-disciple Riley arrived in Oklahoma back in 2015, Bob Stoops made it clear that they were not about to 
        abandon the run. Riley, with heavy input from Bill Bedenbaugh, proceeded to develop a GT-counter rushing attack to complement his 
        wide-open passing philosophy. And they were successful. In fact, in 2016 the Sooners ran MORE designed rushing plays (53.4%) than passes.</p>
        
        <p>But, as they chart below reveals, those pass-first tendencies have crept back in over time. Last season, the ratio was 65.9% pass to 
        34.1% run (adjusted for sacks and scrambles) — numbers closer to his play-calling days at East Carolina that might have even make Mike Leach smile.</p>

        <img src="/img/runpass2023.png" className="center" alt="2023 run:pass ratio"/>

        <h4>2024 Outlook</h4>

        <h5>Offensive Coaching Staff</h5>
        <p>
          <b>Gone: </b>Kliff Kingsbury (Senior Analyst)<br/>
          <b>Back: </b>HC/QB Lincoln Riley, OC/OL Josh Henson, AHC/PGC/Outside WR Dennis Simmons, RB Kiel McDonald, TE Zach Hanson, Inside WR Luke Huard
        </p>

        <p>While Kingsbury was certainly as asset (based on positive comments from Caleb Williams and Miller Moss), some fans exaggerate the importance of having “names” on the staff as analysts. Riley is big on culture and fit. I would not be at all surprised to see Dana Holgorsen step into that senior offensive analyst role. He and Riley are extremely close. He is available. And his creativity in melding power running with Air Raid concepts could be quite beneficial.</p>

        <h5>Quarterback</h5>
        <p>
          <b>Gone: </b>Caleb Williams, Malachi Nelson (Boise State)<br/>
          <b>Back: </b>Miller Moss, Jake Jensen<br/>
          <b>New: </b>Jayden Maiava (UNLV)
        </p>

        <p>Caleb Williams is irreplaceable, a truly special talent. But you do not necessarily need a generational player to produce an elite offense. Miller Moss showed in the Holiday Bowl what really good passing game can still achieve when firing on all cylinders.</p>
        <p>Moss is a born leader—one the team was quick to rally around. He is also smart and talented and has the additional advantage as last year’s backup of building chemistry with some of the younger receivers—something that was apparent in the bowl game.</p>
        <p>The UNLV transfer, Jayden Maiava, brings game experience to the room—albeit against mostly Mountain West competition. And despite having some ball security issues himself, he can be add more of a threat to run and was one of the better QB options in the portal.</p> */}

        {/* https://twitter.com/uscfb/status/1740211945515487637
        https://twitter.com/uscfb/status/1740219524044243167
        https://twitter.com/uscfb/status/1740193847416697097
        https://twitter.com/uscfb/status/1740182897402020200 */}

        {/* See https://www.npmjs.com/package/react-twitter-widgets */}
        {/* <blockquote class="twitter-tweet" data-media-max-width="560">
          <p lang="en" dir="ltr">Longest catch of <a href="https://twitter.com/getabagkai?ref_src=twsrc%5Etfw">@getabagkai</a>’s career! 🍋<br><br>📺 <a href="https://twitter.com/CFBONFOX?ref_src=twsrc%5Etfw">@CFBONFOX</a> <a href="https://t.co/EWLbQExBIC">pic.twitter.com/EWLbQExBIC</a></p>&mdash; USC Football ✌️ (@uscfb) <a href="https://twitter.com/uscfb/status/1740182897402020200?ref_src=twsrc%5Etfw">December 28, 2023</a>
        </blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script> */}



      </div>
    </div>
  )
}

export default Spring2024PreviewOffense;