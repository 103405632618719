import Data from '../../Data';
import Table from 'react-bootstrap/Table';

function Geographic() {
  const file = '2024roster';
  const sortOrder = ['region', 'lastName', 'firstName'];
  const criteria = [{prop: 'region', operator: 'exists'}];
  const players = Data(file, sortOrder, criteria);

  let regions = [];
  let regionCount = 0;
  let lastRegion = undefined;
  for (let i = 0; i < players.length ; i++) {
    if (players[i].region) {
      if (players[i].region === lastRegion) {
        regions[regionCount - 1].players.push(players[i])
      } else {
        lastRegion = players[i].region
        regionCount++;
        regions.push({'region': players[i].region, players: []});
        regions[regionCount - 1].players.push(players[i]);
      }
    }
  };

  return (
    <div className="App-content">
      <h3>2024 Players by High School Region</h3>

      <p>Players are listed by region and city of the high school that they graduated from. Note that some players competed at high schools or transfered 
         from other colleges in a region other than where their hometown is located.</p>

      <p><a href="#/roster-name">Player Names</a> | <a href="#/roster-number">Player Numbers</a> | <a href="#/player-majors">Player Majors</a></p>
      <p># Non-scholarship player</p>

      {regions.map((region, i) =>
        <div key={i}>
          <h4>{region.region} ({region.players.length})</h4>

          <Table bordered hover size="sm">
            <thead>
              <tr>
                <th width="30" className={"centeredCol"}>No</th>
                <th width="150">Name</th>
                <th width="40" className={"centeredCol"}>Pos</th>
                <th width="40" className={"centeredCol"}>Ht</th>
                <th width="40" className={"centeredCol"}>Wt</th>
                <th width="40" className={"centeredCol"}>Class</th>
                <th width="150">Hometown</th>
                <th width="300">School(s)</th>
              </tr>
            </thead>
            <tbody>
              {region.players.map((player, j) =>
                <tr key={j}>
                  <td align="center">{player.jersey}</td>
                  <td>
                    {player.walkOn ? '# ' : ''}
                    <a href={"https://usctrojans.com/sports/football/roster/" + player.link} target={"_blank"} rel="noreferrer">
                      {player.firstName} {player.lastName} {player.suffix}
                    </a>
                  </td>
                  <td align="center">{player.displaypos}</td>
                  <td align="center">{player.height}</td>
                  <td align="center">{player.weight}</td>
                  <td align="center">{player.redshirt?'Rs-':''}{player.class}</td>
                  <td>{player.hometown}{player.state ? ', ' + player.state : ''}{player.country !== 'USA' ? ' ' + player.country : ''}</td>
                  <td>{player.schools}</td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      )}
    </div>
  )
}

export default Geographic;