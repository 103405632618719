import Data from '../../Data';

function GMTargetLists() {
  const file = 'gm';
  const sortOrder = undefined;
  const data = Data(file)

  const offense2024criteria = [{prop: 'year', value: '2024'}, {prop: 'type', value: 'High School'}, {prop: 'team', value: 'Offense'}];
  const offense2024 = Data(file, sortOrder, offense2024criteria);
  const defense2024criteria = [{prop: 'year', value: '2024'}, {prop: 'type', value: 'High School'}, {prop: 'team', value: 'Defense'}];
  const defense2024 = Data(file, sortOrder, defense2024criteria);

  const offense2024transferCriteria = [{prop: 'year', value: '2024'}, {prop: 'type', value: 'Transfer'}, {prop: 'team', value: 'Offense'}];
  const offense2024transfer = Data(file, sortOrder, offense2024transferCriteria);
  const defense2024transferCriteria = [{prop: 'year', value: '2024'}, {prop: 'type', value: 'Transfer'}, {prop: 'team', value: 'Defense'}];
  const defense2024transfer = Data(file, sortOrder, defense2024transferCriteria);

  const offense2025criteria = [{prop: 'year', value: '2025'}, {prop: 'type', value: 'High School'}, {prop: 'team', value: 'Offense'}];
  const offense2025 = Data(file, sortOrder, offense2025criteria);
  const defense2025criteria = [{prop: 'year', value: '2025'}, {prop: 'type', value: 'High School'}, {prop: 'team', value: 'Defense'}];
  const defense2025 = Data(file, sortOrder, defense2025criteria);

  return (
    <div className="App-content">
      <h3>USCFootball.com Target Lists</h3>

      <p>Gerard Martinez, Trojan football recruiting expert at USCFootball.com, maintains useful target lists of high school recruits 
      and transfers prospects. We are not affliliated with the site but have linked the latest iterations here for convenience.</p>

      <h4>USC Football 2024 Target Lists</h4>
      
      {offense2024.map((list, index) =>
        <div key={index}>
          <a href={list.pdf} target="_blank" rel="noreferrer">
            Offense (updated {list.updated})
          </a>
        </div>
      )}

      {defense2024.map((list, index) =>
        <div key={index}>
          <a href={list.pdf} target="_blank" rel="noreferrer">
            Defense (updated {list.updated})
          </a>
        </div>
      )}

      <br/>
      <h4>USC Football 2024 Transfer Target Lists</h4>

      {offense2024transfer.map((list, index) =>
        <div key={index}>
          <a href={list.pdf} target="_blank" rel="noreferrer">
            Offense (updated {list.updated})
          </a>
        </div>
      )}

      {defense2024transfer.map((list, index) =>
        <div key={index}>
          <a href={list.pdf} target="_blank" rel="noreferrer">
            Defense (updated {list.updated})
          </a>
        </div>
      )}
      
      <br/>
      <h4>USC Football 2025 Target Lists</h4>
      
      {offense2025.map((list, index) =>
        <div key={index}>
          <a href={list.pdf} target="_blank" rel="noreferrer">
            Offense (updated {list.updated})
          </a>
        </div>
      )}

      {defense2025.map((list, index) =>
        <div key={index}>
          <a href={list.pdf} target="_blank" rel="noreferrer">
            Defense (updated {list.updated})
          </a>
        </div>
      )}

    </div>
  )
}

export default GMTargetLists;