import { Col, Container, Row } from "react-bootstrap";

function Home() {
  return (
    <div className="App-content">
      <Container fluid>
        <Row>
          <Col sm={4}>
            <h3>USC Football</h3>
           
            <h4>Schedule</h4>
            <ul>
              <li><a href="#/season/2023">2023 Results</a></li>
              <li><a href="#/season/2024">2024 Schedule</a></li>
              <li><a href="#/future-schedules">Future Non-Coference Schedules</a></li>
            </ul>
            
            <h4>Roster</h4>
            <ul>
              <li><a href="#/roster-name">Name Roster</a></li>
              <li><a href="#/roster-number">Number Roster</a></li>
              <li><a href="#/player-major">Players by Major</a></li>
              <li><a href="#/roster-geographic">Players by High School Region</a></li>
            </ul>
            
            <ul>
              <li><a href="#/roster-offense">Offensive Depth Chart</a></li>
              <li><a href="#/roster-defense">Defensive Depth Chart</a></li>
              <li><a href="#/roster-special-teams">Special Teams Depth Chart</a></li>
            </ul>

            <ul>
              <li><a href="#/roster-eligibility">Scholarship Distribution Chart</a></li>
              <li><a href="#/coaching-staff">Coaching Staff</a></li>
            </ul>

            <br/>
            <br/>

            <h3>Looking Ahead</h3>
            <div><img src="/img/B1GPatch.png" alt="B1G Patch" width="160" /></div>
            <br/>
            
            <ul>
              <li><a href="#/big-ten/versus-usc">USC All-Time Records Versus Big Ten</a></li>
              <li><a href="#/big-ten/rivals">Traditional Big Ten Rivals</a></li>
              <li><a href="#/big-ten/schedules">Future Big Ten Conference Schedules</a></li>
            </ul>

            <br/>
            <br/>
          </Col>



          <Col sm={4}>
            <h3>Last Game</h3>
            <table className="game-table">
              <tbody>
                <tr>
                  <td width="80" align="center"><img src="/img/usu.png" width="80" alt="Utah State" /></td>
                    <td align="center">
                      <h4>Utah State 0<br/>#13 USC 48</h4>
                    </td>
                  <td width="80" align="center"><img src="/img/usc.png" width="80" alt="USC" /></td>
                </tr>
              </tbody>
            </table>

            <table className="game-table">
              <tbody>
                <tr>
                  <td width="150" className={"row-head"}>Date:</td>
                  <td width="450">September 7, 2024</td>
                </tr> 

                <tr>
                  <td className={"row-head"}></td>
                  <td>
                    <a href="https://usctrojans.com/news/2024/9/7/usc-trojans-football-game-recap-ap-associated-press-utah-state-aggies-defense-lincoln-riley-quinten-joyner.aspx" target="_blank">Recap</a> | <a href="https://usctrojans.com/sports/football/stats/2024/utah-state/boxscore/33371" target="_blank">Final Stats</a> | <a href="https://www.youtube.com/watch?v=D9vQViB6T30" target="_blank">Highlighhts</a></td>
                </tr>
              </tbody>
            </table>

            <br/>
            <br/>

            <h3>Next Up</h3>
            <table className="game-table">
              <tbody>
                <tr>
                  <td width="80" align="center"><img src="/img/usc.png" width="80" alt="USC" /></td>
                  <td align="center">
                    <h4>#11 USC<br/>at #18 Michigan</h4>
                  </td>
                  <td width="80" align="center"><img src="/img/michigan.png" width="80" alt="Michigan" /></td>
                </tr>
              </tbody>
            </table>

            <table className="game-table">
              <tbody>
                <tr>
                  <td width="150" className={"row-head"}>Date:</td>
                  <td width="450">September 21, 2024</td>
                </tr> 

                <tr>
                  <td className={"row-head"}>Time:</td>
                  <td>12:30 PT</td>
                </tr>
                
                <tr>
                  <td className={"row-head"}>Location:</td>
                  <td>Ann Arbor, Michigan</td>
                </tr>

                <tr>
                  <td className={"row-head"}>Televison:</td>
                  <td>
                    CBS
                  </td>
                </tr>

                <tr>
                  <td className={"row-head"}>Radio:</td>
                  <td>
                    790 KABC: Pete Arbogast, Shaun Cody<br/>
                  </td>
                </tr>

                <tr>
                  <td className={"row-head"}>Trojan Tailgate Show:</td>
                  <td>
                    10:30 PT<br/>
                    <a href="https://usctrojans.com/sports/2022/9/30/usc-football-trojan-tailgate-show-presented-by-yeti-790-kabc-radio-pregame.aspx" target="_blank">Live Stream</a>
                  </td>
                </tr>

                <tr>
                  <td className={"row-head"}>Live Stats:</td>
                  <td>
                    <a href="https://usctrojans.com/sidearmstats/football/summary" target="_blank">Live Stats and Play-by-Play</a>
                  </td>
                </tr>

                <tr>
                  <td className={"row-head"}>All-Time Record:</td>
                  <td>
                    <a href="https://www.winsipedia.com/usc/vs/michigan" target="_blank">USC vs. Michigan: 6-4</a>
                  </td>
                </tr>

                {/* <tr>
                  <td className={"row-head"}>Game Notes:</td>
                  <td>
                    <a href="https://s3.us-east-2.amazonaws.com/sidearm.nextgen.sites/usctrojans.com/documents/2024/9/4/usc-trojans-football-utah-state-aggies-game-notes.pdf" target="_blank">Official USC Release</a>
                  </td>
                </tr> */}

                <tr>
                  <td className={"row-head"}>Media Guide:</td>
                  <td>
                    <a href="https://s3.us-east-2.amazonaws.com/sidearm.nextgen.sites/usctrojans.com/documents/2024/8/26/2024-usc-trojans-football-media-guide-v3-compressed.pdf" target="_blank">2024 USC Trojan Football Media Guide</a>
                  </td>
                </tr>
              </tbody>
            </table>

            <br/>
            <br/>
          </Col>



          <Col sm={4}>
            <h3>Week 4 TV Schedule</h3>
            <p><i>All Times Pacific</i></p>
           
            <h4>Thursday 9/19</h4>
            <p>
              South Alabama at Appalachian State 4:30 ESPN<br/>
            </p>

            <h4>Friday 9/20</h4>
            <p>
              Stanford at Syracuse 4:30 ESPN<br/>
              <b>#24 Illinois at #22 Nebraska 5:00 FOX</b><br/>
              San Jose State at Washington State 7:00 CW<br/>
            </p>

            <h4>Saturday 9/21</h4>
            <p>
              <b>NC State at #21 Clemson 10:00 ABC</b><br/>
              Marshall at #3 Ohio State 10:00 FOX<br/>
              <b>Florida at Mississippi State 10:00 ESPN</b><br/>
              Kansas at West Virginia 10:00 ESPN2<br/>
              Houston at Cincinnati 10:00  FS1<br/>
              Charlotte at Indiana 10:00 BTN<br/>
              Villanova at Maryland 10:00 BTN<br/>
              Ohio at Kentucky 10:45 SECN<br/>
              James Madison at North Carolina 10:00 ACCN<br/>
              Rice at Army 10:00 CBSSN<br/>
              <br/>
              <b>#11 USC at #18 Michigan 12:30 CBS</b><br/>
              <b>UCLA at #16 LSU 12:30 ABC</b><br/>
              Miami (OH) at #17 Notre Dame 12:30 NBC<br/>
              <b>Arkansas at Auburn 12:30 ESPN</b><br/>
              Arizona State at Texas Tech 12:30 FS1<br/>
              Georgia Tech at #19 Louisville 12:30 ESPN2<br/>
              Kent State at #10 Penn State 12:30 BTN<br/>
              Memphis at Navy 12:30 CBSSN<br/>
              Rutgers at Virginia Tech 12:30 ACCN<br/>
              <b>#12 Utah at #14 Oklahoma State 1:00 FOX</b><br/>
              Duke at Middle Tennessee 1:00 ESPNU<br/>
              Vanderbilt at #7 Missouri 1:15 SECN<br/>
              TCU at SMU 2:00 CW<br/>
              UTEP at Colorado State 2:00 truTV<br/>
              <br/>
              #8 Miami at South Florida 4:00 ESPN<br/>
              <b>Cal at Florida State 4:00 ESPN2</b><br/>
              Northwestern at Washington 4:00 FS1<br/>
              Florida Atlantic at UConn 4:00 CBSSN<br/>
              <b>#6 Tennessee at #15 Oklahoma 4:30 ABC</b><br/>
              <b>Iowa at Minnesota 4:30 NBC</b><br/>
              Akron at South Carolina 4:30 ESPNU<br/>
              Georgia Southern at #5 Ole Miss 4:45 SECN<br/>
              <b>Baylor at Colorado 5:00 FOX</b><br/>
              Michigan State at Boston College 5:00 ACCN<br/>
              Fresno State at New Mexico 5:30 truTV<br/>
              Purdue at Oregon State 5:30 CW<br/>
              <br/>
              <b>#13 Kansas State at BYU 7:30 ESPN</b><br/>
              Portland State at Boise State 7:00 FS1<br/>
            </p>

            <br/>
            <br/>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Home;