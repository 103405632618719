function TVSchedules() {
  return (
    <div className="App-content">
      <h3>College Football Television Schedule</h3>
        LSUFootball.net has long produced the most comprehensive and up to date college football television listing throughout the season at <a href="https://www.lsufootball.net/tvschedule.htm" target="_blank">lsufootball.net/tvschedule.htm</a>.
      <p></p>
    </div>
  )
}

export default TVSchedules;