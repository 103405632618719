import Data from '../../Data';
import Table from 'react-bootstrap/Table';

function Player({player}) {
  if (player.link) {
    return <div>{(player.walkOn ? '# ' : '') + player.displaypos + ' '}<a href={"https://usctrojans.com/sports/football/roster/" + player.link} target={"_blank"} rel="noreferrer">{player.firstName + ' ' + player.lastName + (player.suffix ? ' ' + player.suffix : '')}</a>{' ' + (player.redshirt ? 'Rs-' : '') + player.class}</div>;
  }

  return <div>{(player.walkOn ? '# ' : '') + player.displaypos + ' ' + player.firstName + ' ' + player.lastName + (player.suffix ? ' ' + player.suffix : '') + ' ' + (player.redshirt ? 'Rs-' : '') + player.class}</div>;
}

function AvailableNumbers() {
  const file = '2024roster';
  const sortOrder = [];
  let jerseyNumbers = [];

  for (let i=0; i<100; i++) {
    const criteriaOff = [{prop: 'enrolled', value: 'TRUE'}, {prop: 'team', value: 'OFF'}, {prop: 'jersey', value: i}];
    const criteriaDef = [{prop: 'enrolled', value: 'TRUE'}, {prop: 'team', value: 'DEF'}, {prop: 'jersey', value: i}];
    const criteriaST = [{prop: 'enrolled', value: 'TRUE'}, {prop: 'team', value: 'ST'}, {prop: 'jersey', value: i}];
    const criteriaNew = [{prop: 'jersey', operator: 'empty'}, {prop: 'priorJersey', value: i}];

    jerseyNumbers.push(
      {
        jerseyNumber: i, 
        allPlayers: [
          Data(file, sortOrder, criteriaOff), 
          Data(file, sortOrder, criteriaDef), 
          Data(file, sortOrder, criteriaST),
          Data(file, sortOrder, criteriaNew)
        ]
      }
    );
  }

  jerseyNumbers[3].retired = 'Carson Palmer';
  jerseyNumbers[5].retired = 'Reggie Bush';
  jerseyNumbers[11].retired = 'Matt Lienart';
  jerseyNumbers[12].retired = 'Charles White';
  jerseyNumbers[13].retired = 'Caleb Williams';
  jerseyNumbers[20].retired = 'Mike Garrett';
  jerseyNumbers[32].retired = 'O.J. Simpson';
  jerseyNumbers[33].retired = 'Marcus Allen';

  return (
    <div className="App-content">
      <h3>2024 Available Jersey Numbers</h3>

      <p>Also see <a href="#/all-time-jersey-numbers">All-Time Jersey Numbers</a></p>
            
      <p>Jerseys worn by USC Heisman Trophy winners Carson Palmer (3), Reggie Bush (5), Matt Leinart (11), Charles White (12), Caleb Williams (13), Mike Garrett (20), 
      O.J. Simpson (32), and Marcus Allen (33), have been (or likely will be) retired.</p>

      <p>Newcomers are listed by their last known high school or college jersey number.</p>

      <p># Non-scholarship player</p>

      <Table bordered hover size="sm">
        <thead>
          <tr>
            <th width="30" className={"centeredCol"}>No</th>
            <th width="200" className={"centeredCol"}>Offense</th>
            <th width="200" className={"centeredCol"}>Defense</th>
            <th width="200" className={"centeredCol"}>Special Teams</th>
            <th width="200" className={"centeredCol"}>Newcomers</th>
          </tr>
        </thead>
        <tbody>
          {jerseyNumbers.map((jersey, i) =>
            <tr key={i}  className={jersey.retired ? 'retired' : ''}>
              <td align="center">{jersey.jerseyNumber}</td>
              {jersey.allPlayers.map((players, j) =>
                <td key={j}>
                  {players.map((player, k) =>
                    <div key={k}>
                      <Player player={player} />
                    </div>
                  )}
                </td>
              )}
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  )
}

export default AvailableNumbers;
