function VisitScorecard() {
  return (
    <div className="App-content">
      <h3>Official Visit Scorecard</h3>

      <h4>Weekend of May 19-21</h4>
      <p>
        <span class="off-the-table">WR <b>Bryant Wesco</b> 6-2 170 Midlothian, TX Midlothian - committed to Clemson 6/6</span><br/>
        <span class="off-the-table">DE <b>Eddrick Houston</b> 6-3 255 Buford, GA - committed to Ohio State 8/22</span><br/>
        <span class="off-the-table">S <b>Myles Davis</b> 6-1 185 Converse, TX Judson - committed to Texas A&M 6/23</span><br/>
        <span class="committed">CB <b>Braylon Conley</b> 6-0 170 Humble, TX Atascocita - committed to USC 6/30</span><br/>
      </p>

      <br/><h4>Weekend of June 2-4</h4>
      <p>
        <span class="off-the-table">RB <b>Taylor Tatum</b> 5-10 205 Longview, TX Longview -  committed to Oklahoma 7/21</span><br/>
        <span class="committed">TE <b>Walter Matthews</b> 6-7 245 Moultrie, GA Hiram - committed to USC 6/14</span><br/>
        <span class="off-the-table">OT <b>Manasse Itete</b> 6-5 290 Republic of the Congo / Modesto, CA Central Catholic  - committed to USC 6/5, decommitted 7/28, committed to Florida State 7/29</span><br/>
        <span class="off-the-table">OT <b>Isaiah Garcia</b> 6-5 280 Draper, UT Corner Canyon - committed to Utah 7/29</span><br/>
        <span class="off-the-table">OT <b>Fox Crader</b> 6-6 285 Vancouver, WA Evergreen - previously committed to Oregon 11/21/22</span><br/>
        <span class="committed">OG <b>Hayden Treter</b> 6-6 300 Englewood, CO Cherry Creek -  committed to USC 6/4</span><br/>
        <span class="committed">RUSH <b>Kameryn Fountain</b> 6-5 235 Atlanta, GA Booker T. Washington - committed to USC 6/5</span><br/>
        <span class="committed">LB <b>Elijah Newby</b> 6-3 210 Cheshire, CT Cheshire Academy - committed to USC 6/9</span><br/>
        <span class="off-the-table">S <b>Jaylen McClain</b> 6-0 180 West Orange, NJ Seton Hall - committed to Ohio State 6/12</span><br/>
        <span class="committed">S <b>Jarvis Boatwright Jr.</b> 6-1 170 Clearwater, FL Clearwater - committed to USC 6/4</span><br/>
        <span class="off-the-table">CB <b>Zabien Brown</b> 6-0 180 Santa Ana, CA Mater Dei - committed to Alabama 6/24</span><br/>
        <span class="committed">CB <b>Dakoda Fields</b> 6-2 175 Gardena, CA Serra - committed to USC 6/5, decommmitted and committed to Oregon 8/7</span><br/>
      </p>

      <br/>
      <h4>Weekend of June 9-11</h4>
      <p>
        <span class="off-the-table">LB <b>Kyngstonn Viliamu-Asa</b> 6-3 225 Bellflower, CA St. John Bosco - committed to Notre Dame 7/23</span><br/>
        <span class="off-the-table">S <b>Peyton Woodyard</b> 6-2 190 Bellflower, CA St. John Bosco - previously committed to Georgia 1/7, decommitted and committed to Alabama 8/8 </span><br/>
      </p>

      <br/>
      <h4>Weekend of June 16-18</h4>
      <p>
        <span class="committed">RB <b>Bryan Jackson</b> 6-1 240 McKinney, TX McKinney - previously committed to USC 4/21</span><br/>
        <span class="committed">WR <b>Xavier Jordan</b> 6-1 165 Chatsworth, CA Sierra Canyon - previously committed to USC 5/1</span><br/>
        <span class="committed">WR <b>Ryan Pellum</b> 5-11 175 Long Beach, CA Millikan - committed to USC 6/26</span><br/>
        <span class="off-the-table">WR <b>Drelon Miller</b> 6-2 200 Silsbee, TX Silsbee - committed to Texas A&M 6/29, decommitted 10/17, committed to Colorado 12/9</span><br/>
        <span class="off-the-table">WR <b>Mike Matthews</b> 6-2 180 Lilburn, GA Parkview - committed to Tennessee 7/19</span><br/>
        <span class="committed">TE <b>Joey Olsen</b> 6-5 220 Lake Oswego, OR Lakeridge - previously committed to USC 9/14/2022</span><br/>
        <span class="committed">OG <b>Makai Saina</b> 6-4 290 Arlington, TX Martin - committed to USC 6/27</span><br/>
        <span class="committed">C <b>Jason Zandamela</b> 6-3 285 Mozambique / Clearwater, FL Clearwater International Academy - committed to USC 6/20</span><br/>
        <span class="off-the-table">DE <b>Darien Mayo</b> 6-7 250 Olney, MD Good Counsel - committed to Clemson 6/22</span><br/>
        <span class="off-the-table">RUSH <b>Marquise Lightfoot</b> 6-5 220 Chicago, IL Kenwood Academy - committed to Miami 7/3</span><br/>
        <span class="off-the-table">RUSH <b>Jaylen Harvey</b> 6-2 235 Gaitherburg, MD Quince Orchard - committed to Penn State 10/16</span><br/>
        <span class="off-the-table">LB <b>Tyanthony Smith</b> 6-4 205 Jasper, TX - committed to Texas A&M 6/30</span><br/>
        <span class="committed">S <b>Marquis Gallegos</b> 6-1 175 Chatsworth, CA Sierra Canyon - committed to USC 6/27</span><br/>
        <span class="committed">CB <b>Marcelles Williams</b> 5-11 170 Bellflower, CA St. John Bosco - committed to USC 6/18</span><br/>
        <span class="off-the-table">CB <b>Sione Laulea</b> 6-4 185 San Mateo, CA College of San Mateo - committed to Oregon 10/8</span><br/>
      </p>

      <br/>
      <h4>Weekend of June 23-25</h4>
      <p>
        <span class="off-the-table">DL <b>Deyvid Palepale</b> 6-3 305 Landisville, PA Manheim Township - committed to USC 7/1, decommitted from USC and committed to Michigan 12/9</span><br/>
      </p>

      <br/>
      <h4>Weekend of September 1-3</h4>
      <p>
        <span class="off-the-table">LB <b>Chris Cole</b> 6-3 210 Salem, VA Salem - committed to Georgia 9/10</span><br/>
      </p>

      <br/>
      <h4>Weekend of September 8-10</h4>
        <p>
      LB <b>Ephraim Asiata</b> 6-1 220 Salt Lake City, UT Hunter<br/>
      </p>

      <br/>
      <h4>Weekend of October 6-8</h4>
      <p>
        <span class="committed">OL <b>Justin Tauanuu</b> 6-6 285 Huntington Beach, CA Huntington Beach - committed to Stanford 6/1, decommitted 11/9; committed to USC 11/10</span><br/>
      </p>

      <br/>
      <h4>Weekend of November 3-5</h4>
      <p>
        DL <b>Jericho Johnson</b> 6-4 300 Fairfield, CA Armijo<br/>
        <span class="off-the-table">EDGE <b>Gabriel Reliford</b> 6-2 255 Shreveport, LA Evangel Christian Academy - committed to LSU 12/8</span><br/>
      </p>

      <br/>
      <h4>Weekend of November 17-19</h4>
      <p>
        <span class="committed">S <b>Desman Stephens</b> 6-3.5 225 Clarkston, MI Clarkston - committed to USC 11/24</span><br/>
      </p>

      <h4>Weekend of December 8-10</h4>
      <p>
        <span class="">WR <b>Dillon Gresham</b> 6-0	170	San Jacinto, CA San Jacinto - committed to Oregon 5/22</span><br/>
        <span class="committed">OL <b>Nate Clifton</b> 6-5 280 Brentwood, TN Brentwood Academy / Vanderbilt - committed to USC 12/10</span><br/>
        <span class="">OL <b>Maurice Rodrigues</b> 6-7 320 Pasadena, CA Pasadena / Saddleback College</span><br/>
        <span class="committed">OL <b>Kalolo Ta'aga</b> 6-7 265 San Francisco, CA Archbishop Riordan - committed to USC 12/17</span><br/>
        <span class="committed">DL <b>Jide Abasiri</b> 6-5 290 Prior Lake, MN Prior Lake - committed to Minnesota 5/25</span><br/>
        <span class="committed">EDGE <b>Lorenzo Cowan</b> 6-3.5 220 Savannah, GA Jenkins - committed to Kentucky 9/10</span><br/>
        <span class="committed">CB <b>Isaiah Rubin</b> 6-1 170 Los Alamitos, CA Los Alamitos - committed to USC 10/20</span><br/>
      </p>

      <br/>
      <h4>Midweek, December 13</h4>
      <p>
        <span class="off-the-table">RB <b>Mario Anderson</b> 5-9 210 Summerville, SC Stratford / Newberry College / South Carolina - committed to Memphis 12/17</span><br/>
      </p>

      <br/>
      <h4>Weekend of December 15-17</h4>
      <p>
        <span class="">QB <b>Will Howard</b> 6-5 240 Downington, PA Downingtown West / Kansas State</span><br/>
        <span class="">RB <b>Jo'Quavious "Woody" Marks</b> 5-10 210 Atlanta, GA Carver / Mississippi State</span><br/>
        <span class="committed">DL <b>Carlon Jones</b> 6-2 285 Bay City, TX Bay City - committed to USC 12/17</span><br/>
        <span class="committed">LB <b>Easton Mascarenas-Arnold</b> 5-11 235 Mission Viejo, CA Mission Viejo / Oregon State - committed to USC 12/17</span><br/>
        <span class="">LB <b>Jadyn Walker</b> 6-3 205 Portage, MI Portage Northern - committed to Michigan State 9/9</span><br/>
        <span class="committed">S <b>Akili Arnold</b> 5-11 195	Mission Viejo, CA Mission Viejo / Oregon State - committed to USC 12/17</span><br/>
      </p>

  </div>
  )
}

export default VisitScorecard;