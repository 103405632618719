function About() {
      
  return (
    <div className="App-content">
      <h3>About TributeToTroy</h3>
      <p>If you have ideas for additional features or corrections to any our our data, feel free to reach out at <a href="mailto:info@tributetotroy.com">info@tributetotroy.com</a>.</p>
    </div>
  )
}

export default About;