import Table from 'react-bootstrap/Table'

function FutureSchedules() {
  return (
    <div className="App-content">
      <h3>Future and Recent Past Non-Conference Schedules</h3>
      <p><i>Note: USC will be joining the Big Ten conference beginning in 2024. It is not yet clear what changes may be required to the non-conference slate to accommodate the new conference schedule.</i></p>

      <h4>Future Non-Conference Schedules</h4>
      <Table responsive bordered hover size="sm">
        <thead>
          <tr>
            <th width="80" className={"centeredCol"}>Year</th>
            <th width="160"></th>
            <th width="160"></th>
            <th width="160"></th>
            <th width="360" className={"centeredCol"}>Notes</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td align="center">2024</td>
            <td>9/1 LSU (Las Vegas)</td>
            <td>9/7 San José State</td>
            <td>11/30 Notre Dame</td>
            <td>USC will be joining the Big Ten in 2024</td>
          </tr>
          <tr>
            <td align="center">2025</td>
            <td>9/6 Georgia Southern</td>
            <td>TBD*</td>
            <td>10/18 at Notre Dame</td>
            <td>*was Ole Miss</td>
          </tr>
          <tr>
            <td align="center">2026</td>
            <td>9/5 Fresno State</td>
            <td>TBD*</td>
            <td>11/28 Notre Dame</td>
            <td>*was at Ole Miss</td>
          </tr>
          <tr>
            <td align="center">2027</td>
            <td>9/4 UNLV</td>
            <td>9/11 Nevada</td>
            <td>*at Notre Dame</td>
            <td>*Assumed, but not official yet</td>
          </tr>
          <tr>
            <td align="center">2028</td>
            <td>9/9 Fresno State</td>
            <td>TBD</td>
            <td>*Notre Dame</td>
            <td>*Assumed, but not official yet</td>
          </tr>
        </tbody>
      </Table>

      <h4>Recent Past Non-Conference Schedules</h4>
      <Table responsive bordered hover size="sm">
        <thead>
          <tr>
            <th width="80" className={"centeredCol"}>Year</th>
            <th width="120"></th>
            <th width="120"></th>
            <th width="120"></th>
            <th width="120"></th>
            <th width="360" className={"centeredCol"}>Notes</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td align="center">2003</td>
            <td>Hawai'i</td>
            <td>BYU</td>
            <td>at Auburn</td>
            <td>at Notre Dame</td>
            <td>Pac-12 moves to 9-game conference schedule after 2003</td>
          </tr>
          <tr>
            <td align="center">2004</td>
            <td>Colorado State</td>
            <td>at BYU</td>
            <td>*Virginia Tech</td>
            <td>Notre Dame</td>
            <td>*BCA Football Classic (Washington, DC) allowed extra game</td>
          </tr>
          <tr>
            <td align="center">2005</td>
            <td>Fresno State</td>
            <td>Arkansas</td>
            <td>at Notre Dame</td>
            <td>at Hawai'i</td>
            <td>Extra allowed game at Hawai'i</td>
          </tr>
          <tr>
            <td align="center">2006</td>
            <td>at Arkansas</td>
            <td>Nebraska</td>
            <td>Notre Dame</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td align="center">2007</td>
            <td>Idaho</td>
            <td>at Nebraska</td>
            <td>at Notre Dame</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td align="center">2008</td>
            <td>at Virginia</td>
            <td>Ohio State</td>
            <td>Notre Dame</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td align="center">2009</td>
            <td>San José State</td>
            <td>at Ohio State</td>
            <td>at Notre Dame</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td align="center">2010</td>
            <td>at Minnesota</td>
            <td>Virginia</td>
            <td>Notre Dame</td>
            <td>at Hawai'i</td>
            <td>Extra allowed game at Hawai'i</td>
          </tr>
          <tr>
            <td align="center">2011</td>
            <td>Minnesota</td>
            <td>Syracuse</td>
            <td>at Notre Dame</td>
            <td></td>
            <td>Syracuse AD was former assistant AD at USC</td>
          </tr>
          <tr>
            <td align="center">2012</td>
            <td>Hawai'i</td>
            <td>at Syracuse</td>
            <td>Notre Dame</td>
            <td></td>
            <td>Syracuse AD was former assistant AD at USC</td>
          </tr>
          <tr>
            <td align="center">2013</td>
            <td>Utah State</td>
            <td>Boston College</td>
            <td>at Notre Dame</td>
            <td>at Hawai'i</td>
            <td>Extra allowed game at Hawaii</td>
          </tr>
          <tr>
            <td align="center">2014</td>
            <td>Fresno State</td>
            <td>at Boston College</td>
            <td>Notre Dame</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td align="center">2015</td>
            <td>Idaho</td>
            <td>*Arkansas State</td>
            <td>at Notre Dame</td>
            <td></td>
            <td>*Originally Texas A&M (until they joined the SEC)</td>
          </tr>
          <tr>
            <td align="center">2016</td>
            <td>Utah State</td>
            <td>*Alabama (TX)</td>
            <td>Notre Dame</td>
            <td></td>
            <td>*Originally at Texas A&M (until they joined the SEC)</td>
          </tr>
          <tr>
            <td align="center">2017</td>
            <td>Western Michigan</td>
            <td>Texas</td>
            <td>at Notre Dame</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td align="center">2018</td>
            <td>UNLV</td>
            <td>at Texas</td>
            <td>Notre Dame</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td align="center">2019</td>
            <td>Fresno State</td>
            <td>at BYU</td>
            <td>at Notre Dame</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td align="center">2020</td>
            <td className={"strike-through"}>New Mexico</td>
            <td className={"strike-through"}>Alabama (TX)</td>
            <td className={"strike-through"}>Notre Dame</td>
            <td></td>
            <td>Pac-12 non-conference games cancelled due to Covid</td>
          </tr>
          <tr>
            <td align="center">2021</td>
            <td>San José State</td>
            <td>*BYU</td>
            <td>at Notre Dame</td>
            <td></td>
            <td>*Originally Tennessee (until Kiffin left) and then (FCS) UC Davis</td>
          </tr>
          <tr>
            <td align="center">2022</td>
            <td>Fresno State</td>
            <td>*Rice</td>
            <td>Notre Dame</td>
            <td></td>
            <td>*Originally at Tennessee (until Kiffin left)</td>
          </tr>
          <tr>
            <td align="center">2023</td>
            <td>*San José State</td>
            <td>Nevada</td>
            <td>at Notre Dame</td>
            <td></td>
            <td>*Originally BYU (until they joined the Big 12)</td>
          </tr>
        </tbody>
      </Table>
    </div>
  )
}

export default FutureSchedules;