function BigTenSchedules() {
  return (
    <div className="App-content">
      <h3>Big Ten Conference Schedules 2024-2028</h3>

      <p>Source: <a href="https://bigten.org/news/2023/10/4/big-ten-conference-announces-future-football-schedule-formats-for-2024-28.aspx" target="_blank">Big Ten conference</a></p>

      <p>Annual protected matchups:</p>
      <ul>
        <li>Illinois-Northwestern</li>
        <li>Illinois-Purdue</li>
        <li>Indiana-Purdue</li>
        <li>Iowa-Minnesota</li>
        <li>Iowa-Nebraska</li>
        <li>Iowa-Wisconsin</li>
        <li>Maryland-Rutgers</li>
        <li>Michigan-Michigan State</li>
        <li>Michigan-Ohio State</li>
        <li>Minnesota-Wisconsin</li>
        <li>Oregon-Washington</li>
        <li>UCLA-USC</li>
      </ul>

      <p><img src="/img/B1G2024.png" alt="2024 Big Ten Schedule" width="100%" /></p>
      <p><img src="/img/B1G2025.png" alt="2025 Big Ten Schedule" width="100%" /></p>
      <p><img src="/img/B1G2026.png" alt="2026 Big Ten Schedule" width="100%" /></p>
      <p><img src="/img/B1G2027.png" alt="2027 Big Ten Schedule" width="100%" /></p>
      <p><img src="/img/B1G2028.png" alt="2028 Big Ten Schedule" width="100%" /></p>

    </div>
  )
}

export default BigTenSchedules;