import Table from 'react-bootstrap/Table';
import SeasonSelect from './SeasonSelect';

function Season2024() {
  return (
    <div className="App-content">
      <h3>2024 Schedule</h3>

      <SeasonSelect />

      <Table responsive bordered hover size="sm">
        <thead>
        <tr>
            <th></th>
            <th></th>
            <th></th>
            <th className={"centeredCol"}>Pacific</th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th className={"centeredCol"} colSpan={2}>USC</th>
            <th className={"centeredCol"} colSpan={2}>Opponent</th>
            <th className={"centeredCol"} colSpan={2}>Opp Current</th>
          </tr>
          <tr>
            <th width="50" className={"centeredCol"}>Date</th>
            <th width="125" className={"centeredCol"}>Opponent</th>
            <th width="100" className={"centeredCol"}>Conference</th>
            <th width="75" className={"centeredCol"}>Time</th>
            <th width="100" className={"centeredCol"}>TV</th>
            <th width="125" className={"centeredCol"}>Location</th>
            <th width="50" className={"centeredCol"}>Line</th>
            <th width="75" className={"centeredCol"}>Result</th>
            <th width="50" className={"centeredCol"}>Record</th>
            <th width="50" className={"centeredCol"}>Rank</th>
            <th width="50" className={"centeredCol"}>Record</th>
            <th width="50" className={"centeredCol"}>Rank</th>
            <th width="50" className={"centeredCol"}>Record</th>
            <th width="50" className={"centeredCol"}>Rank</th>
          </tr>
        </thead>
        <tbody>
          <tr className="monthRow">
            <td colSpan={14}>
              September
            </td>
          </tr>
          <tr>
            <td align="center">1 Sun</td>
            <td>LSU</td>
            <td>SEC</td>
            <td align="center">4:30</td>
            <td align="center">ABC</td>
            <td>Las Vegas, NV</td>
            <td align="center" className={"win"}>+4</td>
            <td align="center" className={"win"}>27-20</td>
            <td align="center">0-0</td>
            <td align="center">23</td>
            <td align="center">0-0</td>
            <td align="center">13</td>
            <td align="center">2-1</td>
            <td align="center">16</td>
          </tr>
          <tr>
            <td align="center">7</td>
            <td>Utah State</td>
            <td>Mountain West</td>
            <td align="center">8:00</td>
            <td align="center">B1G Network</td>
            <td>L.A. Coliseum</td>
            <td align="center" className={"win"}>-31</td>
            <td align="center" className={"win"}>48-0</td>
            <td align="center">1-0</td>
            <td align="center">13</td>
            <td align="center">1-0</td>
            <td align="center">-</td>
            <td align="center">1-2</td>
            <td align="center">-</td>
          </tr>
          <tr className="bye">
            <td align="center">14</td>
            <td></td>
            <td></td>
            <td align="center"></td>
            <td align="center"></td>
            <td></td>
            <td align="center"></td>
            <td align="center"></td>
            <td align="center"></td>
            <td align="center"></td>
            <td align="center"></td>
            <td align="center"></td>
            <td align="center"></td>
            <td align="center"></td>
          </tr>
          <tr>
            <td align="center">21</td>
            <td>Michigan</td>
            <td>Big Ten</td>
            <td align="center">12:30</td>
            <td align="center">CBS</td>
            <td>Ann Arbor, MI</td>
            <td align="center">-5.5</td>
            <td align="center"></td>
            <td align="center">2-0</td>
            <td align="center">11</td>
            <td align="center">2-1</td>
            <td align="center">18</td>
            <td align="center">2-1</td>
            <td align="center">18</td>
          </tr>
          <tr>
            <td align="center">28</td>
            <td>Wisconsin</td>
            <td>Big Ten</td>
            <td align="center">TBD</td>
            <td align="center">TBD</td>
            <td>L.A. Coliseum</td>
            <td align="center">-4.5</td>
            <td align="center"></td>
            <td align="center"></td>
            <td align="center"></td>
            <td align="center"></td>
            <td align="center"></td>
            <td align="center">2-1</td>
            <td align="center">-</td>
          </tr>

          <tr className="monthRow">
            <td colSpan={14}>
              October
            </td>
          </tr>
          <tr>
            <td align="center">5</td>
            <td>Minnesota</td>
            <td>Big Ten</td>
            <td align="center">TBD</td>
            <td align="center">TBD</td>
            <td>Minneapolis, MN</td>
            <td align="center"></td>
            <td align="center"></td>
            <td align="center"></td>
            <td align="center"></td>
            <td align="center"></td>
            <td align="center"></td>
            <td align="center">2-1</td>
            <td align="center">-</td>
          </tr>
          <tr>
            <td align="center">12</td>
            <td>Penn State</td>
            <td>Big Ten</td>
            <td align="center">TBD</td>
            <td align="center">TBD</td>
            <td>L.A. Coliseum</td>
            <td align="center">+4.5</td>
            <td align="center"></td>
            <td align="center"></td>
            <td align="center"></td>
            <td align="center"></td>
            <td align="center"></td>
            <td align="center">2-0</td>
            <td align="center">10</td>
          </tr>
          <tr>
            <td align="center">19</td>
            <td>Maryland</td>
            <td>Big Ten</td>
            <td align="center">TBD</td>
            <td align="center">TBD</td>
            <td>College Park, MD</td>
            <td align="center"></td>
            <td align="center"></td>
            <td align="center"></td>
            <td align="center"></td>
            <td align="center"></td>
            <td align="center"></td>
            <td align="center">2-1</td>
            <td align="center">-</td>
          </tr>
          <tr>
            <td align="center">25 Fri</td>
            <td>Rutgers</td>
            <td>Big Ten</td>
            <td align="center">8:00</td>
            <td align="center">FOX</td>
            <td>L.A. Coliseum</td>
            <td align="center"></td>
            <td align="center"></td>
            <td align="center"></td>
            <td align="center"></td>
            <td align="center"></td>
            <td align="center"></td>
            <td align="center">2-0</td>
            <td align="center">-</td>
          </tr>

          <tr className="monthRow">
            <td colSpan={14}>
              November
            </td>
          </tr>
          <tr>
            <td align="center">2</td>
            <td>Washington</td>
            <td>Big Ten</td>
            <td align="center">TBD</td>
            <td align="center">TBD</td>
            <td>Seattle, WA</td>
            <td align="center"></td>
            <td align="center"></td>
            <td align="center"></td>
            <td align="center"></td>
            <td align="center"></td>
            <td align="center"></td>
            <td align="center">2-1</td>
            <td align="center">-</td>
          </tr>
          <tr className="bye">
            <td align="center">9</td>
            <td></td>
            <td></td>
            <td align="center"></td>
            <td align="center"></td>
            <td></td>
            <td align="center"></td>
            <td align="center"></td>
            <td align="center"></td>
            <td align="center"></td>
            <td align="center"></td>
            <td align="center"></td>
            <td align="center"></td>
            <td align="center"></td>
          </tr>
          <tr>
            <td align="center">16</td>
            <td>Nebraska</td>
            <td>Big Ten</td>
            <td align="center">TBD</td>
            <td align="center">TBD</td>
            <td>L.A. Coliseum</td>
            <td align="center"></td>
            <td align="center"></td>
            <td align="center"></td>
            <td align="center"></td>
            <td align="center"></td>
            <td align="center"></td>
            <td align="center">3-0</td>
            <td align="center">22</td>
          </tr>
          <tr>
            <td align="center">23</td>
            <td>UCLA</td>
            <td>Big Ten</td>
            <td align="center">TBD</td>
            <td align="center">TBD</td>
            <td>Pasadena, CA</td>
            <td align="center">-6.5</td>
            <td align="center"></td>
            <td align="center"></td>
            <td align="center"></td>
            <td align="center"></td>
            <td align="center"></td>
            <td align="center">1-1</td>
            <td align="center">-</td>
          </tr>
          <tr>
            <td align="center">30</td>
            <td>Notre Dame</td>
            <td>Independent</td>
            <td align="center">TBD</td>
            <td align="center">TBD</td>
            <td>L.A. Coliseum</td>
            <td align="center">+3.5</td>
            <td align="center"></td>
            <td align="center"></td>
            <td align="center"></td>
            <td align="center"></td>
            <td align="center"></td>
            <td align="center">2-1</td>
            <td align="center">17</td>
          </tr>

          <tr className="monthRow">
            <td colSpan={14}>
              December 
            </td>
          </tr>
          <tr>
            <td align="center">7</td>
            <td>Conference Championship</td>
            <td>Big Ten</td>
            <td align="center">TBD</td>
            <td align="center">TBD</td>
            <td>Indianapolis, IN</td>
            <td align="center"></td>
            <td align="center"></td>
            <td align="center"></td>
            <td align="center"></td>
            <td align="center"></td>
            <td align="center"></td>
            <td align="center"></td>
            <td align="center"></td>
          </tr>
        </tbody>
      </Table>
    </div>
  )
}

export default Season2024;