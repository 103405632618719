import Table from 'react-bootstrap/Table'

function VersusUSC() {
  return (
    <div className="App-content">
      <h3>All-Time Against the Big Ten</h3>
      <p>Through the 2023 college football season</p>

      <Table responsive bordered hover size="sm">
        <thead>
          <tr>
            <th width="175" className={"centeredCol"}>Opponent</th>
            <th width="175" className={"centeredCol"}>Home Venue</th>
            <th width="75" className={"centeredCol"}>Capacity</th>
            <th width="175" className={"centeredCol"}>Location</th>
            <th width="50" className={"centeredCol"}>Games</th>
            <th width="50" className={"centeredCol"}>First</th>
            <th width="60" className={"centeredCol"}>Record</th>
            <th width="50" className={"centeredCol"}>Win %</th>
            <th width="60" className={"centeredCol"}>Home</th>
            <th width="60" className={"centeredCol"}>Road</th>
            <th width="60" className={"centeredCol"}>Bowls</th>
            <th width="275" className={"centeredCol"}>Bowl Games</th>
            </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <a href="https://nusports.com/sports/football" target={"_blank"} rel="noreferrer">
                Northwestern Wildcats
              </a>
            </td>
            <td>Ryan Field</td>
            <td align="center">47,130</td>
            <td>Evanstown, Illinois</td>
            <td align="center">5</td>
            <td align="center">1952</td>
            <td align="center">5-0</td>
            <td align="center">1.000</td>
            <td align="center">2-0</td>
            <td align="center">2-0</td>
            <td align="center">1-0</td>
            <td>
              <a href="https://en.wikipedia.org/wiki/1996_Rose_Bowl" target={"_blank"} rel="noreferrer">1996 Rose Bowl: USC 41-32</a>
            </td>
          </tr>

          <tr>
            <td>
              <a href="https://iuhoosiers.com/sports/football" target={"_blank"} rel="noreferrer">
                Indiana Hooisers
              </a>
            </td>
            <td>Memorial Stadium</td>
            <td align="center">52,959</td>
            <td>Bloomington, Indiana</td>
            <td align="center">4</td>
            <td align="center">1963</td>
            <td align="center">4-0</td>
            <td align="center">1.000</td>
            <td align="center">2-0</td>
            <td align="center">1-0</td>
            <td align="center">1-0</td>
            <td>1968 Rose Bowl: USC 14-3</td>
          </tr>

          <tr>
            <td>
              <a href="https://huskers.com/sports/football" target={"_blank"} rel="noreferrer">
                Nebraska Cornhuskers
              </a>
            </td>
            <td>Memorial Stadium</td>
            <td align="center">85,458</td>
            <td>Lincoln, Nebraska</td>
            <td align="center">5</td>
            <td align="center">1969</td>
            <td align="center">4-0-1</td>
            <td align="center">.900</td>
            <td align="center">1-0-1</td>
            <td align="center">2-0</td>
            <td align="center">1-0</td>
            <td>2014 Holiday Bowl: USC 45-42</td>
          </tr>

          <tr>
            <td>
              <a href="https://uwbadgers.com/sports/football" target={"_blank"} rel="noreferrer">
                Wisconsin Badgers
              </a>
            </td>
            <td>Camp Randall Stadium</td>
            <td align="center">80,321</td>
            <td>Madison, Wisconsin</td>
            <td align="center">7</td>
            <td align="center">1963</td>
            <td align="center">6-1</td>
            <td align="center">.857</td>
            <td align="center">2-0</td>
            <td align="center">2-0</td>
            <td align="center">2-1</td>
            <td>
              1952 Rose Bowl: USC 7-0<br/>
              <a href="https://en.wikipedia.org/wiki/1963_Rose_Bowl" target={"_blank"} rel="noreferrer">1963 Rose Bowl: USC 42-37</a><br/>
              2015 Holiday Bowl: Wisconsin 23-21
            </td>
          </tr>

          <tr>
            <td>
              <a href="https://fightingillini.com/sports/football" target={"_blank"} rel="noreferrer">
                Illinois Fighting Illini
              </a>
            </td>
            <td>Memorial Stadium</td>
            <td align="center">60,670</td>
            <td>Champaign, Illinois</td>
            <td align="center">13</td>
            <td align="center">1935</td>
            <td align="center">11-2</td>
            <td align="center">.846</td>
            <td align="center">4-2</td>
            <td align="center">6-0</td>
            <td align="center">1-0</td>
            <td>2008 Rose Bowl: USC 49-17</td>
          </tr>

          <tr>
            <td>
              <a href="https://gophersports.com/sports/football" target={"_blank"} rel="noreferrer">
                Minnesota Golden Gophers
              </a>
            </td>
            <td>Huntington Bank Stadium</td>
            <td align="center">50,805</td>
            <td>Minneapolis, Minnesota</td>
            <td align="center">8</td>
            <td align="center">1953</td>
            <td align="center">6-1-1</td>
            <td align="center">.813</td>
            <td align="center">3-0-1</td>
            <td align="center">3-1</td>
            <td align="center"></td>
            <td></td>
          </tr>

          <tr>
            <td>
              <a href="https://purduesports.com/sports/football" target={"_blank"} rel="noreferrer">
                Purdue Boilermakers
              </a>
            </td>
            <td>Ross-Ade Stadium</td>
            <td align="center">57,236</td>
            <td>West Lafayette, Indiana</td>
            <td align="center">4</td>
            <td align="center">1967</td>
            <td align="center">3-1</td>
            <td align="center">.750</td>
            <td align="center">2-0</td>
            <td align="center">1-0</td>
            <td align="center">0-1</td>
            <td>1967 Rose Bowl: Purdue 14-13</td>
          </tr>

          <tr>
            <td>
              <a href="https://hawkeyesports.com/sports/football/" target={"_blank"} rel="noreferrer">
                Iowa Hawkeyes
              </a>
            </td>
            <td>Kinnick Stadium</td>
            <td align="center">70,585</td>
            <td>Iowa City, Iowa</td>
            <td align="center">10</td>
            <td align="center">1925</td>
            <td align="center">7-3</td>
            <td align="center">.700</td>
            <td align="center">3-2</td>
            <td align="center">3-0</td>
            <td align="center">1-1</td>
            <td>
              <a href="https://en.wikipedia.org/wiki/2003_Orange_Bowl" target={"_blank"} rel="noreferrer">2003 Orange Bowl: USC 38-17</a><br/>
              2019 Holiday Bowl: Iowa 49-24
            </td>
          </tr>

          <tr>
            <td>
              <a href="https://goducks.com/sports/football" target={"_blank"} rel="noreferrer">
                Oregon Ducks
              </a>
            </td>
            <td>Autzen Stadium</td>
            <td align="center">54,000</td>
            <td>Eugene, Oregon</td>
            <td align="center">64</td>
            <td align="center">1915</td>
            <td align="center">39-23-2</td>
            <td align="center">.625</td>
            <td align="center">25-10-1</td>
            <td align="center">13-13-1</td>
            <td align="center"></td>
            <td></td>
          </tr>

          <tr>
            <td>
              <a href="https://gohuskies.com/sports/football" target={"_blank"} rel="noreferrer">
                Washington Huskies
              </a>
            </td>
            <td>Hushy Stadium</td>
            <td align="center">70,083</td>
            <td>Seattle, Washington</td>
            <td align="center">87</td>
            <td align="center">1923</td>
            <td align="center">52-31-4</td>
            <td align="center">.621</td>
            <td align="center">31-13-0</td>
            <td align="center">20-18-4</td>
            <td align="center">1-0</td>
            <td>1944 Rose Bowl: USC 29-0<br/></td>
          </tr>

          <tr>
            <td>
              <a href="https://mgoblue.com/sports/football" target={"_blank"} rel="noreferrer">
                Michigan Wolverines
              </a>
            </td>
            <td>Michigan Stadium<br/>"The Big House"</td>
            <td align="center">107,601</td>
            <td>Ann Arbor, Michigan</td>
            <td align="center">10</td>
            <td align="center">1948</td>
            <td align="center">6-4</td>
            <td align="center">.600</td>
            <td align="center">0-1</td>
            <td align="center">0-1</td>
            <td align="center">6-2</td>
            <td>
              1947 Rose Bowl: Michigan 49-0<br/>
              1970 Rose Bowl: USC 10-3<br/>
              1977 Rose Bowl: USC 14-6<br/>
              1979 Rose Bowl: USC 17-10<br/>
              1989 Rose Bowl: Michigan 22-14<br/>
              1990 Rose Bowl: USC 17-10<br/>
              2004 Rose Bowl: USC 28-14<br/>
              2007 Rose Bowl: USC 32-18
            </td>
          </tr>
          
          <tr>
            <td>
              <a href="https://gopsusports.com/sports/football" target={"_blank"} rel="noreferrer">
                Penn State Nittany Lions
              </a>
            </td>
            <td>Beaver Stadium</td>
            <td align="center">106,572</td>
            <td>State College, Pennsylvania<br/>"Happy Valley"</td>
            <td align="center">10</td>
            <td align="center">1923</td>
            <td align="center">6-4</td>
            <td align="center">.600</td>
            <td align="center">2-0</td>
            <td align="center">0-2</td>
            <td align="center">3-1</td>
            <td>
              <a href="https://en.wikipedia.org/wiki/1923_Rose_Bowl" target={"_blank"} rel="noreferrer">1923 Rose Bowl: USC 14-3</a><br/>
              1982 Fiesta Bowl: Penn State 26-10<br/>
              2009 Rose Bowl: USC 38-24<br/>
              <a href="https://en.wikipedia.org/wiki/2017_Rose_Bowl" target={"_blank"} rel="noreferrer">2017 Rose Bowl: USC 52-49</a><br/>
              Note: USC is also 1-1 versus Penn State in neutral site Kickoff Classic games
            </td>
          </tr>

          <tr>
            <td>
              <a href="https://uclabruins.com/sports/football" target={"_blank"} rel="noreferrer">
                UCLA Bruins
              </a>
            </td>
            <td>Rose Bowl</td>
            <td align="center">90,888</td>
            <td>Pasadena, California</td>
            <td align="center">93</td>
            <td align="center">1888</td>
            <td align="center">52-34-7</td>
            <td align="center">.596</td>
            <td align="center">26-17-4</td>
            <td align="center">26-17-3</td>
            <td align="center"></td>
            <td></td>
          </tr>

          <tr>
            <td>
              <a href="https://ohiostatebuckeyes.com/sports/m-footbl/" target={"_blank"} rel="noreferrer">
                Ohio State Buckeyes
              </a>
            </td>
            <td>Ohio Stadium<br/>"The Horseshoe"</td>
            <td align="center">102,780</td>
            <td>Columbus, Ohio</td>
            <td align="center">24</td>
            <td align="center">1937</td>
            <td align="center">13-10-1</td>
            <td align="center">.563</td>
            <td align="center">5-2-1</td>
            <td align="center">4-4</td>
            <td align="center">4-4</td>
            <td>
              1955 Rose Bowl: Ohio State 20-7<br/>
              1969 Rose Bowl: Ohio State 27-16<br/>
              <a href="https://en.wikipedia.org/wiki/1973_Rose_Bowl" target={"_blank"} rel="noreferrer">1973 Rose Bowl: USC 42-17</a><br/>
              1974 Rose Bowl: Ohio State 42-21<br/>
              <a href="https://en.wikipedia.org/wiki/1975_Rose_Bowl" target={"_blank"} rel="noreferrer">1975 Rose Bowl: USC 18-17</a><br/>
              1980 Rose Bowl: USC 17-16<br/>
              1986 Rose Bowl: USC 20-17<br/>
              2017 Cotton Bowl: Ohio State 24-7
            </td>
          </tr>

          <tr>
            <td>
              <a href="https://msuspartans.com/sports/football" target={"_blank"} rel="noreferrer">
                Michigan State Spartans
              </a>
            </td>
            <td>Spartan Stadium</td>
            <td align="center">75,005</td>
            <td>East Lansing, Michigan</td>
            <td align="center">8</td>
            <td align="center">1963</td>
            <td align="center">4-4</td>
            <td align="center">.500</td>
            <td align="center">3-0</td>
            <td align="center">1-2</td>
            <td align="center">0-2</td>
            <td>
              1988 Rose Bowl: Michigan State 20-17<br/>
              1990 Sun Bowl: Michigan State 17-16
            </td>
          </tr>

          <tr>
            <td>
              <a href="https://umterps.com/sports/football" target={"_blank"} rel="noreferrer">
                Maryland Terrapins
              </a>
            </td>
            <td>SECU Stadium</td>
            <td align="center">51,802</td>
            <td>College Park, Maryland</td>
            <td align="center">0</td>
            <td align="center"></td>
            <td align="center"></td>
            <td align="center"></td>
            <td align="center"></td>
            <td align="center"></td>
            <td align="center"></td>
            <td></td>
          </tr>

          <tr>
            <td>
              <a href="https://scarletknights.com/sports/football" target={"_blank"} rel="noreferrer">
                Rutgers Scarlet Knights
              </a>
            </td>
            <td>SHI Stadium</td>
            <td align="center">52,454</td>
            <td>New Brunswick, New Jersey</td>
            <td align="center">0</td>
            <td align="center"></td>
            <td align="center"></td>
            <td align="center"></td>
            <td align="center"></td>
            <td align="center"></td>
            <td align="center"></td>
            <td></td>
          </tr>
        </tbody>
      </Table>
    </div>
  )
}

export default VersusUSC;