import { Table } from "react-bootstrap";
import Data from "../../Data";

function Majors() {
  const file = '2024roster';
  const sortOrder = ['major', 'lastName', 'firstName'];
  const criteria = [{prop: 'major', operator: 'exists'}];
  const players = Data(file, sortOrder, criteria);

  let majors = [];
  let majorCount = 0;
  let lastMajor = undefined;
  for (let i = 0; i < players.length ; i++) {
    if (players[i].major) {
      if (players[i].major === lastMajor) {
        majors[majorCount - 1].players.push(players[i])
      } else {
        lastMajor = players[i].major
        majorCount++;
        let isMasters = !!players[i].bachelors;
        majors.push({'major': players[i].major, players: [], isMasters});
        majors[majorCount - 1].players.push(players[i]);
      }
    }
  };
  
  return (
    <div className="App-content">
      <h3>2024 Player Majors</h3>
      <p><a href="#/roster-name">Player Names</a> | <a href="#/roster-number">Player Numbers</a> | <a href="#/roster-geographic">Player High Schools</a></p>
      <p> # Non-scholarship player</p>

      {majors.map((major, i) =>
        <div key={i}>
          <h4>{major.major} ({major.players.length})</h4>

          <Table bordered hover size="sm">
            <thead>
              <tr>
                <th width="30" className={"centeredCol"}>No</th>
                <th width="150">Name</th>
                <th width="40" className={"centeredCol"}>Pos</th>
                <th width="40" className={"centeredCol"}>Ht</th>
                <th width="40" className={"centeredCol"}>Wt</th>
                <th width="40" className={"centeredCol"}>Class</th>
                <th width="150">Hometown</th>
                <th width="200">School(s)</th>
                <th width="200">{major.isMasters ? "Bachelor's Degree" : ""}</th>
              </tr>
            </thead>
            <tbody>
              {major.players.map((player, j) =>
                <tr key={j}>
                  <td align="center">{player.jersey}</td>
                  <td>
                    {player.walkOn ? '# ' : ''}
                    <a href={"https://usctrojans.com/sports/football/roster/" + player.link} target={"_blank"} rel="noreferrer">
                      {player.firstName} {player.lastName} {player.suffix}
                    </a>
                  </td>
                  <td align="center">{player.displaypos}</td>
                  <td align="center">{player.height}</td>
                  <td align="center">{player.weight}</td>
                  <td align="center">{player.redshirt?'Rs-':''}{player.class}</td>
                  <td>{player.hometown}{player.state ? ', ' + player.state : ''}{player.country !== 'USA' ? ' ' + player.country : ''}</td>
                  <td>{player.schools}</td>
                  <td>{player.bachelors}</td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      )}
    </div>
  )
}

export default Majors;