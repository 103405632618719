import Data from '../../Data';
import Table from 'react-bootstrap/Table';
import { Container, Row, Col } from 'react-bootstrap';

function SpecialTeams() {
  const file = '2024roster';
  const sortOrder = ['depth'];
  const puntersCriteria = [{prop: 'position', value: 'P'}];
  const punters = Data(file, sortOrder, puntersCriteria);
  const kickersCriteria = [{prop: 'position', value: 'K'}];
  const kickers = Data(file, sortOrder, kickersCriteria);
  const longSnappersCriteria = [{prop: 'position', value: 'SNP'}];
  const longSnappers = Data(file, sortOrder, longSnappersCriteria);
  
  return (
    <div className="App-content">
      <h3>2024 Special Teams</h3>
      <p><a href="#/roster-offense">Offense</a> | <a href="#/roster-defense">Defense</a></p>
      <p>
        This is a <i>speculative</i> depth chart. It may not accurately reflect the views of the coaching staff.<br/>
        # Non-scholarship player
      </p>

      <h5>Coach: <a href={"https://usctrojans.com/sports/football/roster/coaches/ryan-dougherty/6626"} target={"_blank"} rel="noreferrer">Ryan Dougherty</a></h5>

      <Container fluid>
        <Row>
          <Col className={'fixed-one-third'}>
            <h4>Punters</h4>
            <Table bordered size="sm">
              <tbody>
                {punters.map((player, index) =>
                  <tr key={index} className={player.enrolled ? '' : 'not-enrolled'}>
                    <td width="30" align="center">{player.jersey}</td>
                    <td width="150" className={player.enrolled ? '' : 'hideCell'}>
                      {player.walkOn ? '# ' : ''}
                      <a href={"https://usctrojans.com/sports/football/roster/" + player.link} target={"_blank"} rel="noreferrer">
                        {player.firstName} {player.lastName} {player.suffix}
                      </a>
                    </td>
                    <td width="150" className={player.enrolled ? 'hideCell' : ''}>
                      {player.walkOn ? '# ' : ''}{player.firstName} {player.lastName} {player.suffix}
                    </td>
                    <td width="40" align="center">{player.height}</td>
                    <td width="40" align="center">{player.weight}</td>
                    <td width="40" align="center">{player.redshirt?'Rs-':''}{player.class}</td>
                    <td width="60" align="center">{player.status}</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Col>

          <Col className={'fixed-one-third'}>
            <h4>Kickers</h4>
            <Table bordered size="sm">
              <tbody>
                {kickers.map((player, index) =>
                  <tr key={index} className={player.enrolled ? '' : 'not-enrolled'}>
                    <td width="30" align="center">{player.jersey}</td>
                    <td width="150" className={player.enrolled ? '' : 'hideCell'}>
                      {player.walkOn ? '# ' : ''}
                      <a href={"https://usctrojans.com/sports/football/roster/" + player.link} target={"_blank"} rel="noreferrer">
                        {player.firstName} {player.lastName} {player.suffix}
                      </a>
                    </td>
                    <td width="150" className={player.enrolled ? 'hideCell' : ''}>
                      {player.walkOn ? '# ' : ''}{player.firstName} {player.lastName} {player.suffix}
                    </td>
                    <td width="40" align="center">{player.height}</td>
                    <td width="40" align="center">{player.weight}</td>
                    <td width="40" align="center">{player.redshirt?'Rs-':''}{player.class}</td>
                    <td width="60" align="center">{player.status}</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Col>

          <Col className={'fixed-one-third'}>
            <h4>Long Snappers</h4>
            <Table bordered size="sm">
              <tbody>
                {longSnappers.map((player, index) =>
                  <tr key={index} className={player.enrolled ? '' : 'not-enrolled'}>
                    <td width="30" align="center">{player.jersey}</td>
                    <td width="150" className={player.enrolled ? '' : 'hideCell'}>
                      {player.walkOn ? '# ' : ''}
                      <a href={"https://usctrojans.com/sports/football/roster/" + player.link} target={"_blank"} rel="noreferrer">
                        {player.firstName} {player.lastName} {player.suffix}
                      </a>
                    </td>
                    <td width="150" className={player.enrolled ? 'hideCell' : ''}>
                      {player.walkOn ? '# ' : ''}{player.firstName} {player.lastName} {player.suffix}
                    </td>
                    <td width="40" align="center">{player.height}</td>
                    <td width="40" align="center">{player.weight}</td>
                    <td width="40" align="center">{player.redshirt?'Rs-':''}{player.class}</td>
                    <td width="60" align="center">{player.status}</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default SpecialTeams;