function SeasonSelect() {
  return (
    <div>
      <p>
        <a href="#/season/2022">2022</a> | <a href="#/season/2023">2023</a> | <a href="#/season/2024">2024</a> | <a href="#/season/2025">2025</a> | <a href="#/season/2026">2026</a> | <a href="#/season/2027">2027</a> | <a href="#/season/2028">2028</a>
      </p>
    </div>
  )
}

export default SeasonSelect;