import Table from 'react-bootstrap/Table';
import SeasonSelect from './SeasonSelect';

function Season2022() {
  return (
    <div className="App-content">
      <h3>2022 Schedule</h3>

      <SeasonSelect />

      <p><i>* College Football Playoff ranking (otherwise AP)</i></p>

      <Table responsive bordered hover size="sm">
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th className={"centeredCol"}>Time</th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th className={"centeredCol"} colSpan={2}>USC</th>
            <th className={"centeredCol"} colSpan={2}>Opponent</th>
            <th className={"centeredCol"} colSpan={2}>Opp Current</th>
          </tr>
          <tr>
            <th width="50" className={"centeredCol"}>Date</th>
            <th width="125" className={"centeredCol"}>Opponent</th>
            <th width="100" className={"centeredCol"}>Conference</th>
            <th width="75" className={"centeredCol"}>(PT)</th>
            <th width="100" className={"centeredCol"}>TV</th>
            <th width="100" className={"centeredCol"}>Location</th>
            <th width="50" className={"centeredCol"}>Line</th>
            <th width="50" className={"centeredCol"}>Result</th>
            <th width="50" className={"centeredCol"}>Record</th>
            <th width="50" className={"centeredCol"}>Rank</th>
            <th width="50" className={"centeredCol"}>Record</th>
            <th width="50" className={"centeredCol"}>Rank</th>
            <th width="50" className={"centeredCol"}>Record</th>
            <th width="50" className={"centeredCol"}>Rank</th>
          </tr>
        </thead>
        <tbody>
          <tr className="monthRow">
            <td colSpan={14}>
              September
            </td>
          </tr>
          <tr>
            <td align="center">3</td>
            <td>
              <a href={"https://usctrojans.com/news/2022/9/3/usc-trojans-football-recap-rice-owls-lincoln-riley-caleb-williams-jordan-addison-pac-12.aspx"} target={"_blank"} rel="noreferrer">
                Rice
              </a>
            </td>
            <td>C-USA</td>
            <td align="center">3:00</td>
            <td align="center">P12N</td>
            <td>L.A. Coliseum</td>
            <td align="center" className={"win"}>-32.5</td>
            <td align="center" className={"win"}>66-14</td>
            <td align="center">0-0</td>
            <td align="center">14</td>
            <td align="center">0-0</td>
            <td align="center">-</td>
            <td align="center">5-8</td>
            <td align="center">-</td>
          </tr>
          <tr>
            <td align="center">10</td>
            <td>
              <a href={"https://usctrojans.com/news/2022/9/10/usc-trojans-football-game-recap-stanford-cardinal-pac-12-caleb-williams-jordan-addison-lincoln-riley.aspx"} target={"_blank"} rel="noreferrer">
                Stanford
              </a>
            </td>
            <td>Pac-12</td>
            <td align="center">4:30</td>
            <td align="center">ABC</td>
            <td>Palo Alto, CA</td>
            <td align="center" className={"win"}>-9.5</td>
            <td align="center" className={"win"}>41-28</td>
            <td align="center">1-0</td>
            <td align="center">10</td>
            <td align="center">1-0</td>
            <td align="center">-</td>
            <td align="center">3-9</td>
            <td align="center">-</td>
          </tr>
          <tr>
            <td align="center">17</td>
            <td>
              <a href={"https://usctrojans.com/news/2022/9/17/usc-trojans-football-game-recap-fresno-state-bulldogs-pac-12-lincoln-riley-austin-jones-travis-dye.aspx"} target={"_blank"} rel="noreferrer">
              Fresno State
              </a>
            </td>
            <td>Mountain West</td>
            <td align="center">7:30</td>
            <td align="center">FOX</td>
            <td>L.A. Coliseum</td>
            <td align="center" className={"win"}>-11.5</td>
            <td align="center" className={"win"}>45-17</td>
            <td align="center">2-0</td>
            <td align="center">7</td>
            <td align="center">1-1-0</td>
            <td align="center">-</td>
            <td align="center">10-4</td>
            <td align="center">-</td>
          </tr>
          <tr>
            <td align="center">24</td>
            <td>
              <a href={"https://usctrojans.com/news/2022/9/24/usc-trojans-football-game-recap-oregon-state-osu-beavers-pac-12-jordan-addison-travis-dye-eric-gentry.aspx"} target={"_blank"} rel="noreferrer">
              Oregon State
              </a>
            </td>
            <td>Pac-12</td>
            <td align="center">6:30</td>
            <td align="center">P12N</td>
            <td>L.A. Coliseum</td>
            <td align="center" className={"loss"}>-5.5</td>
            <td align="center" className={"win"}>17-14</td>
            <td align="center">3-0</td>
            <td align="center">7</td>
            <td align="center">3-0</td>
            <td align="center">32</td>
            <td align="center">10-3</td>
            <td align="center">17</td>
          </tr>
          <tr className="monthRow">
            <td colSpan={14}>
              October
            </td>
          </tr>
          <tr>
            <td align="center">1</td>
            <td>
              <a href={"https://usctrojans.com/news/2022/10/1/usc-trojans-football-game-recap-arizona-state-asu-sun-devils-pac-12-lincoln-riley-caleb-williams-travis-dye.aspx"} target={"_blank"} rel="noreferrer">
              Arizona State
              </a>
            </td>
            <td>Pac-12</td>
            <td align="center">7:30</td>
            <td align="center">ESPN</td>
            <td>L.A. Coliseum</td>
            <td align="center" className={"loss"}>-24.5</td>
            <td align="center" className={"win"}>42-25</td>
            <td align="center">4-0</td>
            <td align="center">6</td>
            <td align="center">1-3</td>
            <td align="center">-</td>
            <td align="center">3-9</td>
            <td align="center">-</td>
          </tr>
          <tr>
            <td align="center">8</td>
            <td>
              <a href={"https://usctrojans.com/news/2022/10/8/usc-trojans-football-game-recap-washington-state-wsu-cougars-pac-12-travis-dye-tuli-tuipulotu-lincoln-riley.aspx"} target={"_blank"} rel="noreferrer">
              Washington State
              </a>
            </td>
            <td>Pac-12</td>
            <td align="center">4:30</td>
            <td align="center">FOX</td>
            <td>L.A. Coliseum</td>
            <td align="center" className={"win"}>-12.5</td>
            <td align="center" className={"win"}>30-14</td>
            <td align="center">5-0</td>
            <td align="center">6</td>
            <td align="center">4-1</td>
            <td align="center">26</td>
            <td align="center">7-6</td>
            <td align="center">-</td>
          </tr>
          <tr>
            <td align="center">15</td>
            <td>
              <a href={"https://usctrojans.com/news/2022/10/15/usc-trojans-football-game-recap-utah-utes-pac-12-caleb-williams-lincoln-riley-josh-falo.aspx"} target={"_blank"} rel="noreferrer">
              Utah
              </a>
            </td>
            <td>Pac-12</td>
            <td align="center">5:00</td>
            <td align="center">FOX</td>
            <td>Salt Lake City, UT</td>
            <td align="center" className={"win"}>+3.5</td>
            <td align="center" className={"loss"}>42-43</td>
            <td align="center">6-0</td>
            <td align="center">7</td>
            <td align="center">4-2</td>
            <td align="center">20</td>
            <td align="center"d>10-4</td>
            <td align="center">10</td>
          </tr>
          <tr className="bye">
            <td align="center">22</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td align="center">6-1</td>
            <td align="center">12</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td align="center">29</td>
            <td>
              <a href={"https://usctrojans.com/news/2022/10/29/usc-trojans-football-game-recap-arizona-wildcats-pac-12-caleb-williams-tahj-washington-lincoln-riley.aspx"} target={"_blank"} rel="noreferrer">
                Arizona
              </a>
            </td>
            <td>Pac-12</td>
            <td align="center">4:00</td>
            <td align="center">P12N</td>
            <td>Tucson, AZ</td>
            <td align="center" className={"loss"}>-13.5</td>
            <td align="center" className={"win"}>45-37</td>
            <td align="center">6-1</td>
            <td align="center">10</td>
            <td align="center"td>3-4</td>
            <td align="center">-</td>
            <td align="center">5-7</td>
            <td align="center">-</td>
          </tr>
          <tr className="monthRow">
            <td colSpan={14}>
              November
            </td>
          </tr>
          <tr>
            <td align="center">5</td>
            <td>
              <a href={"https://usctrojans.com/news/2022/11/5/usc-trojans-football-game-recap-california-golden-bears-pac-12-caleb-williams-michael-jackson-iii.aspx"} target={"_blank"} rel="noreferrer">
                Cal
              </a>
            </td>
            <td>Pac-12</td>
            <td align="center">7:30</td>
            <td align="center">ESPN</td>
            <td>L.A. Coliseum</td>
            <td align="center" className={"loss"}>-21.5</td>
            <td align="center" className={"win"}>41-35</td>
            <td align="center">7-1</td>
            <td align="center">9*</td>
            <td align="center">3-5</td>
            <td align="center">-</td>
            <td align="center">4-8</td>
            <td align="center">-</td>
          </tr>
          <tr>
            <td align="center">11 Fri</td>
            <td>
              <a href={"https://usctrojans.com/news/2022/11/11/caleb-williams-leads-no-8-usc-football-to-55-17-rout-over-colorado.aspx"} target={"_blank"} rel="noreferrer">
              Colorado
              </a>
            </td>
            <td>Pac-12</td>
            <td align="center">6:30</td>
            <td align="center">FS1</td>
            <td>L.A. Coliseum</td>
            <td align="center" className={"win"}>-34.5</td>
            <td align="center" className={"win"}>55-17</td>
            <td align="center">8-1</td>
            <td align="center">8*</td>
            <td align="center">1-8</td>
            <td align="center">-</td>
            <td align="center">1-11</td>
            <td align="center">-</td>
          </tr>
          <tr>
            <td align="center">19</td>
            <td>
              <a href={"https://usctrojans.com/news/2022/11/19/usc-trojans-football-game-recap-ucla-bruins-pac-12-championship-game-caleb-williams-austin-jones.aspx"} target={"_blank"} rel="noreferrer">
              UCLA
              </a>
            </td>
            <td>Pac-12</td>
            <td align="center">5:00</td>
            <td align="center">FOX</td>
            <td>Rose Bowl</td>
            <td align="center" className={"win"}>-2.5</td>
            <td align="center" className={"win"}>48-45</td>
            <td align="center">9-1</td>
            <td align="center">7*</td>
            <td align="center">8-2</td>
            <td align="center">16*</td>
            <td align="center">9-4</td>
            <td align="center">21*</td>
          </tr>
          <tr>
            <td align="center">26</td>
            <td>
              <a href={"https://usctrojans.com/news/2022/11/26/usc-trojans-football-game-recap-notre-dame-fighting-irish-college-playoff-caleb-williams-heisman.aspx"} target={"_blank"} rel="noreferrer">
              Notre Dame
              </a>
            </td>
            <td>Independent</td>
            <td align="center">4:30</td>
            <td align="center">ABC</td>
            <td>L.A. Coliseum</td>
            <td align="center" className={"win"}>-4.5</td>
            <td align="center" className={"win"}>38-27</td>
            <td align="center">10-1</td>
            <td align="center">6*</td>
            <td align="center">8-3</td>
            <td align="center">14*</td>
            <td align="center">9-4</td>
            <td align="center">18*</td>
          </tr>
          <tr className="monthRow">
            <td colSpan={14}>
              December
            </td>
          </tr>
          <tr>
            <td align="center">2 Fri</td>
            <td colSpan={2}>
              <a href={"https://usctrojans.com/news/2022/12/2/usc-trojans-football-game-recap-utah-utes-pac-12-championship-game-caleb-williams.aspx"} target={"_blank"} rel="noreferrer">
              Utah (Pac-12 Championship)
              </a>
            </td>
            <td align="center">5:00</td>
            <td align="center">FOX</td>
            <td>Las Vegas, NV</td>
            <td align="center" className={"loss"}>-2.5</td>
            <td align="center" className={"loss"}>24-47</td>
            <td align="center">11-1</td>
            <td align="center">4*</td>
            <td align="center">9-3</td>
            <td align="center">11*</td>
            <td align="center">10-4</td>
            <td align="center">10*</td>
          </tr>
          <tr className="monthRow">
            <td colSpan={14}>
              January
            </td>
          </tr>
          <tr>
            <td align="center">2 Mon</td>
            <td colSpan={2}>
              <a href={"https://usctrojans.com/news/2023/1/2/usc-trojans-football-game-recap-tulan-green-wave-goodyear-cotton-bowl-classic-caleb-williams.aspx"} target={"_blank"} rel="noreferrer">
              Tulane (Cotton Bowl)
              </a>
            </td>
            <td align="center">10:00</td>
            <td align="center">ESPN</td>
            <td>Arlington, TX</td>
            <td align="center" className={"loss"}>-1.5</td>
            <td align="center" className={"loss"}>45-46</td>
            <td align="center">11-2</td>
            <td align="center">10*</td>
            <td align="center">11-2</td>
            <td align="center">16*</td>
            <td align="center">12-2</td>
            <td align="center">9*</td>
          </tr>
          <tr>
            <td align="center"></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td align="center">11-3</td>
            <td align="center">12</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </Table>
    </div>
  )
}

export default Season2022;