import { useEffect, useState } from 'react';

function Data(file, sortOrder, criteria) {
  const [data, setData] = useState([]);

  const getData = () => {
    fetch('./' + file + '.json'
    ,{
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Cache-Control': 'no-cache, max-age=30'
       }
    })
    .then(function(response){
      return response.json();
    })
    .then(function(myJson) {
      setData(myJson);
    }); 
  }
  useEffect(()=>{
    getData();
  },[])

  let myData = [].concat(data);
  if (criteria && criteria.length) {
    myData = [];
    data.forEach(element => {
      let match = true;
      criteria.forEach(criterion => {
        if (criterion.operator) {
          switch (criterion.operator) {
            case 'equals':
              if (element[criterion.prop] !== criterion.value) match = false;
              break;
            case 'exists':
              if (!element[criterion.prop]) match = false;
              break
            default:
              if (element[criterion.prop] !== criterion.value) match = false;
          }

        } else {
          if (element[criterion.prop] !== criterion.value) match = false;
        }
      })
      if (match) myData.push(element);
    })
  }

  let mySortedData = myData;
  if (sortOrder && sortOrder.length) {
    mySortedData = objSort(myData, ...sortOrder)
  }

  function objSort() {
    var args = arguments,
        array = args[0],
        case_sensitive, keys_length, key, desc, a, b, i;

    if (typeof arguments[arguments.length - 1] === 'boolean') {
        case_sensitive = arguments[arguments.length - 1];
        keys_length = arguments.length - 1;
    } else {
        case_sensitive = false;
        keys_length = arguments.length;
    }

    return array.sort(function (obj1, obj2) {
        for (i = 1; i < keys_length; i++) {
            key = args[i];
            if (typeof key !== 'string') {
                desc = key[1];
                key = key[0];
                a = obj1[args[i][0]];
                b = obj2[args[i][0]];
            } else {
                desc = false;
                a = obj1[args[i]];
                b = obj2[args[i]];
            }

            if (case_sensitive === false && typeof a === 'string' && typeof b === 'string') {
                if (a.length) a = a.toLowerCase();
                if (b.length) b = b.toLowerCase();
            }

            if (! desc) {
                if (a < b) return -1;
                if (a > b) return 1;
            } else {
                if (a > b) return -1;
                if (a < b) return 1;
            }
        }
        return 0;
    });
  }
  
  return mySortedData;
}

export default Data;