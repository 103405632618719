import Data from '../../Data';
import SeasonSelect from './SeasonSelect';

function Board2024() {
  const file = '2024recruits';
  const sortOrder = ['lastName'];

  const quarterbacksCriteria = [{prop: 'position', value: 'QB'},{prop: 'sign', operator: 'exists'}];
  const quarterbacks = Data(file, sortOrder, quarterbacksCriteria);
  const runningBacksCriteria = [{prop: 'position', value: 'RB'},{prop: 'sign', operator: 'exists'}];
  const runningBacks = Data(file, sortOrder, runningBacksCriteria);
  const receiversCriteria = [{prop: 'position', value: 'WR'},{prop: 'sign', operator: 'exists'}];
  const receivers = Data(file, sortOrder, receiversCriteria);
  const tightEndsCriteria = [{prop: 'position', value: 'TE'},{prop: 'sign', operator: 'exists'}];
  const tightEnds = Data(file, sortOrder, tightEndsCriteria);
  const offensiveLineCriteria = [{prop: 'position', value: 'OL'},{prop: 'sign', operator: 'exists'}];
  const offensiveLine = Data(file, sortOrder, offensiveLineCriteria);

  const portalQuarterbacksCriteria = [{prop: 'position', value: 'QB'},{prop: 'sign', operator: '!exists'}];
  const portalQuarterbacks = Data(file, sortOrder, portalQuarterbacksCriteria);
  const portalRunningBacksCriteria = [{prop: 'position', value: 'RB'},{prop: 'sign', operator: '!exists'}];
  const portalRunningBacks = Data(file, sortOrder, portalRunningBacksCriteria);
  const portalReceiversCriteria = [{prop: 'position', value: 'WR'},{prop: 'sign', operator: '!exists'}];
  const portalReceivers = Data(file, sortOrder, portalReceiversCriteria);
  const portalTightEndsCriteria = [{prop: 'position', value: 'TE'},{prop: 'sign', operator: '!exists'}];
  const portalTightEnds = Data(file, sortOrder, portalTightEndsCriteria);
  const portalOffensiveLineCriteria = [{prop: 'position', value: 'OL'},{prop: 'sign', operator: '!exists'}];
  const portalOffensiveLine = Data(file, sortOrder, portalOffensiveLineCriteria);
  
  const defensiveLineCriteria = [{prop: 'position', value: 'DL'},{prop: 'sign', operator: 'exists'}];
  const defensiveLine = Data(file, sortOrder, defensiveLineCriteria);
  const linebackersCriteria = [{prop: 'position', value: 'LB'},{prop: 'sign', operator: 'exists'}];
  const linebackers = Data(file, sortOrder, linebackersCriteria);
  const edgeCriteria = [{prop: 'position', value: 'DE'},{prop: 'sign', operator: 'exists'}];
  const edge = Data(file, sortOrder, edgeCriteria);
  const safetiesCriteria = [{prop: 'position', value: 'S'},{prop: 'sign', operator: 'exists'}];
  const safeties = Data(file, sortOrder, safetiesCriteria);
  const cornerbacksCriteria = [{prop: 'position', value: 'CB'},{prop: 'sign', operator: 'exists'}];
  const cornerbacks = Data(file, sortOrder, cornerbacksCriteria);

  const portalDefensiveLineCriteria = [{prop: 'position', value: 'DL'},{prop: 'sign', operator: '!exists'}];
  const portalDefensiveLine = Data(file, sortOrder, portalDefensiveLineCriteria);
  const portalLinebackersCriteria = [{prop: 'position', value: 'LB'},{prop: 'sign', operator: '!exists'}];
  const portalLinebackers = Data(file, sortOrder, portalLinebackersCriteria);
  const portalEdgeCriteria = [{prop: 'position', value: 'DE'},{prop: 'sign', operator: '!exists'}];
  const portalEdge = Data(file, sortOrder, portalEdgeCriteria);
  const portalSafetiesCriteria = [{prop: 'position', value: 'S'},{prop: 'sign', operator: '!exists'}];
  const portalSafeties = Data(file, sortOrder, portalSafetiesCriteria);
  const portalCornerbacksCriteria = [{prop: 'position', value: 'CB'},{prop: 'sign', operator: '!exists'}];
  const portalCornerbacks = Data(file, sortOrder, portalCornerbacksCriteria);

  const longSnappersCriteria = [{prop: 'position', value: 'LS'},{prop: 'sign', operator: 'exists'}];
  const longSnappers = Data(file, sortOrder, longSnappersCriteria);
  const kickersCriteria = [{prop: 'position', value: 'K'},{prop: 'sign', operator: 'exists'}];
  const kickers = Data(file, sortOrder, kickersCriteria);
  const puntersCriteria = [{prop: 'position', value: 'P'},{prop: 'sign', operator: 'exists'}];
  const punters = Data(file, sortOrder, puntersCriteria);

  const portalLongSnappersCriteria = [{prop: 'position', value: 'LS'},{prop: 'sign', operator: '!exists'}];
  const portalLongSnappers = Data(file, sortOrder, portalLongSnappersCriteria);
  const portalKickersCriteria = [{prop: 'position', value: 'K'},{prop: 'sign', operator: '!exists'}];
  const portalKickers = Data(file, sortOrder, portalKickersCriteria);
  const portalPuntersCriteria = [{prop: 'position', value: 'P'},{prop: 'sign', operator: '!exists'}];
  const portalPunters = Data(file, sortOrder, portalPuntersCriteria);

  return (
    <div className="App-content">
      <h3>2024 Recruiting Board</h3>

      <SeasonSelect />

      <p><i>This information is culled from numerous sources (none of which are directly from the coaching staff) and is for 
      entertainment purposes only. Dates indicate weekend of official visits.</i></p>
      
      <h4>Offense</h4>

      <h5>QUARTERBACKS</h5>
      
      {quarterbacks.map((recruit, index) =>
        <div key={index}>
          <span className={recruit.commit === 'USC' ? 'committed' : ''}>
            <b>{recruit.firstName} {recruit.lastName} {recruit.suffix ? recruit.suffix + ' ' : ' '}</b>
          </span>
          {recruit.height} {recruit.weight} {recruit.hometown}, {recruit.state} {recruit.school}
          {recruit.college ? ' / ' + recruit.college + ' (eligibility: ' + recruit.eligible + ' year' : ''}{recruit.college && recruit.eligible > 1 ? 's' : ''}{recruit.college ? ') ' : ' '}
          {recruit.visits ? ' - ' + recruit.visits : ''}
          <br/>
        </div>
      )}

      <br/>
    
      {portalQuarterbacks.map((recruit, index) =>
        <div key={index}>
          <span className={recruit.commit === 'USC' ? 'committed' : ''}>
            <b>{recruit.firstName} {recruit.lastName} {recruit.suffix ? recruit.suffix + ' ' : ' '}</b>
          </span>
          {recruit.height} {recruit.weight} {recruit.hometown}, {recruit.state} {recruit.school}
          {recruit.college ? ' / ' + recruit.college + ' (eligibility: ' + recruit.eligible + ' year' : ''}{recruit.college && recruit.eligible > 1 ? 's' : ''}{recruit.college ? ') ' : ' '}
          {recruit.visits ? ' - ' + recruit.visits : ''}
          <br/>
        </div>
      )}
  

      <br/>
      <h5>RUNNING BACKS</h5>

      {runningBacks.map((recruit, index) =>
        <div key={index}>
          <span className={recruit.commit === 'USC' ? 'committed' : ''}>
            <b>{recruit.firstName} {recruit.lastName} {recruit.suffix ? recruit.suffix + ' ' : ' '}</b>
          </span>
          {recruit.height} {recruit.weight} {recruit.hometown}, {recruit.state} {recruit.school}
          {recruit.college ? ' / ' + recruit.college + ' (eligibility: ' + recruit.eligible + ' year' : ''}{recruit.college && recruit.eligible > 1 ? 's' : ''}{recruit.college ? ') ' : ' '}
          {recruit.visits ? ' - ' + recruit.visits : ''}
          <br/>
        </div>
      )}

      <br/>
      
      {portalRunningBacks.map((recruit, index) =>
        <div key={index}>
          <span className={recruit.commit === 'USC' ? 'committed' : ''}>
            <b>{recruit.firstName} {recruit.lastName} {recruit.suffix ? recruit.suffix + ' ' : ' '}</b>
          </span>
          {recruit.height} {recruit.weight} {recruit.hometown}, {recruit.state} {recruit.school}
          {recruit.college ? ' / ' + recruit.college + ' (eligibility: ' + recruit.eligible + ' year' : ''}{recruit.college && recruit.eligible > 1 ? 's' : ''}{recruit.college ? ') ' : ' '}
          {recruit.visits ? ' - ' + recruit.visits : ''}
          <br/>
        </div>
      )}


      <br/>
      <h5>RECEIVERS</h5>

      {receivers.map((recruit, index) =>
        <div key={index}>
          <span className={recruit.commit === 'USC' ? 'committed' : ''}>
            <b>{recruit.firstName} {recruit.lastName} {recruit.suffix ? recruit.suffix + ' ' : ' '}</b>
          </span>
          {recruit.height} {recruit.weight} {recruit.hometown}, {recruit.state} {recruit.school}
          {recruit.college ? ' / ' + recruit.college + ' (eligibility: ' + recruit.eligible + ' year' : ''}{recruit.college && recruit.eligible > 1 ? 's' : ''}{recruit.college ? ') ' : ' '}
          {recruit.visits ? ' - ' + recruit.visits : ''}
          <br/>
        </div>
      )}

      <br/>
      
      {portalReceivers.map((recruit, index) =>
        <div key={index}>
          <span className={recruit.commit === 'USC' ? 'committed' : ''}>
            <b>{recruit.firstName} {recruit.lastName} {recruit.suffix ? recruit.suffix + ' ' : ' '}</b>
          </span>
          {recruit.height} {recruit.weight} {recruit.hometown}, {recruit.state} {recruit.school}
          {recruit.college ? ' / ' + recruit.college + ' (eligibility: ' + recruit.eligible + ' year' : ''}{recruit.college && recruit.eligible > 1 ? 's' : ''}{recruit.college ? ') ' : ' '}
          {recruit.visits ? ' - ' + recruit.visits : ''}
          <br/>
        </div>
      )}

      <br/>
      <h5>TIGHT ENDS</h5>

      {tightEnds.map((recruit, index) =>
        <div key={index}>
          <span className={recruit.commit === 'USC' ? 'committed' : ''}>
            <b>{recruit.firstName} {recruit.lastName} {recruit.suffix ? recruit.suffix + ' ' : ' '}</b>
          </span>
          {recruit.height} {recruit.weight} {recruit.hometown}, {recruit.state} {recruit.school}
          {recruit.college ? ' / ' + recruit.college + ' (eligibility: ' + recruit.eligible + ' year' : ''}{recruit.college && recruit.eligible > 1 ? 's' : ''}{recruit.college ? ') ' : ' '}
          {recruit.visits ? ' - ' + recruit.visits : ''}
          <br/>
        </div>
      )}

      <br/>

      {portalTightEnds.map((recruit, index) =>
        <div key={index}>
          <span className={recruit.commit === 'USC' ? 'committed' : ''}>
            <b>{recruit.firstName} {recruit.lastName} {recruit.suffix ? recruit.suffix + ' ' : ' '}</b>
          </span>
          {recruit.height} {recruit.weight} {recruit.hometown}, {recruit.state} {recruit.school}
          {recruit.college ? ' / ' + recruit.college + ' (eligibility: ' + recruit.eligible + ' year' : ''}{recruit.college && recruit.eligible > 1 ? 's' : ''}{recruit.college ? ') ' : ' '}
          {recruit.visits ? ' - ' + recruit.visits : ''}
          <br/>
        </div>
      )}

      <br/>
      <h5>OFFENSIVE LINEMEN</h5>

      {offensiveLine.map((recruit, index) =>
        <div key={index}>
          <span className={recruit.commit === 'USC' ? 'committed' : ''}>
            <b>{recruit.firstName} {recruit.lastName} {recruit.suffix ? recruit.suffix + ' ' : ' '}</b>
          </span>
          {recruit.height} {recruit.weight} {recruit.hometown}, {recruit.state} {recruit.school}
          {recruit.college ? ' / ' + recruit.college + ' (eligibility: ' + recruit.eligible + ' year' : ''}{recruit.college && recruit.eligible > 1 ? 's' : ''}{recruit.college ? ') ' : ' '}
          {recruit.visits ? ' - ' + recruit.visits : ''}
          <br/>
        </div>
      )}

      <br/>
      {portalOffensiveLine.map((recruit, index) =>
        <div key={index}>
          <span className={recruit.commit === 'USC' ? 'committed' : ''}>
            <b>{recruit.firstName} {recruit.lastName} {recruit.suffix ? recruit.suffix + ' ' : ' '}</b>
          </span>
          {recruit.height} {recruit.weight} {recruit.hometown}, {recruit.state} {recruit.school}
          {recruit.college ? ' / ' + recruit.college + ' (eligibility: ' + recruit.eligible + ' year' : ''}{recruit.college && recruit.eligible > 1 ? 's' : ''}{recruit.college ? ') ' : ' '}
          {recruit.visits ? ' - ' + recruit.visits : ''}
          <br/>
        </div>
      )}

      <br/>
      <h4>Defense</h4>
      
      <h5>DEFENSIVE LINEMEN</h5>

      {defensiveLine.map((recruit, index) =>
        <div key={index}>
          <span className={recruit.commit === 'USC' ? 'committed' : ''}>
            <b>{recruit.firstName} {recruit.lastName} {recruit.suffix ? recruit.suffix + ' ' : ' '}</b>
          </span>
          {recruit.height} {recruit.weight} {recruit.hometown}, {recruit.state} {recruit.school}
          {recruit.college ? ' / ' + recruit.college + ' (eligibility: ' + recruit.eligible + ' year' : ''}{recruit.college && recruit.eligible > 1 ? 's' : ''}{recruit.college ? ') ' : ' '}
          {recruit.visits ? ' - ' + recruit.visits : ''}
          <br/>
        </div>
      )}

      <br/>
      {portalDefensiveLine.map((recruit, index) =>
        <div key={index}>
          <span className={recruit.commit === 'USC' ? 'committed' : ''}>
            <b>{recruit.firstName} {recruit.lastName} {recruit.suffix ? recruit.suffix + ' ' : ' '}</b>
          </span>
          {recruit.height} {recruit.weight} {recruit.hometown}, {recruit.state} {recruit.school}
          {recruit.college ? ' / ' + recruit.college + ' (eligibility: ' + recruit.eligible + ' year' : ''}{recruit.college && recruit.eligible > 1 ? 's' : ''}{recruit.college ? ') ' : ' '}
          {recruit.visits ? ' - ' + recruit.visits : ''}
          <br/>
        </div>
      )}

      <br/>
      <h5>DEFENSIVE ENDS</h5>

      {edge.map((recruit, index) =>
        <div key={index}>
          <span className={recruit.commit === 'USC' ? 'committed' : ''}>
            <b>{recruit.firstName} {recruit.lastName} {recruit.suffix ? recruit.suffix + ' ' : ' '}</b>
          </span>
          {recruit.height} {recruit.weight} {recruit.hometown}, {recruit.state} {recruit.school}
          {recruit.college ? ' / ' + recruit.college + ' (eligibility: ' + recruit.eligible + ' year' : ''}{recruit.college && recruit.eligible > 1 ? 's' : ''}{recruit.college ? ') ' : ' '}
          {recruit.visits ? ' - ' + recruit.visits : ''}
          <br/>
        </div>
      )}

      <br/>
      
      {portalEdge.map((recruit, index) =>
        <div key={index}>
          <span className={recruit.commit === 'USC' ? 'committed' : ''}>
            <b>{recruit.firstName} {recruit.lastName} {recruit.suffix ? recruit.suffix + ' ' : ' '}</b>
          </span>
          {recruit.height} {recruit.weight} {recruit.hometown}, {recruit.state} {recruit.school}
          {recruit.college ? ' / ' + recruit.college + ' (eligibility: ' + recruit.eligible + ' year' : ''}{recruit.college && recruit.eligible > 1 ? 's' : ''}{recruit.college ? ') ' : ' '}
          {recruit.visits ? ' - ' + recruit.visits : ''}
          <br/>
        </div>
      )}
  
      <br/>
      <h5>LINEBACKERS</h5>

      {linebackers.map((recruit, index) =>
        <div key={index}>
          <span className={recruit.commit === 'USC' ? 'committed' : ''}>
            <b>{recruit.firstName} {recruit.lastName} {recruit.suffix ? recruit.suffix + ' ' : ' '}</b>
          </span>
          {recruit.height} {recruit.weight} {recruit.hometown}, {recruit.state} {recruit.school}
          {recruit.college ? ' / ' + recruit.college + ' (eligibility: ' + recruit.eligible + ' year' : ''}{recruit.college && recruit.eligible > 1 ? 's' : ''}{recruit.college ? ') ' : ' '}
          {recruit.visits ? ' - ' + recruit.visits : ''}
          <br/>
        </div>
      )}

      <br/>
      
      {portalLinebackers.map((recruit, index) =>
        <div key={index}>
          <span className={recruit.commit === 'USC' ? 'committed' : ''}>
            <b>{recruit.firstName} {recruit.lastName} {recruit.suffix ? recruit.suffix + ' ' : ' '}</b>
          </span>
          {recruit.height} {recruit.weight} {recruit.hometown}, {recruit.state} {recruit.school}
          {recruit.college ? ' / ' + recruit.college + ' (eligibility: ' + recruit.eligible + ' year' : ''}{recruit.college && recruit.eligible > 1 ? 's' : ''}{recruit.college ? ') ' : ' '}
          {recruit.visits ? ' - ' + recruit.visits : ''}
          <br/>
        </div>
      )}

      <br/>
      <h5>SAFETIES</h5>

      {safeties.map((recruit, index) =>
        <div key={index}>
          <span className={recruit.commit === 'USC' ? 'committed' : ''}>
            <b>{recruit.firstName} {recruit.lastName} {recruit.suffix ? recruit.suffix + ' ' : ' '}</b>
          </span>
          {recruit.height} {recruit.weight} {recruit.hometown}, {recruit.state} {recruit.school}
          {recruit.college ? ' / ' + recruit.college + ' (eligibility: ' + recruit.eligible + ' year' : ''}{recruit.college && recruit.eligible > 1 ? 's' : ''}{recruit.college ? ') ' : ' '}
          {recruit.visits ? ' - ' + recruit.visits : ''}
          <br/>
        </div>
      )}

      <br/>
      
      {portalSafeties.map((recruit, index) =>
        <div key={index}>
          <span className={recruit.commit === 'USC' ? 'committed' : ''}>
            <b>{recruit.firstName} {recruit.lastName} {recruit.suffix ? recruit.suffix + ' ' : ' '}</b>
          </span>
          {recruit.height} {recruit.weight} {recruit.hometown}, {recruit.state} {recruit.school}
          {recruit.college ? ' / ' + recruit.college + ' (eligibility: ' + recruit.eligible + ' year' : ''}{recruit.college && recruit.eligible > 1 ? 's' : ''}{recruit.college ? ') ' : ' '}
          {recruit.visits ? ' - ' + recruit.visits : ''}
          <br/>
        </div>
      )}

      <br/>
      <h5>CORNERBACKS</h5>

      {cornerbacks.map((recruit, index) =>
        <div key={index}>
          <span className={recruit.commit === 'USC' ? 'committed' : ''}>
            <b>{recruit.firstName} {recruit.lastName} {recruit.suffix ? recruit.suffix + ' ' : ' '}</b>
          </span>
          {recruit.height} {recruit.weight} {recruit.hometown}, {recruit.state} {recruit.school}
          {recruit.college ? ' / ' + recruit.college + ' (eligibility: ' + recruit.eligible + ' year' : ''}{recruit.college && recruit.eligible > 1 ? 's' : ''}{recruit.college ? ') ' : ' '}
          {recruit.visits ? ' - ' + recruit.visits : ''}
          <br/>
        </div>
      )}

      <br/>
      
      {portalCornerbacks.map((recruit, index) =>
        <div key={index}>
          <span className={recruit.commit === 'USC' ? 'committed' : ''}>
            <b>{recruit.firstName} {recruit.lastName} {recruit.suffix ? recruit.suffix + ' ' : ' '}</b>
          </span>
          {recruit.height} {recruit.weight} {recruit.hometown}, {recruit.state} {recruit.school}
          {recruit.college ? ' / ' + recruit.college + ' (eligibility: ' + recruit.eligible + ' year' : ''}{recruit.college && recruit.eligible > 1 ? 's' : ''}{recruit.college ? ') ' : ' '}
          {recruit.visits ? ' - ' + recruit.visits : ''}
          <br/>
        </div>
      )}

      <br/>
      <h4>Special Teams</h4>

      <h5>PUNTERS</h5>

      {punters.map((recruit, index) =>
        <div key={index}>
          <span className={recruit.commit === 'USC' ? 'committed' : ''}>
            <b>{recruit.firstName} {recruit.lastName} {recruit.suffix ? recruit.suffix + ' ' : ' '}</b>
          </span>
          {recruit.height} {recruit.weight} {recruit.hometown}, {recruit.state} {recruit.school}
          {recruit.college ? ' / ' + recruit.college + ' (eligibility: ' + recruit.eligible + ' year' : ''}{recruit.college && recruit.eligible > 1 ? 's' : ''}{recruit.college ? ') ' : ' '}
          {recruit.visits ? ' - ' + recruit.visits : ''}
          <br/>
        </div>
      )}

      <br/>

      {portalPunters.map((recruit, index) =>
        <div key={index}>
          <span className={recruit.commit === 'USC' ? 'committed' : ''}>
            <b>{recruit.firstName} {recruit.lastName} {recruit.suffix ? recruit.suffix + ' ' : ' '}</b>
          </span>
          {recruit.height} {recruit.weight} {recruit.hometown}, {recruit.state} {recruit.school}
          {recruit.college ? ' / ' + recruit.college + ' (eligibility: ' + recruit.eligible + ' year' : ''}{recruit.college && recruit.eligible > 1 ? 's' : ''}{recruit.college ? ') ' : ' '}
          {recruit.visits ? ' - ' + recruit.visits : ''}
          <br/>
        </div>
      )}
      
      <br/>
      <h5>KICKERS</h5>

      {kickers.map((recruit, index) =>
        <div key={index}>
          <span className={recruit.commit === 'USC' ? 'committed' : ''}>
            <b>{recruit.firstName} {recruit.lastName} {recruit.suffix ? recruit.suffix + ' ' : ' '}</b>
          </span>
          {recruit.height} {recruit.weight} {recruit.hometown}, {recruit.state} {recruit.school}
          {recruit.college ? ' / ' + recruit.college + ' (eligibility: ' + recruit.eligible + ' year' : ''}{recruit.college && recruit.eligible > 1 ? 's' : ''}{recruit.college ? ') ' : ' '}
          {recruit.visits ? ' - ' + recruit.visits : ''}
          <br/>
        </div>
      )}

      <br/>
      {portalKickers.map((recruit, index) =>
        <div key={index}>
          <span className={recruit.commit === 'USC' ? 'committed' : ''}>
            <b>{recruit.firstName} {recruit.lastName} {recruit.suffix ? recruit.suffix + ' ' : ' '}</b>
          </span>
          {recruit.height} {recruit.weight} {recruit.hometown}, {recruit.state} {recruit.school}
          {recruit.college ? ' / ' + recruit.college + ' (eligibility: ' + recruit.eligible + ' year' : ''}{recruit.college && recruit.eligible > 1 ? 's' : ''}{recruit.college ? ') ' : ' '}
          {recruit.visits ? ' - ' + recruit.visits : ''}
          <br/>
        </div>
      )}
      
      <br/>
      <h5>LONG SNAPPERS</h5>

      {longSnappers.map((recruit, index) =>
        <div key={index}>
          <span className={recruit.commit === 'USC' ? 'committed' : ''}>
            <b>{recruit.firstName} {recruit.lastName} {recruit.suffix ? recruit.suffix + ' ' : ' '}</b>
          </span>
          {recruit.height} {recruit.weight} {recruit.hometown}, {recruit.state} {recruit.school}
          {recruit.college ? ' / ' + recruit.college + ' (eligibility: ' + recruit.eligible + ' year' : ''}{recruit.college && recruit.eligible > 1 ? 's' : ''}{recruit.college ? ') ' : ' '}
          {recruit.visits ? ' - ' + recruit.visits : ''}
          <br/>
        </div>
      )}

      <br/>
      
      {portalLongSnappers.map((recruit, index) =>
        <div key={index}>
          <span className={recruit.commit === 'USC' ? 'committed' : ''}>
            <b>{recruit.firstName} {recruit.lastName} {recruit.suffix ? recruit.suffix + ' ' : ' '}</b>
          </span>
          {recruit.height} {recruit.weight} {recruit.hometown}, {recruit.state} {recruit.school}
          {recruit.college ? ' / ' + recruit.college + ' (eligibility: ' + recruit.eligible + ' year' : ''}{recruit.college && recruit.eligible > 1 ? 's' : ''}{recruit.college ? ') ' : ' '}
          {recruit.visits ? ' - ' + recruit.visits : ''}
          <br/>
        </div>
      )}

    </div>
  )
}

export default Board2024;