import Data from '../../Data';
import Table from 'react-bootstrap/Table';
import { Container, Row, Col } from 'react-bootstrap';

function Defense() {
  const file = '2024roster';
  const sortOrder = ['depth'];
  const noseTacklesCriteria = [{prop: 'position', value: 'NT'}];
  const noseTackles = Data(file, sortOrder, noseTacklesCriteria);
  const defensiveTacklesCriteria = [{prop: 'position', value: 'DT'}];
  const defensiveTackles = Data(file, sortOrder, defensiveTacklesCriteria);
  const defensiveEndsCriteria = [{prop: 'position', value: 'DE'}];
  const defensiveEnds = Data(file, sortOrder, defensiveEndsCriteria);
  const rushEndsCriteria = [{prop: 'position', value: 'RE'}];
  const rushEnds = Data(file, sortOrder, rushEndsCriteria);
  const mikeLinebackersCriteria = [{prop: 'position', value: 'MIKE'}];
  const mikeLinebackers = Data(file, sortOrder, mikeLinebackersCriteria);
  const willLinebackersCriteria = [{prop: 'position', value: 'WILL'}];
  const willLinebackers = Data(file, sortOrder, willLinebackersCriteria);
  const nickelSafetiesCriteria = [{prop: 'position', value: 'NS'}];
  const nickelSafeties = Data(file, sortOrder, nickelSafetiesCriteria);
  const strongSafetiesCriteria = [{prop: 'position', value: 'SS'}];
  const strongSafeties = Data(file, sortOrder, strongSafetiesCriteria);
  const freeSafetiesCriteria = [{prop: 'position', value: 'FS'}];
  const freeSafeties = Data(file, sortOrder, freeSafetiesCriteria);
  const fieldCornerbacksCriteria = [{prop: 'position', value: 'FC'}];
  const fieldCornerbacks = Data(file, sortOrder, fieldCornerbacksCriteria);
  const borderCornerbacksCriteria = [{prop: 'position', value: 'BC'}];
  const borderCornerbacks = Data(file, sortOrder, borderCornerbacksCriteria);
  
  return (
    <div className="App-content">
      <h3>2024 Defense</h3>
      <p><a href="#/roster-offense">Offense</a> | <a href="#/roster-special-teams">Special Teams</a></p>
      <p>
        This is a <i>speculative</i> depth chart. It may not accurately reflect the views of the coaching staff.<br/>
        # Non-scholarship player
      </p>

      <Container fluid>
        <Row>
          <Col className={'fixed-one-third'}>
            <h4>Defensive Linemen</h4>
            <h5>Coach: <a href={"https://usctrojans.com/sports/football/roster/coaches/eric-henderson/6903"} target={"_blank"} rel="noreferrer">Eric Henderson</a></h5>
            
            <h5>Defensive Tackle</h5>
            <Table bordered size="sm">
              <tbody>
                {noseTackles.map((player, index) =>
                  <tr key={index} className={player.enrolled ? '' : 'not-enrolled'}>
                    <td width="30" align="center">{player.jersey}</td>
                    <td width="150" className={player.enrolled ? '' : 'hideCell'}>
                      {player.walkOn ? '# ' : ''}
                      <a href={"https://usctrojans.com/sports/football/roster/" + player.link} target={"_blank"} rel="noreferrer">
                        {player.firstName} {player.lastName} {player.suffix}
                      </a>
                    </td>
                    <td width="150" className={player.enrolled ? 'hideCell' : ''}>
                      {player.walkOn ? '# ' : ''}{player.firstName} {player.lastName} {player.suffix}
                    </td>
                    <td width="40" align="center">{player.height}</td>
                    <td width="40" align="center">{player.weight}</td>
                    <td width="40" align="center">{player.redshirt?'Rs-':''}{player.class}</td>
                    <td width="60" align="center">{player.status}</td>
                  </tr>
                )}
              </tbody>
            </Table>

            <h5>Defensive Tackle</h5>
            <Table bordered size="sm">
              <tbody>
                {defensiveTackles.map((player, index) =>
                  <tr key={index} className={player.enrolled ? '' : 'not-enrolled'}>
                    <td width="30" align="center">{player.jersey}</td>
                    <td width="150" className={player.enrolled ? '' : 'hideCell'}>
                      {player.walkOn ? '# ' : ''}
                      <a href={"https://usctrojans.com/sports/football/roster/" + player.link} target={"_blank"} rel="noreferrer">
                        {player.firstName} {player.lastName} {player.suffix}
                      </a>
                    </td>
                    <td width="150" className={player.enrolled ? 'hideCell' : ''}>
                      {player.walkOn ? '# ' : ''}{player.firstName} {player.lastName} {player.suffix}
                    </td>
                    <td width="40" align="center">{player.height}</td>
                    <td width="40" align="center">{player.weight}</td>
                    <td width="40" align="center">{player.redshirt?'Rs-':''}{player.class}</td>
                    <td width="60" align="center">{player.status}</td>
                  </tr>
                )}
              </tbody>
            </Table>

            <h4>Defensive Ends</h4>
            <h5>Coach: <a href={"https://usctrojans.com/sports/football/roster/coaches/shaun-nua/6617"} target={"_blank"} rel="noreferrer">Shaun Nua</a></h5>

            <h5>Defensive End</h5>
            <Table bordered size="sm">
              <tbody>
                {defensiveEnds.map((player, index) =>
                  <tr key={index} className={player.enrolled ? '' : 'not-enrolled'}>
                    <td width="30" align="center">{player.jersey}</td>
                    <td width="150" className={player.enrolled ? '' : 'hideCell'}>
                      {player.walkOn ? '# ' : ''}
                      <a href={"https://usctrojans.com/sports/football/roster/" + player.link} target={"_blank"} rel="noreferrer">
                        {player.firstName} {player.lastName} {player.suffix}
                      </a>
                    </td>
                    <td width="150" className={player.enrolled ? 'hideCell' : ''}>
                      {player.walkOn ? '# ' : ''}{player.firstName} {player.lastName} {player.suffix}
                    </td>
                    <td width="40" align="center">{player.height}</td>
                    <td width="40" align="center">{player.weight}</td>
                    <td width="40" align="center">{player.redshirt?'Rs-':''}{player.class}</td>
                    <td width="60" align="center">{player.status}</td>
                  </tr>
                )}
              </tbody>
            </Table>

            <h5>Defensive End</h5>
            <Table bordered size="sm">
              <tbody>
                {rushEnds.map((player, index) =>
                  <tr key={index} className={player.enrolled ? '' : 'not-enrolled'}>
                    <td width="30" align="center">{player.jersey}</td>
                    <td width="150" className={player.enrolled ? '' : 'hideCell'}>
                      {player.walkOn ? '# ' : ''}
                      <a href={"https://usctrojans.com/sports/football/roster/" + player.link} target={"_blank"} rel="noreferrer">
                        {player.firstName} {player.lastName} {player.suffix}
                      </a>
                    </td>
                    <td width="150" className={player.enrolled ? 'hideCell' : ''}>
                      {player.walkOn ? '# ' : ''}{player.firstName} {player.lastName} {player.suffix}
                    </td>
                    <td width="40" align="center">{player.height}</td>
                    <td width="40" align="center">{player.weight}</td>
                    <td width="40" align="center">{player.redshirt?'Rs-':''}{player.class}</td>
                    <td width="60" align="center">{player.status}</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Col>

          <Col className={'fixed-one-third'}>
            <h4>Linebackers</h4>
            <h5>Coach: <a href={"https://usctrojans.com/sports/football/roster/coaches/matt-entz/6902"} target={"_blank"} rel="noreferrer">Matt Entz</a></h5>

            <h5>Mike Linebacker</h5>
            <Table bordered size="sm">
              <tbody>
                {mikeLinebackers.map((player, index) =>
                  <tr key={index} className={player.enrolled ? '' : 'not-enrolled'}>
                    <td width="30" align="center">{player.jersey}</td>
                    <td width="150" className={player.enrolled ? '' : 'hideCell'}>
                      {player.walkOn ? '# ' : ''}
                      <a href={"https://usctrojans.com/sports/football/roster/" + player.link} target={"_blank"} rel="noreferrer">
                        {player.firstName} {player.lastName} {player.suffix}
                      </a>
                    </td>
                    <td width="150" className={player.enrolled ? 'hideCell' : ''}>
                      {player.walkOn ? '# ' : ''}{player.firstName} {player.lastName} {player.suffix}
                    </td>
                    <td width="40" align="center">{player.height}</td>
                    <td width="40" align="center">{player.weight}</td>
                    <td width="40" align="center">{player.redshirt?'Rs-':''}{player.class}</td>
                    <td width="60" align="center">{player.status}</td>
                  </tr>
                )}
              </tbody>
            </Table>

            <h5>Will Linebacker</h5>
            <Table bordered size="sm">
              <tbody>
                {willLinebackers.map((player, index) =>
                  <tr key={index} className={player.enrolled ? '' : 'not-enrolled'}>
                    <td width="30" align="center">{player.jersey}</td>
                    <td width="150" className={player.enrolled ? '' : 'hideCell'}>
                      {player.walkOn ? '# ' : ''}
                      <a href={"https://usctrojans.com/sports/football/roster/" + player.link} target={"_blank"} rel="noreferrer">
                        {player.firstName} {player.lastName} {player.suffix}
                      </a>
                    </td>
                    <td width="150" className={player.enrolled ? 'hideCell' : ''}>
                      {player.walkOn ? '# ' : ''}{player.firstName} {player.lastName} {player.suffix}
                    </td>
                    <td width="40" align="center">{player.height}</td>
                    <td width="40" align="center">{player.weight}</td>
                    <td width="40" align="center">{player.redshirt?'Rs-':''}{player.class}</td>
                    <td width="60" align="center">{player.status}</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Col>

          <Col className={'fixed-one-third'}>
            <h4>Defensive Backs</h4>
            <h5>Coach: <a href={"https://usctrojans.com/sports/football/roster/coaches/doug-belk/6901"} target={"_blank"} rel="noreferrer">Doug Belk</a></h5>

            <h5>Safety</h5>
            <Table bordered size="sm">
              <tbody>
                {strongSafeties.map((player, index) =>
                  <tr key={index} className={player.enrolled ? '' : 'not-enrolled'}>
                    <td width="30" align="center">{player.jersey}</td>
                    <td width="150" className={player.enrolled ? '' : 'hideCell'}>
                      {player.walkOn ? '# ' : ''}
                      <a href={"https://usctrojans.com/sports/football/roster/" + player.link} target={"_blank"} rel="noreferrer">
                        {player.firstName} {player.lastName} {player.suffix}
                      </a>
                    </td>
                    <td width="150" className={player.enrolled ? 'hideCell' : ''}>
                      {player.walkOn ? '# ' : ''}{player.firstName} {player.lastName} {player.suffix}
                    </td>
                    <td width="40" align="center">{player.height}</td>
                    <td width="40" align="center">{player.weight}</td>
                    <td width="40" align="center">{player.redshirt?'Rs-':''}{player.class}</td>
                    <td width="60" align="center">{player.status}</td>
                  </tr>
                )}
              </tbody>
            </Table>

            <h5>Safety</h5>
            <Table bordered size="sm">
              <tbody>
                {freeSafeties.map((player, index) =>
                  <tr key={index} className={player.enrolled ? '' : 'not-enrolled'}>
                    <td width="30" align="center">{player.jersey}</td>
                    <td width="150" className={player.enrolled ? '' : 'hideCell'}>
                      {player.walkOn ? '# ' : ''}
                      <a href={"https://usctrojans.com/sports/football/roster/" + player.link} target={"_blank"} rel="noreferrer">
                        {player.firstName} {player.lastName} {player.suffix}
                      </a>
                    </td>
                    <td width="150" className={player.enrolled ? 'hideCell' : ''}>
                      {player.walkOn ? '# ' : ''}{player.firstName} {player.lastName} {player.suffix}
                    </td>
                    <td width="40" align="center">{player.height}</td>
                    <td width="40" align="center">{player.weight}</td>
                    <td width="40" align="center">{player.redshirt?'Rs-':''}{player.class}</td>
                    <td width="60" align="center">{player.status}</td>
                  </tr>
                )}
              </tbody>
            </Table>

            <h5>Nickelback</h5>
            <Table bordered size="sm">
              <tbody>
                {nickelSafeties.map((player, index) =>
                  <tr key={index} className={player.enrolled ? '' : 'not-enrolled'}>
                    <td width="30" align="center">{player.jersey}</td>
                    <td width="150" className={player.enrolled ? '' : 'hideCell'}>
                      {player.walkOn ? '# ' : ''}
                      <a href={"https://usctrojans.com/sports/football/roster/" + player.link} target={"_blank"} rel="noreferrer">
                        {player.firstName} {player.lastName} {player.suffix}
                      </a>
                    </td>
                    <td width="150" className={player.enrolled ? 'hideCell' : ''}>
                      {player.walkOn ? '# ' : ''}{player.firstName} {player.lastName} {player.suffix}
                    </td>
                    <td width="40" align="center">{player.height}</td>
                    <td width="40" align="center">{player.weight}</td>
                    <td width="40" align="center">{player.redshirt?'Rs-':''}{player.class}</td>
                    <td width="60" align="center">{player.status}</td>
                  </tr>
                )}
              </tbody>
            </Table>
            
            <h5>Cornerback</h5>
            <Table bordered size="sm">
              <tbody>
                {fieldCornerbacks.map((player, index) =>
                  <tr key={index} className={player.enrolled ? '' : 'not-enrolled'}>
                    <td width="30" align="center">{player.jersey}</td>
                    <td width="150" className={player.enrolled ? '' : 'hideCell'}>
                      {player.walkOn ? '# ' : ''}
                      <a href={"https://usctrojans.com/sports/football/roster/" + player.link} target={"_blank"} rel="noreferrer">
                        {player.firstName} {player.lastName} {player.suffix}
                      </a>
                    </td>
                    <td width="150" className={player.enrolled ? 'hideCell' : ''}>
                      {player.walkOn ? '# ' : ''}{player.firstName} {player.lastName} {player.suffix}
                    </td>
                    <td width="40" align="center">{player.height}</td>
                    <td width="40" align="center">{player.weight}</td>
                    <td width="40" align="center">{player.redshirt?'Rs-':''}{player.class}</td>
                    <td width="60" align="center">{player.status}</td>
                  </tr>
                )}
              </tbody>
            </Table>

            <h5>Cornerback</h5>
            <Table bordered size="sm">
              <tbody>
                {borderCornerbacks.map((player, index) =>
                  <tr key={index} className={player.enrolled ? '' : 'not-enrolled'}>
                    <td width="30" align="center">{player.jersey}</td>
                    <td width="150" className={player.enrolled ? '' : 'hideCell'}>
                      {player.walkOn ? '# ' : ''}
                      <a href={"https://usctrojans.com/sports/football/roster/" + player.link} target={"_blank"} rel="noreferrer">
                        {player.firstName} {player.lastName} {player.suffix}
                      </a>
                    </td>
                    <td width="150" className={player.enrolled ? 'hideCell' : ''}>
                      {player.walkOn ? '# ' : ''}{player.firstName} {player.lastName} {player.suffix}
                    </td>
                    <td width="40" align="center">{player.height}</td>
                    <td width="40" align="center">{player.weight}</td>
                    <td width="40" align="center">{player.redshirt?'Rs-':''}{player.class}</td>
                    <td width="60" align="center">{player.status}</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Defense;