import Data from '../../Data';
import Table from 'react-bootstrap/Table';
import { Container, Row, Col } from 'react-bootstrap';

function Offense() {
  const file = '2024roster';
  const sortOrder = ['depth'];
  const quarterbacksCriteria = [{prop: 'position', value: 'QB'}];
  const quarterbacks = Data(file, sortOrder, quarterbacksCriteria);
  const runningBacksCriteria = [{prop: 'position', value: 'F'}];
  const runningBacks = Data(file, sortOrder, runningBacksCriteria);
  const xReceiversCriteria = [{prop: 'position', value: 'X'}];
  const xReceivers = Data(file, sortOrder, xReceiversCriteria);
  const hReceiversCriteria = [{prop: 'position', value: 'H'}];
  const hReceivers = Data(file, sortOrder, hReceiversCriteria);
  const yReceiversCriteria = [{prop: 'position', value: 'Y'}];
  const yReceivers = Data(file, sortOrder, yReceiversCriteria);
  const zReceiversCriteria = [{prop: 'position', value: 'Z'}];
  const zReceivers = Data(file, sortOrder, zReceiversCriteria);
  const tightEndsCriteria = [{prop: 'position', value: 'TE'}];
  const tightEnds = Data(file, sortOrder, tightEndsCriteria);
  const rightTacklesCriteria = [{prop: 'position', value: 'RT'}];
  const rightTackles = Data(file, sortOrder, rightTacklesCriteria);
  const rightGuardsCriteria = [{prop: 'position', value: 'RG'}];
  const rightGuards = Data(file, sortOrder, rightGuardsCriteria);
  const centersCriteria = [{prop: 'position', value: 'C'}];
  const centers = Data(file, sortOrder, centersCriteria);
  const leftGuardsCriteria = [{prop: 'position', value: 'LG'}];
  const leftGuards = Data(file, sortOrder, leftGuardsCriteria);
  const leftTacklesCriteria = [{prop: 'position', value: 'LT'}];
  const leftTackles = Data(file, sortOrder, leftTacklesCriteria);
  
  return (
    <div className="App-content">
      <h3>2024 Offense</h3>
      <p><a href="#/roster-defense">Defense</a> | <a href="#/roster-special-teams">Special Teams</a></p>
      <p>
        This is a <i>speculative</i> depth chart. It may not accurately reflect the views of the coaching staff.<br/>
        # Non-scholarship player
      </p>

      <Container fluid>
        <Row>
          <Col className={'fixed-one-third'}>
            <h4>Quarterbacks</h4>
            <h5>Coach: <a href="https://usctrojans.com/sports/football/roster/coaches/luke-huard/5467" target={"_blank"} rel="noreferrer">Luke Huard</a></h5>

            <Table bordered size="sm">
              <tbody>
                {quarterbacks.map((player, index) =>
                  <tr key={index} className={player.enrolled ? '' : 'not-enrolled'}>
                    <td width="30" align="center">{player.jersey}</td>
                    <td width="150" className={player.enrolled ? '' : 'hideCell'}>
                      {player.walkOn ? '# ' : ''}
                      <a href={"https://usctrojans.com/sports/football/roster/" + player.link} target={"_blank"} rel="noreferrer">
                        {player.firstName} {player.lastName} {player.suffix}
                      </a>
                    </td>
                    <td width="150" className={player.enrolled ? 'hideCell' : ''}>
                      {player.walkOn ? '# ' : ''}{player.firstName} {player.lastName} {player.suffix}
                    </td>
                    <td width="40" align="center">{player.height}</td>
                    <td width="40" align="center">{player.weight}</td>
                    <td width="40" align="center">{player.redshirt?'Rs-':''}{player.class}</td>
                    <td width="60" align="center">{player.status}</td>
                  </tr>
                )}
              </tbody>
            </Table>

            <h4>Running Backs</h4>
            <h5>Coach: <a href={"https://usctrojans.com/sports/football/roster/coaches/anthony-jones-jr-/6904"} target={"_blank"} rel="noreferrer">Anthony Jones Jr.</a></h5>

            <Table bordered size="sm">
              <tbody>
                {runningBacks.map((player, index) =>
                  <tr key={index} className={player.enrolled ? '' : 'not-enrolled'}>
                    <td width="30" align="center">{player.jersey}</td>
                    <td width="150" className={player.enrolled ? '' : 'hideCell'}>
                      {player.walkOn ? '# ' : ''}
                      <a href={"https://usctrojans.com/sports/football/roster/" + player.link} target={"_blank"} rel="noreferrer">
                        {player.firstName} {player.lastName} {player.suffix}
                      </a>
                    </td>
                    <td width="150" className={player.enrolled ? 'hideCell' : ''}>
                      {player.walkOn ? '# ' : ''}{player.firstName} {player.lastName} {player.suffix}
                    </td>
                    <td width="40" align="center">{player.height}</td>
                    <td width="40" align="center">{player.weight}</td>
                    <td width="40" align="center">{player.redshirt?'Rs-':''}{player.class}</td>
                    <td width="60" align="center">{player.status}</td>
                  </tr>
                )}
              </tbody>
            </Table>

            <h4>Tight Ends</h4>
            <h5>Coach: <a href="https://usctrojans.com/sports/football/roster/coaches/zach-hanson/5466" target={"_blank"} rel="noreferrer">Zach Hanson</a></h5>

            <Table bordered size="sm">
              <tbody>
                {tightEnds.map((player, index) =>
                  <tr key={index} className={player.enrolled ? '' : 'not-enrolled'}>
                    <td width="30" align="center">{player.jersey}</td>
                    <td width="150" className={player.enrolled ? '' : 'hideCell'}>
                      {player.walkOn ? '# ' : ''}
                      <a href={"https://usctrojans.com/sports/football/roster/" + player.link} target={"_blank"} rel="noreferrer">
                        {player.firstName} {player.lastName} {player.suffix}
                      </a>
                    </td>
                    <td width="150" className={player.enrolled ? 'hideCell' : ''}>
                      {player.walkOn ? '# ' : ''}{player.firstName} {player.lastName} {player.suffix}
                    </td>
                    <td width="40" align="center">{player.height}</td>
                    <td width="40" align="center">{player.weight}</td>
                    <td width="40" align="center">{player.redshirt?'Rs-':''}{player.class}</td>
                    <td width="60" align="center">{player.status}</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Col>

          <Col className={'fixed-one-third'}>
            <h4>Receivers</h4>
            <h5>Coach: <a href="https://usctrojans.com/sports/football/roster/coaches/dennis-simmons/5458" target={"_blank"} rel="noreferrer">Dennis Simmons</a></h5>
            
            <h5>H Receiver</h5>
            <Table bordered size="sm">
              <tbody>
                {hReceivers.map((player, index) =>
                  <tr key={index} className={player.enrolled ? '' : 'not-enrolled'}>
                    <td width="30" align="center">{player.jersey}</td>
                    <td width="150" className={player.enrolled ? '' : 'hideCell'}>
                      {player.walkOn ? '# ' : ''}
                      <a href={"https://usctrojans.com/sports/football/roster/" + player.link} target={"_blank"} rel="noreferrer">
                        {player.firstName} {player.lastName} {player.suffix}
                      </a>
                    </td>
                    <td width="150" className={player.enrolled ? 'hideCell' : ''}>
                      {player.walkOn ? '# ' : ''}{player.firstName} {player.lastName} {player.suffix}
                    </td>
                    <td width="40" align="center">{player.height}</td>
                    <td width="40" align="center">{player.weight}</td>
                    <td width="40" align="center">{player.redshirt?'Rs-':''}{player.class}</td>
                    <td width="60" align="center">{player.status}</td>
                  </tr>
                )}
              </tbody>
            </Table>

            <h5>Y Receiver</h5>
            <Table bordered size="sm">
              <tbody>
                {yReceivers.map((player, index) =>
                  <tr key={index} className={player.enrolled ? '' : 'not-enrolled'}>
                    <td width="30" align="center">{player.jersey}</td>
                    <td width="150" className={player.enrolled ? '' : 'hideCell'}>
                      {player.walkOn ? '# ' : ''}
                      <a href={"https://usctrojans.com/sports/football/roster/" + player.link} target={"_blank"} rel="noreferrer">
                        {player.firstName} {player.lastName} {player.suffix}
                      </a>
                    </td>
                    <td width="150" className={player.enrolled ? 'hideCell' : ''}>
                      {player.walkOn ? '# ' : ''}{player.firstName} {player.lastName} {player.suffix}
                    </td>
                    <td width="40" align="center">{player.height}</td>
                    <td width="40" align="center">{player.weight}</td>
                    <td width="40" align="center">{player.redshirt?'Rs-':''}{player.class}</td>
                    <td width="60" align="center">{player.status}</td>
                  </tr>
                )}
              </tbody>
            </Table>

            <h5>Z Receiver</h5>
            <Table bordered size="sm">
              <tbody>
                {zReceivers.map((player, index) =>
                  <tr key={index} className={player.enrolled ? '' : 'not-enrolled'}>
                    <td width="30" align="center">{player.jersey}</td>
                    <td width="150" className={player.enrolled ? '' : 'hideCell'}>
                      {player.walkOn ? '# ' : ''}
                      <a href={"https://usctrojans.com/sports/football/roster/" + player.link} target={"_blank"} rel="noreferrer">
                        {player.firstName} {player.lastName} {player.suffix}
                      </a>
                    </td>
                    <td width="150" className={player.enrolled ? 'hideCell' : ''}>
                      {player.walkOn ? '# ' : ''}{player.firstName} {player.lastName} {player.suffix}
                    </td>
                    <td width="40" align="center">{player.height}</td>
                    <td width="40" align="center">{player.weight}</td>
                    <td width="40" align="center">{player.redshirt?'Rs-':''}{player.class}</td>
                    <td width="60" align="center">{player.status}</td>
                  </tr>
                )}
              </tbody>
            </Table>

            <h5>X Receiver</h5>
            <Table bordered size="sm">
              <tbody>
                {xReceivers.map((player, index) =>
                  <tr key={index} className={player.enrolled ? '' : 'not-enrolled'}>
                    <td width="30" align="center">{player.jersey}</td>
                    <td width="150" className={player.enrolled ? '' : 'hideCell'}>
                      {player.walkOn ? '# ' : ''}
                      <a href={"https://usctrojans.com/sports/football/roster/" + player.link} target={"_blank"} rel="noreferrer">
                        {player.firstName} {player.lastName} {player.suffix}
                      </a>
                    </td>
                    <td width="150" className={player.enrolled ? 'hideCell' : ''}>
                      {player.walkOn ? '# ' : ''}{player.firstName} {player.lastName} {player.suffix}
                    </td>
                    <td width="40" align="center">{player.height}</td>
                    <td width="40" align="center">{player.weight}</td>
                    <td width="40" align="center">{player.redshirt?'Rs-':''}{player.class}</td>
                    <td width="60" align="center">{player.status}</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Col>

          <Col className={'fixed-one-third'}>
            <h4>Offensive Linemen</h4>
            <h5>Coach: <a href="https://usctrojans.com/sports/football/roster/coaches/josh-henson/5460" target={"_blank"} rel="noreferrer">Josh Henson</a></h5>

            <h5>Left Tackle</h5>
            <Table bordered size="sm">
              <tbody>
                {leftTackles.map((player, index) =>
                  <tr key={index} className={player.enrolled ? '' : 'not-enrolled'}>
                    <td width="30" align="center">{player.jersey}</td>
                    <td width="150" className={player.enrolled ? '' : 'hideCell'}>
                      {player.walkOn ? '# ' : ''}
                      <a href={"https://usctrojans.com/sports/football/roster/" + player.link} target={"_blank"} rel="noreferrer">
                        {player.firstName} {player.lastName} {player.suffix}
                      </a>
                    </td>
                    <td width="150" className={player.enrolled ? 'hideCell' : ''}>
                      {player.walkOn ? '# ' : ''}{player.firstName} {player.lastName} {player.suffix}
                    </td>
                    <td width="40" align="center">{player.height}</td>
                    <td width="40" align="center">{player.weight}</td>
                    <td width="40" align="center">{player.redshirt?'Rs-':''}{player.class}</td>
                    <td width="60" align="center">{player.status}</td>
                  </tr>
                )}
              </tbody>
            </Table>

            <h5>Left Guard</h5>
            <Table bordered size="sm">
              <tbody>
                {leftGuards.map((player, index) =>
                  <tr key={index} className={player.enrolled ? '' : 'not-enrolled'}>
                    <td width="30" align="center">{player.jersey}</td>
                    <td width="150" className={player.enrolled ? '' : 'hideCell'}>
                      {player.walkOn ? '# ' : ''}
                      <a href={"https://usctrojans.com/sports/football/roster/" + player.link} target={"_blank"} rel="noreferrer">
                        {player.firstName} {player.lastName} {player.suffix}
                      </a>
                    </td>
                    <td width="150" className={player.enrolled ? 'hideCell' : ''}>
                      {player.walkOn ? '# ' : ''}{player.firstName} {player.lastName} {player.suffix}
                    </td>
                    <td width="40" align="center">{player.height}</td>
                    <td width="40" align="center">{player.weight}</td>
                    <td width="40" align="center">{player.redshirt?'Rs-':''}{player.class}</td>
                    <td width="60" align="center">{player.status}</td>
                  </tr>
                )}
              </tbody>
            </Table>

            <h5>Center</h5>
            <Table bordered size="sm">
              <tbody>
                {centers.map((player, index) =>
                  <tr key={index} className={player.enrolled ? '' : 'not-enrolled'}>
                    <td width="30" align="center">{player.jersey}</td>
                    <td width="150" className={player.enrolled ? '' : 'hideCell'}>
                      {player.walkOn ? '# ' : ''}
                      <a href={"https://usctrojans.com/sports/football/roster/" + player.link} target={"_blank"} rel="noreferrer">
                        {player.firstName} {player.lastName} {player.suffix}
                      </a>
                    </td>
                    <td width="150" className={player.enrolled ? 'hideCell' : ''}>
                      {player.walkOn ? '# ' : ''}{player.firstName} {player.lastName} {player.suffix}
                    </td>
                    <td width="40" align="center">{player.height}</td>
                    <td width="40" align="center">{player.weight}</td>
                    <td width="40" align="center">{player.redshirt?'Rs-':''}{player.class}</td>
                    <td width="60" align="center">{player.status}</td>
                  </tr>
                )}
              </tbody>
            </Table>

            <h5>Right Guard</h5>
            <Table bordered size="sm">
              <tbody>
                {rightGuards.map((player, index) =>
                  <tr key={index} className={player.enrolled ? '' : 'not-enrolled'}>
                    <td width="30" align="center">{player.jersey}</td>
                    <td width="150" className={player.enrolled ? '' : 'hideCell'}>
                      {player.walkOn ? '# ' : ''}
                      <a href={"https://usctrojans.com/sports/football/roster/" + player.link} target={"_blank"} rel="noreferrer">
                        {player.firstName} {player.lastName} {player.suffix}
                      </a>
                    </td>
                    <td width="150" className={player.enrolled ? 'hideCell' : ''}>
                      {player.walkOn ? '# ' : ''}{player.firstName} {player.lastName} {player.suffix}
                    </td>
                    <td width="40" align="center">{player.height}</td>
                    <td width="40" align="center">{player.weight}</td>
                    <td width="40" align="center">{player.redshirt?'Rs-':''}{player.class}</td>
                    <td width="60" align="center">{player.status}</td>
                  </tr>
                )}
              </tbody>
            </Table>

            <h5>Right Tackle</h5>
            <Table bordered size="sm">
              <tbody>
                {rightTackles.map((player, index) =>
                  <tr key={index} className={player.enrolled ? '' : 'not-enrolled'}>
                    <td width="30" align="center">{player.jersey}</td>
                    <td width="150" className={player.enrolled ? '' : 'hideCell'}>
                      {player.walkOn ? '# ' : ''}
                      <a href={"https://usctrojans.com/sports/football/roster/" + player.link} target={"_blank"} rel="noreferrer">
                        {player.firstName} {player.lastName} {player.suffix}
                      </a>
                    </td>
                    <td width="150" className={player.enrolled ? 'hideCell' : ''}>
                      {player.walkOn ? '# ' : ''}{player.firstName} {player.lastName} {player.suffix}
                    </td>
                    <td width="40" align="center">{player.height}</td>
                    <td width="40" align="center">{player.weight}</td>
                    <td width="40" align="center">{player.redshirt?'Rs-':''}{player.class}</td>
                    <td width="60" align="center">{player.status}</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Offense;