import Data from '../../Data';
import Table from 'react-bootstrap/Table';

function Player({player}) {
  if (player.link) {
    return <li className={player.enrolled ? "" : "notYetEnrolled"}>{player.walkOn ? '# ' : ''}<a href={"https://usctrojans.com/sports/football/roster/" + player.link} target={"_blank"} rel="noreferrer">{player.firstName} {player.lastName} {player.suffix}</a> {player.redshirt ? 'Rs-' : ''}{player.class}{player.covid ? '^' : ''}</li>;
  }

  return <li className={player.enrolled ? "" : "notYetEnrolled"}>{player.walkOn ? '# ' : ''}{player.firstName} {player.lastName} {player.suffix} {player.redshirt ? 'Rs-' : ''}{player.class}{player.covid ? '^' : ''}</li>;
}

function Eligibility() {
  const file = '2024roster';
  const sortOrder = ['lastName', 'firstName'];
  const positionList = ['QB', 'RB', 'WR', 'TE', 'OL', 'DL', 'DE', 'LB', 'S', 'CB', 'P', 'K', 'SNP'];
  
  let eligibilityMatrix = []
  for (let i=0; i<positionList.length; i++) {
    let years = [];
    for (let j=0; j<4; j++) {
      const criteria = [{prop: 'displaypos', value: positionList[i]}, {prop: 'eligibility', value: j+1}];
      const players = Data(file, sortOrder, criteria);
      years.push(players);
    };
    eligibilityMatrix.push({
      displaypos: positionList[i],
      years: years
    });
  };

  let positionCounts = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  let yearCounts = [0, 0, 0, 0];
  eligibilityMatrix.forEach((pos, i) => {
    pos.years.forEach((year, j) => {
      let count = 0;
      year.forEach(player => {
        if (!player.walkOn) count++
      });
      positionCounts[i] += count;
      yearCounts[j] +=count;
    });
  });

  return (
    <div className="App-content">
      <h3>2024 Player Eligibility & Scholarship Distribution</h3>
      <p>Years indicate remaining years of eligibiity | Row and column counts include only scholarship players<br/>
      # Non-scholarship player | ^ Exercising additonal covid year</p>

      <Table bordered size="sm">
        <thead>
          <tr>
            <th width="45"></th>
            <th width="180" className={"centeredCol"}>1 Year</th>
            <th width="180" className={"centeredCol"}>2 Years</th>
            <th width="180" className={"centeredCol"}>3 Years</th>
            <th width="180" className={"centeredCol"}>4 Years</th>
            <th width="35"></th>
          </tr>
        </thead>
        <tbody>
          {positionList.map((displayPos, index) =>
            <tr key={index}>
              <td align="center" className={"reverse"}>{displayPos}</td>
              <td>
                {eligibilityMatrix[index].years[0].map((player, index2) =>
                  <ul key={index2} className={"unformattedList"}>
                    <Player player={player} />
                  </ul>
                )}
              </td>
              <td>
                {eligibilityMatrix[index].years[1].map((player, index2) =>
                  <ul key={index2} className={"unformattedList"}>
                    <Player player={player} />
                  </ul>
                )}
              </td>
              <td>
                {eligibilityMatrix[index].years[2].map((player, index2) =>
                  <ul key={index2} className={"unformattedList"}>
                    <Player player={player} />
                  </ul>
                )}
              </td>
              <td>
                {eligibilityMatrix[index].years[3].map((player, index2) =>
                  <ul key={index2} className={"unformattedList"}>
                    <Player player={player} />
                  </ul>
                )}
              </td>
              <td align="center" className={"reverse"}>{positionCounts[index]}</td>
            </tr>
          )}
          <tr>
            <td className={"reverse"}></td>
            <td align="center" className={"reverse"}>{yearCounts[0]}</td>
            <td align="center" className={"reverse"}>{yearCounts[1]}</td>
            <td align="center" className={"reverse"}>{yearCounts[2]}</td>
            <td align="center" className={"reverse"}>{yearCounts[3]}</td>
            <td align="center" className={"reverse"}>{yearCounts[0] + yearCounts[1] + yearCounts[2] + yearCounts[3]}</td>
          </tr>
        </tbody>
      </Table>
    </div>
  )
}

export default Eligibility;