import Data from '../../Data';
import SeasonSelect from './SeasonSelect';

function Board2025() {
  const file = '2025recruits';
  const sortOrder = ['lastName'];
  const quarterbacksCriteria = [{prop: 'position', value: 'QB'}];
  const quarterbacks = Data(file, sortOrder, quarterbacksCriteria);
  const runningBacksCriteria = [{prop: 'position', value: 'RB'}];
  const runningBacks = Data(file, sortOrder, runningBacksCriteria);
  const receiversCriteria = [{prop: 'position', value: 'WR'}];
  const receivers = Data(file, sortOrder, receiversCriteria);
  const tightEndsCriteria = [{prop: 'position', value: 'TE'}];
  const tightEnds = Data(file, sortOrder, tightEndsCriteria);
  const offensiveLineCriteria = [{prop: 'position', value: 'OL'}];
  const offensiveLine = Data(file, sortOrder, offensiveLineCriteria);
  
  const defensiveLineCriteria = [{prop: 'position', value: 'DL'}];
  const defensiveLine = Data(file, sortOrder, defensiveLineCriteria);
  const edgeCriteria = [{prop: 'position', value: 'EDGE'}];
  const edge = Data(file, sortOrder, edgeCriteria);
    const linebackersCriteria = [{prop: 'position', value: 'LB'}];
  const linebackers = Data(file, sortOrder, linebackersCriteria);
  const safetiesCriteria = [{prop: 'position', value: 'S'}];
  const safeties = Data(file, sortOrder, safetiesCriteria);
  const cornerbacksCriteria = [{prop: 'position', value: 'CB'}];
  const cornerbacks = Data(file, sortOrder, cornerbacksCriteria);

  const longSnappersCriteria = [{prop: 'position', value: 'LS'}];
  const longSnappers = Data(file, sortOrder, longSnappersCriteria);
  const kickersCriteria = [{prop: 'position', value: 'K'}];
  const kickers = Data(file, sortOrder, kickersCriteria);

  return (
    <div className="App-content">
      <h3>2025 Recruiting Board</h3>

      <SeasonSelect />

      <p><i>This information is culled from numerous sources (none of which are directly from the coaching staff) and is for 
      entertainment purposes only. Dates indicate weekend of official visits.</i></p>


      <h4>Offense</h4>

      <h5>QUARTERBACKS</h5>
      
      {quarterbacks.map((recruit, index) =>
        <div key={index}>
          <span className={recruit.commit === 'USC' ? 'committed' : ''}>
            <b>{recruit.firstName} {recruit.lastName} {recruit.suffix ? recruit.suffix + ' ' : ' '}</b>
          </span>
          {recruit.height} {recruit.weight} {recruit.hometown}, {recruit.state} {recruit.school}
          {recruit.college ? ' / ' + recruit.college + ' (eligibility: ' + recruit.eligible + ' year' : ''}{recruit.college && recruit.eligible > 1 ? 's' : ''}{recruit.college ? ') ' : ' '}
          {recruit.visits ? ' - ' + recruit.visits : ''}
          <br/>
        </div>
      )}

      <br/>
      <h5>RUNNING BACKS</h5>

      {runningBacks.map((recruit, index) =>
        <div key={index}>
          <span className={recruit.commit === 'USC' ? 'committed' : ''}>
            <b>{recruit.firstName} {recruit.lastName} {recruit.suffix ? recruit.suffix + ' ' : ' '}</b>
          </span>
          {recruit.height} {recruit.weight} {recruit.hometown}, {recruit.state} {recruit.school}
          {recruit.college ? ' / ' + recruit.college + ' (eligibility: ' + recruit.eligible + ' year' : ''}{recruit.college && recruit.eligible > 1 ? 's' : ''}{recruit.college ? ') ' : ' '}
          {recruit.visits ? ' - ' + recruit.visits : ''}
          <br/>
        </div>
      )}

      <br/>
      <h5>RECEIVERS</h5>

      {receivers.map((recruit, index) =>
        <div key={index}>
          <span className={recruit.commit === 'USC' ? 'committed' : ''}>
            <b>{recruit.firstName} {recruit.lastName} {recruit.suffix ? recruit.suffix + ' ' : ' '}</b>
          </span>
          {recruit.height} {recruit.weight} {recruit.hometown}, {recruit.state} {recruit.school}
          {recruit.college ? ' / ' + recruit.college + ' (eligibility: ' + recruit.eligible + ' year' : ''}{recruit.college && recruit.eligible > 1 ? 's' : ''}{recruit.college ? ') ' : ' '}
          {recruit.visits ? ' - ' + recruit.visits : ''}
          <br/>
        </div>
      )}

      <br/>
      <h5>TIGHT ENDS</h5>

      {tightEnds.map((recruit, index) =>
        <div key={index}>
          <span className={recruit.commit === 'USC' ? 'committed' : ''}>
            <b>{recruit.firstName} {recruit.lastName} {recruit.suffix ? recruit.suffix + ' ' : ' '}</b>
          </span>
          {recruit.height} {recruit.weight} {recruit.hometown}, {recruit.state} {recruit.school}
          {recruit.college ? ' / ' + recruit.college + ' (eligibility: ' + recruit.eligible + ' year' : ''}{recruit.college && recruit.eligible > 1 ? 's' : ''}{recruit.college ? ') ' : ' '}
          {recruit.visits ? ' - ' + recruit.visits : ''}
          <br/>
        </div>
      )}

      <br/>
      <h5>OFFENSIVE LINEMEN</h5>

      {offensiveLine.map((recruit, index) =>
        <div key={index}>
          <span className={recruit.commit === 'USC' ? 'committed' : ''}>
            <b>{recruit.firstName} {recruit.lastName} {recruit.suffix ? recruit.suffix + ' ' : ' '}</b>
          </span>
          {recruit.height} {recruit.weight} {recruit.hometown}, {recruit.state} {recruit.school}
          {recruit.college ? ' / ' + recruit.college + ' (eligibility: ' + recruit.eligible + ' year' : ''}{recruit.college && recruit.eligible > 1 ? 's' : ''}{recruit.college ? ') ' : ' '}
          {recruit.visits ? ' - ' + recruit.visits : ''}
          <br/>
        </div>
      )}

      <br/>
      <h4>Defense</h4>
      
      <h5>DEFENSIVE LINEMEN</h5>

      {defensiveLine.map((recruit, index) =>
        <div key={index}>
          <span className={recruit.commit === 'USC' ? 'committed' : ''}>
            <b>{recruit.firstName} {recruit.lastName} {recruit.suffix ? recruit.suffix + ' ' : ' '}</b>
          </span>
          {recruit.height} {recruit.weight} {recruit.hometown}, {recruit.state} {recruit.school}
          {recruit.college ? ' / ' + recruit.college + ' (eligibility: ' + recruit.eligible + ' year' : ''}{recruit.college && recruit.eligible > 1 ? 's' : ''}{recruit.college ? ') ' : ' '}
          {recruit.visits ? ' - ' + recruit.visits : ''}
          <br/>
        </div>
      )}

      <br/>
      <h5>DEFENSIVE ENDS</h5>

      {edge.map((recruit, index) =>
        <div key={index}>
          <span className={recruit.commit === 'USC' ? 'committed' : ''}>
            <b>{recruit.firstName} {recruit.lastName} {recruit.suffix ? recruit.suffix + ' ' : ' '}</b>
          </span>
          {recruit.height} {recruit.weight} {recruit.hometown}, {recruit.state} {recruit.school}
          {recruit.college ? ' / ' + recruit.college + ' (eligibility: ' + recruit.eligible + ' year' : ''}{recruit.college && recruit.eligible > 1 ? 's' : ''}{recruit.college ? ') ' : ' '}
          {recruit.visits ? ' - ' + recruit.visits : ''}
          <br/>
        </div>
      )}
    
      <br/>
      <h5>LINEBACKERS</h5>

      {linebackers.map((recruit, index) =>
        <div key={index}>
          <span className={recruit.commit === 'USC' ? 'committed' : ''}>
            <b>{recruit.firstName} {recruit.lastName} {recruit.suffix ? recruit.suffix + ' ' : ' '}</b>
          </span>
          {recruit.height} {recruit.weight} {recruit.hometown}, {recruit.state} {recruit.school}
          {recruit.college ? ' / ' + recruit.college + ' (eligibility: ' + recruit.eligible + ' year' : ''}{recruit.college && recruit.eligible > 1 ? 's' : ''}{recruit.college ? ') ' : ' '}
          {recruit.visits ? ' - ' + recruit.visits : ''}
          <br/>
        </div>
      )}

      <br/>
      <h5>SAFETIES</h5>

      {safeties.map((recruit, index) =>
        <div key={index}>
          <span className={recruit.commit === 'USC' ? 'committed' : ''}>
            <b>{recruit.firstName} {recruit.lastName} {recruit.suffix ? recruit.suffix + ' ' : ' '}</b>
          </span>
          {recruit.height} {recruit.weight} {recruit.hometown}, {recruit.state} {recruit.school}
          {recruit.college ? ' / ' + recruit.college + ' (eligibility: ' + recruit.eligible + ' year' : ''}{recruit.college && recruit.eligible > 1 ? 's' : ''}{recruit.college ? ') ' : ' '}
          {recruit.visits ? ' - ' + recruit.visits : ''}
          <br/>
        </div>
      )}

      <br/>
      <h5>CORNERBACKS</h5>

      {cornerbacks.map((recruit, index) =>
        <div key={index}>
          <span className={recruit.commit === 'USC' ? 'committed' : ''}>
            <b>{recruit.firstName} {recruit.lastName} {recruit.suffix ? recruit.suffix + ' ' : ' '}</b>
          </span>
          {recruit.height} {recruit.weight} {recruit.hometown}, {recruit.state} {recruit.school}
          {recruit.college ? ' / ' + recruit.college + ' (eligibility: ' + recruit.eligible + ' year' : ''}{recruit.college && recruit.eligible > 1 ? 's' : ''}{recruit.college ? ') ' : ' '}
          {recruit.visits ? ' - ' + recruit.visits : ''}
          <br/>
        </div>
      )}

      {/* <br/>
      <h4>Special Teams</h4>
      
      <h5>LONG SNAPPERS</h5>

      {longSnappers.map((recruit, index) =>
        <div key={index}>
          <span className={recruit.commit === 'USC' ? 'committed' : ''}>
            <b>{recruit.firstName} {recruit.lastName} {recruit.suffix ? recruit.suffix + ' ' : ' '}</b>
          </span>
          {recruit.height} {recruit.weight} {recruit.hometown}, {recruit.state} {recruit.school}
          {recruit.college ? ' / ' + recruit.college + ' (eligibility: ' + recruit.eligible + ' year' : ''}{recruit.college && recruit.eligible > 1 ? 's' : ''}{recruit.college ? ') ' : ' '}
          {recruit.visits ? ' - ' + recruit.visits : ''}
          <br/>
        </div>
      )}

      <h5>KICKERS</h5>

      {kickers.map((recruit, index) =>
        <div key={index}>
          <span className={recruit.commit === 'USC' ? 'committed' : ''}>
            <b>{recruit.firstName} {recruit.lastName} {recruit.suffix ? recruit.suffix + ' ' : ' '}</b>
          </span>
          {recruit.height} {recruit.weight} {recruit.hometown}, {recruit.state} {recruit.school}
          {recruit.college ? ' / ' + recruit.college + ' (eligibility: ' + recruit.eligible + ' year' : ''}{recruit.college && recruit.eligible > 1 ? 's' : ''}{recruit.college ? ') ' : ' '}
          {recruit.visits ? ' - ' + recruit.visits : ''}
          <br/>
        </div>
      )} */}

    </div>
  )
}

export default Board2025;