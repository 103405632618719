import './App.scss';
import React from 'react';
import { HashRouter, Routes, Route, Link } from "react-router-dom";

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import NavLink from 'react-bootstrap/NavLink';
import Offcanvas from 'react-bootstrap/Offcanvas';

import Home from './components/Home';
import About from './components/About';
import NotFound from './components/NotFound';

import Spring2024PreviewOffense from './components/posts/spring2024-preview-offense';
import Spring2024PreviewDefense from './components/posts/spring2024-preview-defense';

import Season2022 from './components/season/2022';
import Season2023 from './components/season/2023';
import Season2024 from './components/season/2024';
import Season2025 from './components/season/2025';
import Season2026 from './components/season/2026';
import Season2027 from './components/season/2027';
import Season2028 from './components/season/2028';
import FutureSchedules from './components/season/FutureSchedules';
import TVSchedules from './components/season/TVSchedules';

import NameRoster from './components/roster/Name';
import NumberRoster from './components/roster/Number';
import Majors from './components/roster/Majors';
import Region from './components/roster/Geographic';
import Offense from './components/roster/Offense';
import Defense from './components/roster/Defense';
import SpecialTeams from './components/roster/SpecialTeams';
import Eligibility from './components/roster/Eligibility';
import AvailableNumbers from './components/roster/AvailableNumbers';
import AllTimeJerseyNumbers from './components/more/AllTimeJerseysNumbers';
import Staff from './components/roster/Staff';

import GMTargetLists from './components/recruiting/gm';
import Board2024 from './components/recruiting/2024';
import Board2025 from './components/recruiting/2025';
import Board2026 from './components/recruiting/2026';
import VisitScorecard from './components/recruiting/VisitScorecard';

import Rivals from './components/big-ten/Rivals';
import VersusUSC from './components/big-ten/VersusUSC';
import BigTenSchedules from './components/big-ten/Schedules';


function App() {
  let expand = 'nav';

  return (
    <HashRouter>
      <div>
        <>
        <Navbar collapseOnSelect key={expand} fixed="top" expand="lg" bg="" variant="dark">
          <Container>
            <Navbar.Brand as={Link} to="/">TRIBUTE<span className={"to"}>TO</span>TROY</Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`} >
                  <a href="/">TRIBUTE<span className={"to"}>TO</span>TROY</a>
                </Offcanvas.Title>
              </Offcanvas.Header>

              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <NavDropdown title="Schedule" id={`offcanvasNavbarDropdown-expand-${expand}`}>
                    <NavDropdown.Item eventKey="{1.1}" as={Link} to="/season/2024">Schedule & Results</NavDropdown.Item>
                    <NavDropdown.Item eventKey="{1.2}" as={Link} to="/future-schedules">Non-Conference Schedules</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item eventKey="{1.4}" as={Link} to="/tv-schedules">TV Schedules</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Header>Big Ten</NavDropdown.Header>
                    <NavDropdown.Item eventKey="{1.5.1}" as={Link} to="/big-ten/versus-usc">All-Time Against Big Ten</NavDropdown.Item>
                    <NavDropdown.Item eventKey="{1.5.2}" as={Link} to="/big-ten/rivals">Traditional Big Ten Rivals</NavDropdown.Item>
                    <NavDropdown.Item eventKey="{1.5.3}" as={Link} to="/big-ten/schedules">Big Ten Schedules</NavDropdown.Item>
                  </NavDropdown>

                  <NavDropdown title="Roster" id={`offcanvasNavbarDropdown-expand-${expand}`}>
                    <NavDropdown.Item eventKey="{2.1.1}" as={Link} to="/roster-name">Player Names</NavDropdown.Item>
                    <NavDropdown.Item eventKey="{2.1.2}" as={Link} to="/roster-number">Player Numbers</NavDropdown.Item>
                    <NavDropdown.Item eventKey="{2.1.3}" as={Link} to="/player-majors">Player Majors</NavDropdown.Item>
                    <NavDropdown.Item eventKey="{2.1.3}" as={Link} to="/roster-geographic">Player High Schools</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Header>Depth Charts</NavDropdown.Header>
                    <NavDropdown.Item eventKey="{2.2.1}" as={Link} to="/roster-offense">Offense</NavDropdown.Item>
                    <NavDropdown.Item eventKey="{2.2.2}" as={Link} to="/roster-defense">Defense</NavDropdown.Item>
                    <NavDropdown.Item eventKey="{2.2.3}" as={Link} to="/roster-special-teams">Special Teams</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item eventKey="{2.3}" as={Link} to="/roster-eligibility">Scholarship Distribution</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item eventKey="{2.4.1}" as={Link} to="/available-numbers">Available Jersey Numbers</NavDropdown.Item>
                    <NavDropdown.Item eventKey="{2.4.2}" as={Link} to="/all-time-jersey-numbers">All-Time Jersey Numbers</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item eventKey="{2.5}" as={Link} to="/coaching-staff">Coaching Staff</NavDropdown.Item>
                  </NavDropdown>

                  <NavDropdown title="Recruiting" id={`offcanvasNavbarDropdown-expand-${expand}`}>
                    <NavDropdown.Item eventKey="{3.1.1}" as={Link} to="/recruiting/2024">2024 Board</NavDropdown.Item>
                    <NavDropdown.Item eventKey="{3.1.2}" as={Link} to="/recruiting/2025">2025 Board</NavDropdown.Item>
                    <NavDropdown.Item eventKey="{3.1.2}" as={Link} to="/recruiting/2026">2026 Board</NavDropdown.Item>
                    {/* <NavDropdown.Divider />
                    <NavDropdown.Item eventKey="{3.2}" as={Link} to="/recruiting/visit-scorecard">Official Visit Scorecard</NavDropdown.Item> */}
                    <NavDropdown.Divider />
                    <NavDropdown.Item eventKey="{3.3}" as={Link} to="/recruiting/gm">USCFootball.com Target Lists</NavDropdown.Item>
                  </NavDropdown>

                  <NavLink eventKey="{4}" as={Link} to="/about">About TributeToTroy</NavLink>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
        </>
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={<Home/>}/>

          <Route path="/posts/spring2024-preview-offense" element={<Spring2024PreviewOffense/>}/>
          <Route path="/posts/spring2024-preview-defense" element={<Spring2024PreviewDefense/>}/>

          <Route path="/season/2022" element={<Season2022/>}/>
          <Route path="/season/2023" element={<Season2023/>}/>
          <Route path="/season/2024" element={<Season2024/>}/>
          <Route path="/season/2025" element={<Season2025/>}/>
          <Route path="/season/2026" element={<Season2026/>}/>
          <Route path="/season/2027" element={<Season2027/>}/>
          <Route path="/season/2028" element={<Season2028/>}/>
          <Route path="/future-schedules" element={<FutureSchedules/>}/>
          <Route path="/tv-schedules" element={<TVSchedules/>}/>
          
          <Route path="/roster-name" element={<NameRoster/>}/>
          <Route path="/roster-number" element={<NumberRoster/>}/>
          <Route path="/player-majors" element={<Majors/>}/>
          <Route path="/roster-geographic" element={<Region/>}/>
          <Route path="/roster-offense" element={<Offense/>}/>
          <Route path="/roster-defense" element={<Defense/>}/>
          <Route path="/roster-special-teams" element={<SpecialTeams/>}/>
          <Route path="/roster-eligibility" element={<Eligibility/>}/>
          <Route path="/available-numbers" element={<AvailableNumbers/>}/>
          <Route path="/all-time-jersey-numbers" element={<AllTimeJerseyNumbers/>}/>
          <Route path="/coaching-staff" element={<Staff/>}/>

          <Route path="/recruiting/gm" element={<GMTargetLists/>}/>
          <Route path="/recruiting/2024" element={<Board2024/>}/>
          <Route path="/recruiting/2025" element={<Board2025/>}/>
          <Route path="/recruiting/2026" element={<Board2026/>}/>
          <Route path="/recruiting/visit-scorecard" element={<VisitScorecard/>}/>

          <Route path="/big-ten/rivals" element={<Rivals/>}/>
          <Route path="/big-ten/versus-usc" element={<VersusUSC/>}/>
          <Route path="/big-ten/schedules" element={<BigTenSchedules/>}/>
          
          <Route path="/about" element={<About/>}/>
        </Routes>

        {/* <Navbar sticky="bottom" expand="lg" bg="dark" variant="dark">
          © 2023 TributeToTroy. All rights reserved.
        </Navbar> */}
      </div>
    </HashRouter>
  );
}

export default App;