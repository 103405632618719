import Table from 'react-bootstrap/Table'
import SeasonSelect from './SeasonSelect';

function Season2027() {
  return (
    <div className="App-content">
      <h3>2027 Schedule</h3>

      <SeasonSelect />

      <Table responsive bordered hover size="sm">
        <thead>
          <tr>
            <th width="50" className={"centeredCol"}>Date</th>
            <th width="125" className={"centeredCol"}>Opponent</th>
            <th width="100" className={"centeredCol"}>Conference</th>
            <th width="75" className={"centeredCol"}>Time (PT)</th>
            <th width="100" className={"centeredCol"}>TV</th>
            <th width="125" className={"centeredCol"}>Location</th>
          </tr>
        </thead>
        <tbody>
          <tr className="monthRow">
            <td colSpan={7}>
              September
            </td>
          </tr>
          <tr>
            <td align="center">4</td>
            <td>UNLV</td>
            <td>Mountain West</td>
            <td align="center">TBD</td>
            <td align="center">TBD</td>
            <td>L.A. Coliseum</td>
          </tr>
          <tr>
            <td align="center">11</td>
            <td>Nevada</td>
            <td>Mountain West</td>
            <td align="center">TBD</td>
            <td align="center">TBD</td>
            <td>L.A. Coliseum</td>
          </tr>
         
          <tr className="monthRow">
            <td colSpan={7}>
              Dates To Be Determined
            </td>
          </tr>
          <tr>
            <td align="center">TBD</td>
            <td>Illinois</td>
            <td>Big Ten</td>
            <td align="center">TBD</td>
            <td align="center">TBD</td>
            <td>L.A. Coliseum</td>
          </tr>
          <tr>
            <td align="center">TBD</td>
            <td>Indiana</td>
            <td>Big Ten</td>
            <td align="center">TBD</td>
            <td align="center">TBD</td>
            <td>L.A. Coliseum</td>
          </tr>
          <tr>
            <td align="center">TBD</td>
            <td>Iowa</td>
            <td>Big Ten</td>
            <td align="center">TBD</td>
            <td align="center">TBD</td>
            <td>Iowa City, IA</td>
          </tr>
          <tr>
            <td align="center">TBD</td>
            <td>Maryland</td>
            <td>Big Ten</td>
            <td align="center">TBD</td>
            <td align="center">TBD</td>
            <td>College Park, MD</td>
          </tr>
          <tr>
            <td align="center">TBD</td>
            <td>Minnesota</td>
            <td>Big Ten</td>
            <td align="center">TBD</td>
            <td align="center">TBD</td>
            <td>L.A. Coliseum</td>
          </tr>
          <tr>
            <td align="center">TBD</td>
            <td>Ohio State</td>
            <td>Big Ten</td>
            <td align="center">TBD</td>
            <td align="center">TBD</td>
            <td>Columbus, OH</td>
          </tr>
          <tr>
            <td align="center">TBD</td>
            <td>UCLA</td>
            <td>Big Ten</td>
            <td align="center">TBD</td>
            <td align="center">TBD</td>
            <td>L.A. Coliseum</td>
          </tr>
          <tr>
            <td align="center">TBD</td>
            <td>Washington</td>
            <td>Big Ten</td>
            <td align="center">TBD</td>
            <td align="center">TBD</td>
            <td>Seattle, WA</td>
          </tr>
          <tr>
            <td align="center">TBD</td>
            <td>Wisconsin</td>
            <td>Big Ten</td>
            <td align="center">TBD</td>
            <td align="center">TBD</td>
            <td>L.A. Coliseum</td>
          </tr>
        </tbody>
      </Table>
    </div>
  )
}

export default Season2027;