import { Col, Container, Row } from "react-bootstrap";

function Staff() {
  return (
    <div className="App-content">
      <h3>Coaching Staff</h3>
      <br/>

      <Container fluid>
        <Row>
          <Col className={'fixed-one-third'}>
          <h4>Head Football Coach</h4>

          <h5><a href={"https://usctrojans.com/sports/football/roster/coaches/lincoln-riley/6610"} target={"_blank"} rel="noreferrer">Lincoln Riley</a></h5>
          <p>Head Coach</p>

          <br/>
            <h4>Offense</h4>

            <h5><a href={"https://usctrojans.com/sports/football/roster/coaches/josh-henson/6613"} target={"_blank"} rel="noreferrer">Josh Henson</a></h5>
            <p>Offensive Coordinator<br/>Offensive Line Coach</p>

            <h5><a href={"https://usctrojans.com/sports/football/roster/coaches/dennis-simmons/6611"} target={"_blank"} rel="noreferrer">Dennis Simmons</a></h5>
            <p>Assistant Head Coach<br/>Passing Game Coordinator<br/>Receivers Coach</p>

            <h5><a href={"https://usctrojans.com/sports/football/roster/coaches/anthony-jones-jr-/6904"} target={"_blank"} rel="noreferrer">Anthony Jones Jr.</a></h5>
            <p>Run Game Coordinator<br/>Running Backs Coach</p>
            
            <h5><a href={"https://usctrojans.com/sports/football/roster/coaches/luke-huard/6620"} target={"_blank"} rel="noreferrer">Luke Huard</a></h5>
            <p>Quarterbacks Coach</p>

            <h5><a href={"https://usctrojans.com/sports/football/roster/coaches/zach-hanson/6619"} target={"_blank"} rel="noreferrer">Zach Hanson</a></h5>
            <p>Tight Ends Coach</p>

            <h5><a href={"https://usctrojans.com/sports/football/roster/coaches/zach-crabtree/6621"} target={"_blank"} rel="noreferrer">Zach Crabtree</a></h5>
            <p>Assistant Offensive Line Coach</p>

            <h5><a href={"https://usctrojans.com/sports/football/roster/coaches/spencer-jones/6906"} target={"_blank"} rel="noreferrer">Spencer Jones</a></h5>
            <p>Offensive Graduate Assistant (Receivers)</p>

            <h5><a href={"https://usctrojans.com/sports/football/roster/coaches/grayson-reed/6907"} target={"_blank"} rel="noreferrer">Grayson Reed</a></h5>
            <p>Offensive Graduate Assistant (Offensive Line)</p>

            <br/>
          </Col>

          <Col className={'fixed-one-third'}>
            <h4>Defense</h4>

            <h5><a href={"https://usctrojans.com/sports/football/roster/coaches/d-anton-lynn/6905"} target={"_blank"} rel="noreferrer">D'Anton Lynn</a></h5>
            <p>Defensive Coordinator</p>

            <h5><a href={"https://usctrojans.com/sports/football/roster/coaches/eric-henderson/6903"} target={"_blank"} rel="noreferrer">Eric Henderson</a></h5>
            <p>Co-Defensive Coordinator<br/>Run Game Coordinator<br/>Defensive Line Coach</p>

            <h5><a href={"https://usctrojans.com/sports/football/roster/coaches/matt-entz/6902"} target={"_blank"} rel="noreferrer">Matt Entz</a></h5>
            <p>Assistant Head Coach for Defense<br/>Linebackers Coach</p>

            <h5><a href={"https://usctrojans.com/sports/football/roster/coaches/shaun-nua/6617"} target={"_blank"} rel="noreferrer">Shaun Nua</a></h5>
            <p>Defensive Ends Coach</p>

            <h5><a href={"https://usctrojans.com/sports/football/roster/coaches/doug-belk/6901"} target={"_blank"} rel="noreferrer">Doug Belk</a></h5>
            <p>Secondary Coach</p>

            <h5><a href={"htthttps://usctrojans.com/sports/football/roster/coaches/taylor-mays/6627ps"} target={"_blank"} rel="noreferrer">Taylor Mays</a>
            </h5><p>Assistant Defensive Backs Coach</p>

            <h5><a href={"https://usctrojans.com/sports/football/roster/coaches/bryson-allen-williams/6908"} target={"_blank"} rel="noreferrer">Bryson Allen-Williams</a></h5>
            <p>Offensive Graduate Assistant (Defensive Ends)</p>

            <h5><a href={"https://usctrojans.com/sports/football/roster/coaches/bookie-radley-hiles/6637"} target={"_blank"} rel="noreferrer">Bookie Radley-Hiles</a></h5>
            <p>Offensive Graduate Assistant (Cornerbacks)</p>

            <h5><a href={"https://usctrojans.com/sports/football/roster/coaches/skyler-jones/6909"} target={"_blank"} rel="noreferrer">Sklyer Jones</a></h5>
            <p>Defensive Analyst (Defensive Line)</p>

            {/* <h5>Jim Hostler</h5>
            <p>Senior Defensive Analyst</p> */}
            
            <br/>
          </Col>

          <Col className={'fixed-one-third'}>
            <h4>Special Teams</h4>

            <h5><a href={"https://usctrojans.com/sports/football/roster/coaches/ryan-dougherty/6626"} target={"_blank"} rel="noreferrer">Ryan Dougherty</a></h5>
            <p>Special Teams Coordinator</p>

            <br/>

            <h4>Strength and Conditioning</h4>

            <h5><a href={"https://usctrojans.com/sports/football/roster/coaches/bennie-wylie/6628"} target={"_blank"} rel="noreferrer">Bennie Wylie</a></h5>
            <p>Director of Football Sports Performance</p>

            {/* <h5>
              <a href={"https://usctrojans.com/sports/football/roster/coaches/caesar-martinez/6629"} target={"_blank"} rel="noreferrer">Caesar Martinez</a><br/>
              <a href={"https://usctrojans.com/sports/football/roster/coaches/drew-smith/6632"} target={"_blank"} rel="noreferrer">Drew Smith</a><br/>
              <a href={"https://usctrojans.com/sports/football/roster/coaches/adam-thackery/6636"} target={"_blank"} rel="noreferrer">Adam Thackery</a><br/>
              <a href={"https://usctrojans.com/sports/football/roster/coaches/caleb-withrow/6631"} target={"_blank"} rel="noreferrer">Caleb Withrow</a><br/>
            </h5>
            <p>Assistant Directors of Football Sports Performance</p> */}

            {/* <h5><a href={"https://usctrojans.com/sports/football/roster/coaches/jackson-polk/6630"} target={"_blank"} rel="noreferrer">Jackson Polk</a></h5>
            <p>Assistant Director of Sports Science</p> */}

            <br/>

            {/* <h4>Adminstration and Operations</h4>

            <h5><a href={"htthttps://usctrojans.com/sports/football/roster/staff/ed-stewart/276ps"} target={"_blank"} rel="noreferrer">Ed Stewart</a></h5>
            <p>Executive Senior Associate Athletic Director for Football Administration</p>

            <h5><a href={"https://usctrojans.com/sports/football/roster/staff/dave-emerick/275"} target={"_blank"} rel="noreferrer">Dave Emerick</a></h5>
            <p>Senior Associate Athletic Director for Football<br/>General Manager</p>

            <h5><a href={"https://usctrojans.com/sports/football/roster/staff/clarke-stroud/260"} target={"_blank"} rel="noreferrer">Clarke Stroud</a></h5>
            <p>Director of Football Operations</p>

            <h5><a href={"https://usctrojans.com/sports/football/roster/staff/cheryl-taplin/263"} target={"_blank"} rel="noreferrer">Cheryl Taplin</a></h5>
            <p>Executive Assistant to Head Coach<br/>Assistant Director of Operations</p>

            <h5><a href={"https://usctrojans.com/sports/football/roster/staff/ben-hynds/262"} target={"_blank"} rel="noreferrer">Ben Hynds</a></h5>
            <p>Assistant Director of Football Operations</p>

            <br/> */}

            {/* <h4>Video Operations</h4>

            <h5><a href={"https://usctrojans.com/sports/football/roster/coaches/eric-espinoza/6633"} target={"_blank"} rel="noreferrer">Eric Espinoza</a></h5>
            <p>Director of Video Operations</p>

            <h5><a href={"https://usctrojans.com/sports/football/roster/coaches/alex-nederhoff/6634"} target={"_blank"} rel="noreferrer">Alex Nederhoff</a></h5>
            <p>Football Video & Systems Analyst</p>

            <br/> */}
          </Col>

          {/* <Col className={'fixed-one-third'}>
            <h4>Player Development and Recruiting</h4>

            <h5><a href={"https://usctrojans.com/sports/football/roster/staff/annie-hanson/264"} target={"_blank"} rel="noreferrer">Annie Hanson</a></h5>
            <p>Executive Director of Football Recruiting</p>

            <h5><a href={"https://usctrojans.com/sports/football/roster/staff/gavin-morris/265"} target={"_blank"} rel="noreferrer">Gavin Morris</a></h5>
            <p>Assistant Athletic Director<br/>Director of Player Relations</p>

            <h5><a href={"https://usctrojans.com/sports/football/roster/staff/chris-carter/266"} target={"_blank"} rel="noreferrer">Chris Carter</a></h5>
            <p>Director of Player Development</p>

            <h5><a href={"https://usctrojans.com/sports/football/roster/staff/brittany-thackery/277"} target={"_blank"} rel="noreferrer">Brittany Thackery</a></h5>
            <p>Director of Player Engagement</p>

            <h5><a href={"https://usctrojans.com/sports/football/roster/staff/drew-fox/267"} target={"_blank"} rel="noreferrer">Drew Fox</a></h5>
            <p>Assistant Director of Player Personnel</p>

            <h5><a href={"https://usctrojans.com/sports/football/roster/staff/weston-zernechel/268"} target={"_blank"} rel="noreferrer">Weston Zernechel</a></h5>
            <p>Assistant Director of Player Personnel</p>

            <h5><a href={"https://usctrojans.com/sports/football/roster/staff/elizabeth-grady/278"} target={"_blank"} rel="noreferrer">Elizabeth Grady</a></h5>
            <p>Director of Recruiting Operations</p>
            
            <h5><a href={"https://usctrojans.com/sports/football/roster/staff/morgan-laurence/274"} target={"_blank"} rel="noreferrer">Morgan Laurence</a></h5>
            <p>Assistant Director of Recruiting Operations</p>
            
            <br/>

            <h4>Marketing, Branding, and Digital</h4>

            <h5><a href={"https://usctrojans.com/sports/football/roster/staff/radmen-niven/269"} target={"_blank"} rel="noreferrer">Radmen Niven</a></h5>
            <p>Executive Director of Football Marketing and Branding</p>

            <h5><a href={"https://usctrojans.com/sports/football/roster/staff/geno-velasco/270"} target={"_blank"} rel="noreferrer">Geno Velasco</a></h5>
            <p>Director of Cinematography and Post Production</p>

            <h5><a href={"https://usctrojans.com/sports/football/roster/staff/rob-washington/271"} target={"_blank"} rel="noreferrer">Rob Washington</a></h5>
            <p>Director of Content</p>

            <h5><a href={"https://usctrojans.com/sports/football/roster/staff/juan-reyes/272"} target={"_blank"} rel="noreferrer">Juan Reyes</a></h5>
            <p>Director of Digital Strategy</p>

            <h5><a href={"https://usctrojans.com/sports/football/roster/staff/alex-verdugo/99"} target={"_blank"} rel="noreferrer">Alex Verdugo</a></h5>
            <p>Art Director</p>
           
            <br/>
          </Col> */}
        </Row>
      </Container>
    </div>
  )
}

export default Staff;