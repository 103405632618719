import Table from 'react-bootstrap/Table'
import SeasonSelect from './SeasonSelect';

function Season2028() {
  return (
    <div className="App-content">
      <h3>2028 Schedule</h3>

      <SeasonSelect />

      <Table responsive bordered hover size="sm">
        <thead>
          <tr>
            <th width="50" className={"centeredCol"}>Date</th>
            <th width="125" className={"centeredCol"}>Opponent</th>
            <th width="100" className={"centeredCol"}>Conference</th>
            <th width="75" className={"centeredCol"}>Time (PT)</th>
            <th width="100" className={"centeredCol"}>TV</th>
            <th width="125" className={"centeredCol"}>Location</th>
          </tr>
        </thead>
        <tbody>
        <tr className="monthRow">
            <td colSpan={7}>
              September
            </td>
          </tr>
          <tr>
            <td align="center">9</td>
            <td>Fresno State</td>
            <td>Mountain West</td>
            <td align="center">TBD</td>
            <td align="center">TBD</td>
            <td>L.A. Coliseum</td>
          </tr>
         
          <tr className="monthRow">
            <td colSpan={7}>
              Dates To Be Determined
            </td>
          </tr>
          <tr>
            <td align="center">TBD</td>
            <td>Iowa</td>
            <td>Big Ten</td>
            <td align="center">TBD</td>
            <td align="center">TBD</td>
            <td>L.A. Coliseum</td>
          </tr>
          <tr>
            <td align="center">TBD</td>
            <td>Michigan</td>
            <td>Big Ten</td>
            <td align="center">TBD</td>
            <td align="center">TBD</td>
            <td>Ann Arbor, MI</td>
          </tr>
          <tr>
            <td align="center">TBD</td>
            <td>Michigan State</td>
            <td>Big Ten</td>
            <td align="center">TBD</td>
            <td align="center">TBD</td>
            <td>East Lansing, MI</td>
          </tr>
          <tr>
            <td align="center">TBD</td>
            <td>Nebraksa</td>
            <td>Big Ten</td>
            <td align="center">TBD</td>
            <td align="center">TBD</td>
            <td>L.A. Coliseum</td>
          </tr>
          <tr>
            <td align="center">TBD</td>
            <td>Northwestern</td>
            <td>Big Ten</td>
            <td align="center">TBD</td>
            <td align="center">TBD</td>
            <td>Evanstown, IL</td>
          </tr>
          <tr>
            <td align="center">TBD</td>
            <td>Oregon</td>
            <td>Big Ten</td>
            <td align="center">TBD</td>
            <td align="center">TBD</td>
            <td>Eugene, OR</td>
          </tr>
          <tr>
            <td align="center">TBD</td>
            <td>Penn State</td>
            <td>Big Ten</td>
            <td align="center">TBD</td>
            <td align="center">TBD</td>
            <td>L.A. Coliseum</td>
          </tr>
          <tr>
            <td align="center">TBD</td>
            <td>Purdue</td>
            <td>Big Ten</td>
            <td align="center">TBD</td>
            <td align="center">TBD</td>
            <td>L.A. Coliseum</td>
          </tr>
          <tr>
            <td align="center">TBD</td>
            <td>UCLA</td>
            <td>Big Ten</td>
            <td align="center">TBD</td>
            <td align="center">TBD</td>
            <td>Pasadena, CA</td>
          </tr>
        </tbody>
      </Table>
    </div>
  )
}

export default Season2028;