function SeasonSelect() {
  return (
    <div>
      <p>
        <a href="#/recruiting/2024">2024</a> | <a href="#/recruiting/2025">2025</a> | <a href="#/recruiting/2026">2026</a>
      </p>
    </div>
  )
}

export default SeasonSelect;