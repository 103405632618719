import Data from '../../Data';
import Table from 'react-bootstrap/Table';

function NameRoster() {
  const file = '2024roster';
  const sortOrder = ['lastName', 'firstName'];
  const criteria = [{prop: 'enrolled', value: 'TRUE'}];
  const players = Data(file, sortOrder, criteria);
    
  return (
    <div className="App-content">
      <h3>2024 Name Roster</h3>
      <p><a href="#/roster-number">Player Numbers</a> | <a href="#/player-majors">Player Majors</a> | <a href="#/roster-geographic">Player High Schools</a></p>
      <p># Non-scholarship player</p>

      <Table responsive bordered hover size="sm">
        <thead>
          <tr>
            <th width="30" className={"centeredCol"}>No</th>
            <th width="150">Name</th>
            <th width="40" className={"centeredCol"}>Pos</th>
            <th width="40" className={"centeredCol"}>Ht</th>
            <th width="40" className={"centeredCol"}>Wt</th>
            <th width="40" className={"centeredCol"}>Class</th>
            <th width="150">Hometown</th>
            <th width="300">School(s)</th>
          </tr>
        </thead>
        <tbody>
          {players.map((player, index) =>
            <tr key={index}>
              <td align="center">{player.jersey}</td>
              <td>
                {player.walkOn ? '# ' : ''}
                <a href={"https://usctrojans.com/sports/football/roster/" + player.link} target={"_blank"} rel="noreferrer">
                  {player.firstName} {player.lastName} {player.suffix}
                </a>
              </td>
              <td align="center">{player.displaypos}</td>
              <td align="center">{player.height}</td>
              <td align="center">{player.weight}</td>
              <td align="center">{player.redshirt?'Rs-':''}{player.class}</td>
              <td>{player.hometown}{player.state ? ', ' + player.state : ''}{player.country !== 'USA' ? ' ' + player.country : ''}</td>
              <td>{player.schools}</td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  )
}

export default NameRoster;